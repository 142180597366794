import React, { useRef, useEffect } from "react";
import { PropTypes } from "prop-types";

import css from "../../../reportsMagneticStyle.less";
import { DnxTooltip } from "../../../../../loaders/DNXLoader";
import AppHocReport from "../../../../../generics/AppHOCReport";
import {
  getProgressBarTooltipColorInRowOne,
  getProgressBarTooltipColorInRowTwo,
  getProgressBarTooltipColorInRowThree,
  getProgressBarTooltipColorInRowFour,
  getProgressBarTooltipColorInRowFive,
  getBarSize,
  getPathName
} from "../../../../../utils/common";

const DashletProgressBar = ({ location }) => {
  const pathName = getPathName(location);
  const tippyReff = useRef();

  useEffect(() => {
    if (tippyReff.current) {
      const selector = document.querySelector("#_progress-bar");
      selector.style.width = getBarSize(pathName);
    }
  }, []);
  const onMouseEnterFn = e => {
    e.preventDefault();
    e.stopPropagation();
    const el = document.querySelector(".react-grid-item");
    el.style.height = "10px";
    if (tippyReff.current) {
      const popper =
        tippyReff.current._tippy && tippyReff.current._tippy.popper;
      let content =
        popper.querySelector(".dnx-tooltip") ||
        popper.querySelector(".tippy-content");
      const { pageX } = e;
      tippyReff.current.style.left = `${pageX -
        e.target.parentNode.offsetLeft}px`;
      content.innerHTML = `
            <div style="width:250px;">
                <table>
                    <tr>
                      ${getProgressBarTooltipColorInRowOne(pathName)}
                    </tr>
                    <tr>
                      ${getProgressBarTooltipColorInRowTwo(pathName)}
                    </tr>
                    <tr>
                      ${getProgressBarTooltipColorInRowThree(pathName)}
                    </tr>
                    <tr>
                      ${getProgressBarTooltipColorInRowFour(pathName)}
                    </tr>
                    <tr>
                      ${getProgressBarTooltipColorInRowFive(pathName)}
                    </tr>
                </table>
            </div>`;
      tippyReff.current._tippy.show();
    }
  };
  const onMouseLeave = () => {
    if (tippyReff.current) {
      const el = document.querySelector(".react-grid-item");
      el.style.height = "5px";
      tippyReff.current._tippy.hide();
    }
  };
  return (
    <div
      className={css["progress-bar"]}
      id={"_progress-bar"}
      onMouseEnter={onMouseEnterFn}
      onMouseLeave={onMouseLeave}
    >
      <DnxTooltip
        width="360"
        placement="bottom"
        flavor="white"
        type="hoverover"
        name="tooltip-progress"
      >
        <span
          style={{
            position: "absolute",
            left: "-10px",
            bottom: "0",
            height: "10px",
            zIndex: "-1"
          }}
          ref={tippyReff}
          title="x"
        ></span>
      </DnxTooltip>
    </div>
  );
};

DashletProgressBar.propTypes = {
  location: PropTypes.object.isRequired
};

export default AppHocReport(DashletProgressBar);
