import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { PropTypes } from "prop-types";

import css from "../../../reportsMagneticStyle.less";
import reduxContext from "../../../../../generics/AppHOCReport/reducer";
import { SITE, APP } from "../../../../../utils/enums";
import {
  isExecutiveTemplate,
  isAllApplicationsTemplate,
  isAllSitesTemplate,
  getRandomString,
  arrayToObject,
  isAllSitesTemplateCSV,
  isAllApplicationsTemplateCSV
} from "../../../../../utils/common";
import AppHocReport from "../../../../../generics/AppHOCReport";
import useReportApis from "../../../commonHooks/useReportApis";
import Spinner from "../../../../../common/Spinner";
import ErrorComponent from "../../../../../common/ErrorComponent";
import ScopeView from "../../../genericWorkflowComponents/views/ScopeView";
import { setWorkflow } from "../../../../../generics/AppHOCReport/actions";
import { appsFilter } from "../../../../../config/reqConfig/applications"; 
import { prepareQuery } from "../../../../../utils/query-utils";
 

//Generate Report
const Dashlet = props => {
  const workflow = localStorage.getItem("workflow");
  const workflowState = workflow
    ? JSON.parse(workflow)
    : {
      reportName: "",
      report_passkey: getRandomString(10),
      appChoice: APP.ALL,
      siteChoice: SITE.ALL,
      scope: { site_id: "", application: "" },
      recipientEmail: "",
      isNextDisabled: props.globalReport.workflowState.isNextDisabled,
      fileType: "pdf",
      scheduleFreq: "daily",
      timeRange: "daily",
      scheduleType: "once",
      pageName: "Executive Summary",
      step: 1
    };
  const pageName = props.location?.query?.pageName ? props.location.query.pageName : workflowState.pageName
  const [reportNameApiError, setReportNameApiError] = useState(false);
  const [siteDetails, setSiteDetails] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState(false);
  const { state, getAppsDetails, getSitesDetails } = useReportApis();
  const dispatch = useDispatch();

  useEffect(async () => {
    if (props.location.query) {
      const { pageName } = props.location.query;
      // check the generate report is site, then allow to fire sites list api
      if (isAllSitesTemplate(pageName) || isAllSitesTemplateCSV(pageName)) { 
        await getSitesDetails();
      } else {
        setSiteDetails(true);
      }
      // check the generate report is application, then allow to fire application list api
      if (
        isAllApplicationsTemplate(pageName) ||
        isAllApplicationsTemplateCSV(pageName)
      ) {
        await getAppsDetails(
          prepareQuery(appsFilter, { timePeriodSelected: "720" })
        );
      } else {
        setApplicationDetails(true);
      }
    }
  }, []);

  useEffect(() => {
    if (state.isFetchingAppsDetailsDone || applicationDetails) {
      const { pageName, id } = props.location.query;
      let appDetails;
      // To check for application or app360 report
      if (isAllApplicationsTemplate(pageName)) {
        // Create an object to eliminate the dups elements in dropdown

        const appObject = arrayToObject(state.appsDetails, "application");
        appDetails = Object.keys(appObject).map(elem => {
          return {
            text: elem,
            value: elem
          };
        });
      }
      const updatedScopeWorkflow = {
        ...workflowState,
        ...{
          applications: appDetails,
          showAllSites:
            isAllSitesTemplate(pageName) || isExecutiveTemplate(pageName),
          reportName: "",
          pageName: pageName,
          templateUuid: id.template_uuid,
          overlayId: localStorage.getItem("currentOverlay"),
          isFilterAppsLoaded: true
        }
      };
      saveState(updatedScopeWorkflow);
    } else if (state.isFetchingAppsDetailsError) {
      saveState({
        ...workflowState,
        ...{
          scope: { application: "", site_id: "" },
          reportName: "",
          applications: [],
          isNextDisabled: true,
          isFilterAppsLoaded: true,
          step: 1
        }
      });
    }
  }, [
    state.isFetchingAppsDetailsDone,
    state.isFetchingAppsDetailsError,
    applicationDetails
  ]);

  useEffect(() => {
    if (state.isFetchingSitesDetailsDone || siteDetails) {
      const { pageName } = props.location.query;
      let siteDetails;
      // To check for sites report
      if (isAllSitesTemplate(pageName)) {
        siteDetails = state.sitesDetails
          .map(item => ({ text: item.site_name, value: item.site_id }));
      }

      const updatedScopeWorkflow = {
        ...workflowState,
        ...{
          sites: siteDetails,
          showAllApplications:
            isAllApplicationsTemplate(pageName) ||
            isExecutiveTemplate(pageName),
          isFilterSitesLoaded: true
        }
      };
      saveState(updatedScopeWorkflow);
    } else if (state.isFetchingSitesDetailsError) {
      saveState({
        ...workflowState,
        ...{
          scope: { application: "", site_id: "" },
          reportName: "",
          sites: [],
          isNextDisabled: true,
          isFilterSitesLoaded: true,
          step: 1
        }
      });
    }
  }, [
    state.isFetchingSitesDetailsDone,
    state.isFetchingSitesDetailsError,
    siteDetails
  ]);

  const saveState = updatedWorkflow => {
    if (updatedWorkflow.isReportNameApiFailed) {
      setReportNameApiError(true);
    } else {
      dispatch(setWorkflow({ ...updatedWorkflow }));
    }
  };
  return (
    <div className={css["workflow"]}>
      <React.Fragment>
        {workflowState.isFilterSitesLoaded &&
          workflowState.isFilterAppsLoaded ? (
            state.isFetchingAppsDetailsError ||
              state.isFetchingSitesDetailsError ||
              reportNameApiError ? (
                <ErrorComponent
                  code={"500"}
                  width={"300px"}
                  className={"large-dashlet-error"}
                />
              ) : (
                <ScopeView
                  {...props}
                  scopeWorkflowState={workflowState}
                  pageName={pageName}
                  saveState={saveState}
                  isAllSitesDropdownShown={isAllSitesTemplate(pageName) || isExecutiveTemplate(pageName)}
                />
              )
          ) : (
            <Spinner />
          )}
      </React.Fragment>
    </div>
  );
};

Dashlet.propTypes = {
  location: PropTypes.object.isRequired,
  globalReport: PropTypes.object
};
export default reduxContext.withProvider(connect()(AppHocReport(Dashlet)));
