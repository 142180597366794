import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//components
import AppHoc from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import { DnxToast } from "../../../../loaders/DNXLoader";

//reducer files
import reduxContext from "../reducer";
import * as actions from "../actions";

//style file
import css from "../../reportsMagneticStyle.less";

//config files
import { getColumns, getTools } from "./tableConfig";
import * as apiConstant from "../../../../apis/apiConstants";

//i18n filess
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

const [HbrModal, HbrButton, HbrIcon] = reactWrapper([
  "hbr-modal",
  "hbr-button",
  "hbr-icon"
]);

const mapStateToProps = state => {
  return {
    reports: state.vanalytics.reports
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const ReportTable = props => {
  const DnxTable = DtableWrapper(table);
  const history = useHistory();
  const { selectedOverlay, currentTimeStamp } = props.globalFilter;
  const { getReportsList, deleteReport } = props.actions;
  const { reports, reports_loading } = props.reports;
  const [alert, setAlert] = useState();
  const refModal = useRef(null);

  const overlayDismiss = event => {
    event.preventDefault();
  };

  const onClickCancel = () => {
    refModal.current.element.hide();
  };

  useEffect(() => {
    if (selectedOverlay !== null) {
      getReportsList(selectedOverlay);
    }
    document.body.addEventListener("actionclick", handleActionClick);
    return () => {
      document.body.removeEventListener("actionclick", handleActionClick);
    };
  }, [selectedOverlay, currentTimeStamp && currentTimeStamp]);

  const handleActionClick = e => {
    if (e.target.dataset.btnname === "edit") {
      history.push(
        `${apiConstant.REPORT_URL}${e.target.dataset.reportid}/setup-report-scope`
      );
    }
    if (e.target.dataset.btnname === "delete") {
      setAlert(
        <HbrModal
          ref={refModal}
          open={true}
          onHbr-overlay-dismiss={overlayDismiss}
          id="report-delete-modal"
        >
          <div slot="label">
            <HbrIcon
              name="alerts-warning-large"
              sentiment="warning"
            ></HbrIcon>{" "}
            {"Delete"}
          </div>
          {`${i18nMessageBundle.reportDeleteAlertMsg}`} <b className="alert-message">{e.target.dataset.reportname}</b>
          <div slot="footer">
            <HbrButton
              variant="text"
              style={{ marginRight: "16px" }}
              onClick={onClickCancel}
              name="cancel"
            >
              {"Cancel"}
            </HbrButton>
            <HbrButton
              variant="fill"
              onClick={e => onSubmit(e)}
              sentiment="danger"
              id={e.target.dataset.reportid}
              name="delete"
            >
              {"Delete"}
            </HbrButton> 
          </div>
        </HbrModal>
      );
    }
  };
  const onSubmit = event => {
    if (event.target.name === "delete") {
      deleteReport(event.target.id);
      setAlert(<span style={{ display: "none" }} />);
    } else {
      setAlert(<span style={{ display: "none" }} />);
    }
  };
  const getTableProps = () => {
    const columns = getColumns(handleActionClick);
    const tableConfig = {
      columns: columns,
      dataTableClasses: "nowrap",
      tools: getTools(),
      data: reports,
      dtOptions: {
        scrollY: 600,
        scrollX: true,
        stateSave: true,
        stateDuration: 0
      }
    };
    return tableConfig;
  };
  let tableProps = getTableProps();
  return (
    <div className={css["reports-table"]} data-cy="reportsTableView">
      <div
        className="dnx--css_table-wrapper dnx--css_table-fullWidth"
        style={{
          height: "100%",
          display: "flex"
        }}
      >
        <div
          className="dnx--css_table-container commonTableStyle"
          style={{
            maxWidth: "100vw",
            minHeight: "100px"
          }}
        >
          {alert}
          {!reports_loading ? (
            <div style={{ height: "200px" }}>
              <Spinner />
            </div>
          ) : (
            <DnxTable {...tableProps} />
          )}
        </div>
        <DnxToast />
      </div>
    </div>
  );
};

ReportTable.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(React.memo(AppHoc(ReportTable)))
);
