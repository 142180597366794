import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//reducers
import reduxContext from "../reducer";
import * as actions from "../actions";

//style
import css from "../overviewMagneticStyle.less";

//component
import AppHOC from "../../../generics/AppHOC";
import DashletTable from "../../../common/DashletTable";
const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);
import HarborDonut from "../../../common/HbrDonut";
import QoeListComponent from "../../../common/QoeListComponent";
import ChartLegend from "../../../common/ChartLegend";

//utils
import { getThemeColors } from "../../../utils/colors";
import { getDonutData } from "../../../utils/views/overview";
import availTableConfig from "./config/table/siteAvailTableConfig";
import availDonutConfig from "./config/donut/siteAvailDonutConfig";
import {
  getSource,
  columnsName,
  columnsDeviceName,
  getSourceDevice
} from "./config/table/harborTableHeaderConfig";
import deviceTableConfig from "./config/table/deviceTableConfig";
import Spinner from "../../../common/Spinner";
import timeFilterConfig from "../../../generics/config/time-filter-config";
import ApiService from "../../../config/api-config";
import {
  saveSitesDonutData,
  saveSitesDetailsData,
  saveSitesSummaryData,
  saveSitesOverviewTableData,
  getTopSiteListData,
  formatLocationName
} from "../../sites/sitesUtils";
import { getReportTimeRangeDate, formatLegendData } from "../../../utils/common";
import { getSitesOverviewQuery } from "../../../config/reqConfig/overview";
import { COLOR, defaultCellValue, timeFilterTypesCustomV4 } from "../../../utils/enums";
import { BASE_URL } from "../../../apis/apiConstants";

//i18n files
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import { shortDisplayDate24HourWithSec, formatDate } from "../../../utils/displayTime";

const mapStateToProps = state => {
  return {
    overview: state.vanalytics.overview
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const [HbrCard, HbrButton] = reactWrapper(["hbr-card", "hbr-button"]);

const DashletSiteView = props => {
  let title = (
    <div data-cy="sites-link">
      <div className="header-content">
        {i18nMessageBundle.dashletSiteViewSites}
      </div>
    </div>
  );
  let topTableConfig = {
    ...availTableConfig,
    title: i18nMessageBundle.topAvailableSites
  };

  const {
    selectedSite,
    timePeriodSelected,
    siteAPIData,
    deviceSiteOverview,
    deviceSummaryLoader,
    errorFilterViewDevice
  } = props.globalFilter;

  const {
    overviewSiteLoader,
    overviewSiteError,
    overviewSiteDonut,
    overviewSiteStacked
  } = siteAPIData;
  let overviewTopSites;
  if (props.isReporting && siteAPIData?.sitesTableData?.data) {
    overviewTopSites = getTopSiteListData(siteAPIData.sitesTableData.data);
  }

  useEffect(() => {
    if (!sessionStorage.getItem("isGetInfoApiCalled"))
      updateDashletFilter("avail");

    /*****For Executive Report - Sites Section */
    if (
      props.isReporting &&
      props.executiveSummary.reportData &&
      props.executiveSummary.reportData.length > 0
    ) {
      //  let promisesQueue = [];
      const { overlay_id, time_range, current_period_start , current_period_end} = props.executiveSummary.reportData[0];
      const timeFrame = getReportTimeRangeDate(time_range);
      getSitesOverviewQuery.query.rules[0].value[0] = timeFrame;
      let globalV4PayloadReport = {
        time_frame: timeFilterTypesCustomV4[time_range],
        entry_ts: {
          start: shortDisplayDate24HourWithSec(formatDate(current_period_start).utc()),
          end: shortDisplayDate24HourWithSec(formatDate(current_period_end).utc())
          }
      };
      const defaultRequest = { ...globalV4PayloadReport }
      ApiService.getSitesTableTimeAvailability(
        defaultRequest,
        overlay_id
      ).then(res => {
        if (res && !res.errorObject) {
          saveSitesData(res.data);
        } else {
          props.setOverviewError(res);
        }
      });
    }
  }, []);

  const saveSitesData = res => {
    const overviewDonut = saveSitesDonutData(res.data);
    const overviewStacked = saveSitesDetailsData(res);
    const overviewSummary = saveSitesSummaryData(res.data);
    const sitesOverviewTab = saveSitesOverviewTableData(res.data);

    res &&
      res?.data &&
      res.data.map(
        data =>
          (data.availability === defaultCellValue
            ? (data.availability = defaultCellValue)
            : data.availability) &&
          (data.availability_prev === defaultCellValue
            ? (data.availability_prev = defaultCellValue)
            : data.availability_prev)
      );

    const result = {
      sitesTableData: res,
      overviewSiteDonut: overviewDonut,
      overviewSiteStacked: overviewStacked,
      overviewSiteSummary: overviewSummary,
      sitesOverviewTab: sitesOverviewTab
    };
    props.setSitesTableAvailability(result);
  };

  const updateDashletFilter = view => {
    const { setDashletFilter, setDashletLoading } = props.actions;
    setDashletLoading("SITE");
    setDashletFilter("SITE", { view });
  };

  const getChartTooltipConfig = () => {
    const timefilter = props.globalFilter.timePeriodSelected;
    return {
      labels: [
        {
          key: "current",
          label: i18nMessageBundle.dashletSiteViewAvailability
        },
        {
          key: "previous",
          label: `${i18nMessageBundle.applicationOverviewLabelAvailabilityOn} ${timeFilterConfig[timefilter].toolTip}`
        }
      ],
      colors: getThemeColors("overlay"),
      type: "number",
      format: "percent"
    };
  };

  if (selectedSite > 0) {
    return (
      <HbrCard className={css["site-dashlet-one-device"]} data-cy="sites-device-card" container={true}>
        <div className="site-one-device-container">
          <div className="title-device-dashlet hbr-type-h2">
            {i18nMessageBundle.applicationOverviewLabelTopDevices}
          </div>
          {deviceSummaryLoader ? (
            <div
              className="spinner-component-site-view"
              data-cy="spinner-component-site-view"
            >
              <Spinner />
            </div>
          ) : errorFilterViewDevice ? (
            <Suspense fallback={<Spinner />}>
              <ErrorComponent
                {...errorFilterViewDevice}
                width={"210px"}
                className={"small-dashlet-error"}
              />
            </Suspense>
          ) : (
                <div className="devices-content-block">
                  {deviceSiteOverview?.data ? (
                    <div className="table-view-one-device">
                      <DashletTable
                        config={deviceTableConfig}
                        data={deviceSiteOverview.data}
                        source={getSourceDevice(deviceSiteOverview.data)}
                        columnsName={columnsDeviceName}
                        isTableShown={true}
                        style={{ height: "358px" }}
                      />
                    </div>
                  ) : <Spinner />}
                </div>
              )}
        </div>

      </HbrCard>
    );
  } else {
    //let pieColors = [];
    let donutData = [];
    if (availDonutConfig !== undefined) {
      if (overviewSiteDonut?.summary)
        donutData = getDonutData(
          availDonutConfig.text,
          overviewSiteDonut.summary
        );
    }
    if (props.isReporting) {
      availTableConfig.legend = {
        theme: "overlay",
        labels: [
          i18nMessageBundle.applicationCircuitViewLabelAvailable,
          i18nMessageBundle.applicationCircuitViewLabelPrevious,
          i18nMessageBundle.applicationOverviewTotal
        ]
      }
    }

    return (
      <HbrCard
      className={`${css[props.isReporting ? "site-dashlet-reporting" : "site-dashlet"]}`}
      data-cy="site-dashlet-view" container={true}>
        <div
          className={props.isReporting ? "site-dashlet-view-reporting" : "site-dashlet-view"}
          data-cy="site-view"
        >
          {!props.isReporting &&
            <div
              className={props.isReporting ? "title-block-reporting" : "title-block"}
            >
              <div className="header-content hbr-type-h3">{title}</div>
              <HbrButton variant="text" onClick={() => props.history.push(`${BASE_URL}/sites`)}>
                {i18nMessageBundle.dashletSiteViewDetails}
              </HbrButton>
            </div>}
          {overviewSiteLoader ? (
            <div
              className="spinner-component-site-view"
              data-cy="spinner-component-site-view"
            >
              <Spinner />
            </div>
          ) : overviewSiteError ? (
            <Suspense fallback={<Spinner />}>
              <ErrorComponent
                {...overviewSiteError}
                width={"210px"}
                className={"small-dashlet-error"}
              />
            </Suspense>
          ) : (
                <div className="content-block">
                  {overviewSiteLoader ? (
                    <div
                      className="spinner-component-site-view"
                      data-cy="spinner-component-site-view"
                    >
                      <Spinner />
                    </div>
                  ) : (
                      overviewSiteDonut &&
                      availDonutConfig &&
                      overviewSiteDonut.summary && (
                        <>
                          <div
                            className={
                              props.isReporting ? "site-donut-reporting" : "site-donut"
                            }
                          >
                            {props.isReporting && <div
                              className="title-block-reporting hbr-type-h3"
                            >
                              {i18nMessageBundle.siteAvailabilityBreakdown}
                            </div>}
                            <div className="donut-widget">
                              <HarborDonut
                                data={donutData}
                                donutLabel={i18nMessageBundle.sitesLabel}
                              />
                              <div className="legend-site-view">
                                <QoeListComponent
                                  overviewPage={true}
                                  iconType={[
                                    COLOR.GOOD,
                                    COLOR.FAIR,
                                    COLOR.POOR,
                                    COLOR.GRAY
                                  ]}
                                  qoeScore={[0, 0, 0, 0]}
                                  qoeLabels={[
                                    availDonutConfig.text.good.title,
                                    availDonutConfig.text.fair.title,
                                    availDonutConfig.text.bad.title,
                                    availDonutConfig.text.notAvailable.title
                                  ]}
                                  tooltipText={i18nMessageBundle.unknownSiteAvailTtip}
                                  position={3}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}
                  {props.isReporting && overviewSiteLoader ? (
                    <Spinner />
                  ) : (
                      props.isReporting &&
                      overviewTopSites &&
                      topTableConfig && (
                        <>
                          <div
                            className={
                              props.isReporting
                                ? "table-chartview report-table"
                                : "table-chartview"
                            }
                          >
                            <div className="title-site-dashlet hbr-type-h3">
                              {i18nMessageBundle.topAvailableSites}
                            </div>
                            <DashletTable
                              config={topTableConfig}
                              data={overviewTopSites}
                              timeFilterType={props.reportLegendText}
                              chartTooltip={true}
                              chartConfig={getChartTooltipConfig()}
                            ></DashletTable>
                          </div>
                          {!props.isReporting && <div className="legend-ctra">
                            <ChartLegend data={formatLegendData(availTableConfig.siteLegend)}></ChartLegend>
                          </div>}
                        </>
                      )
                    )}

                  {overviewSiteLoader ? (
                    <Spinner />
                  ) : (
                      overviewSiteStacked &&
                      availTableConfig && (
                        <div className="table-view">
                          <div
                            className={
                              props.isReporting
                                ? "table-chartview report-table"
                                : "table-chartview"
                            }
                          >
                            <div className="title-site-dashlet hbr-type-h3">
                              {i18nMessageBundle.siteTableAvailability}
                            </div>
                            <DashletTable
                              config={availTableConfig}
                              data={formatLocationName(overviewSiteStacked)}
                              timeFilterType={
                                props.isReporting
                                  ? props.reportLegendText
                                  : timePeriodSelected
                              }
                              chartTooltip={true}
                              chartConfig={getChartTooltipConfig()}
                              source={getSource([])}
                              columnsName={columnsName()}
                              style={{ height: "40px", width: "380px" }}
                            ></DashletTable>
                          </div>
                          {!props.isReporting &&<div className="legend-ctr">
                            <ChartLegend data={formatLegendData(availTableConfig.siteLegend)}></ChartLegend>
                          </div>}
                        </div>
                      )
                    )}
                </div>
              )}
        </div>
      </HbrCard>
    );
  }
};

DashletSiteView.propTypes = {
  actions: PropTypes.object.isRequired,
  timeFilter: PropTypes.object,
  globalFilter: PropTypes.object.isRequired,
  overview: PropTypes.object,
  isReporting: PropTypes.bool,
  executiveSummary: PropTypes.object,
  setSitesTableAvailability: PropTypes.func,
  getTopSiteListData: PropTypes.func,
  timeRange: PropTypes.any,
  reportLegendText: PropTypes.string,
  setOverviewError: PropTypes.func,
  history: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(DashletSiteView))
);
