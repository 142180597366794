import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { useHistory } from "react-router-dom";
//components
import TabList from "./TabList";
import SelectedTabView from "../SelectedTabView";
import AppHoc from "../../../../generics/AppHOC";

//style file
import css from "../../reportsMagneticStyle.less";

//reducers file
import reduxContext from "../reducer";
import * as actions from "../actions";
import { BASE_URL } from "../../../../apis/apiConstants";

//i18n file
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

const mapStateToProps = state => {
  return {
    reports: state.vanalytics.reports
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const ReportTabList = props => { 
  const [component, setComponent] = useState(<></>);
  const [reportsCount, setReportsCount] = useState(0);
  const [reportTabPath, setReportTabPath] = useState();

  useEffect(() => {
    setReportsCount(props.reports.reports.length);
    setReportTabPath(props?.location?.hash);
    setComponent(<TabComponent reportsCount={reportsCount} reportTabPath={reportTabPath} />);
  }, [props, reportsCount, reportTabPath]);

  return component;
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(ReportTabList))
);


const TabComponent = props => {
  const history = useHistory();
  const [reportTemplate, setReportTemplate] = useState();
  // Report view change on click of tab
  const changeReportView =(templateView) => { 
    let reportPathView;
    if(templateView ==='reportTemplates') {
      reportPathView = "#report-templates";
    } else {
      reportPathView = "#generated-reports";
    }
    history.push(`${BASE_URL}/reports${reportPathView}`);
    setReportTemplate(templateView);
  }
  // Default report view set, onclick of side bar reports submenu
  useEffect(() => {
    let defaultTabValue;
    if(props.reportTabPath ==='#report-templates') {
      defaultTabValue = "reportTemplates";
    } else {
      defaultTabValue = "generatedReports";
    }
    setReportTemplate(defaultTabValue);
  }, [props.reportTabPath]);

  const tabList = [
    {
      label: i18nMessageBundle.reportGeneratedReports,
      name: "generatedReports",
      count: props.reportsCount
    },
    {
      label: i18nMessageBundle.reportReportTemplates,
      name: "reportTemplates" 
    }
  ];  
  
  return (
    <div className={css["report-container"]} data-cy="tab-report-view">
      <div className="app-label hbr-type-h1">{"Reports"}</div>
      <TabList 
        tabs={tabList}
        defaultTabValue={reportTemplate}
        changeReportView={changeReportView} 
      />
      <SelectedTabView tabContent={reportTemplate} />
    </div>
  );
};

TabComponent.propTypes = {
  reportsCount: PropTypes.number,
  reportTabPath: PropTypes.string,
  location: PropTypes.object.isRequired
};
