import _ from "lodash";

import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import {
  shortDisplayTimeOnly,
  shortDisplayDateFullYear
} from "../../../../utils/displayTime";
import { isFreqNow, isDaily, getRepeatOnDay } from "../../../../utils/common";
import { displayFullMonth } from "../../../../utils/displayTime";
import { toast } from "../../../../loaders/DNXLoader";

export const getColumns = (handleActionClick) => {
  return [
    {
      data: "report_name",
      title: i18nMessageBundle.reporTableColReportNameLabel,
      searchable: true,
      searchPopover: false,
      render: data => {
        return data;
      }
    },
    {
      data: "schedule_type",
      title: i18nMessageBundle.reporTableColScheduleLabel,
      searchable: true,
      searchPopover: true,
      render: (data, type, row) => {
        const freq = row.schedule_freq;

        if (_.isEmpty(data)) return "";

        if (isFreqNow(row.schedule_freq)) {
          return ` 
            ${
              i18nMessageBundle.workflowOneTimeNow
            }<br/><span class='next-run'> on 
            ${displayFullMonth(row.next_run)} at ${shortDisplayTimeOnly(
            row.next_run
          )} </span>
            `;
        }
        if (isDaily(row.schedule_freq)) {
          return `
            ${freq.charAt(0).toUpperCase()}${freq.substring(1)}
            <br/>
            <span class='next-run'> 
              on ${shortDisplayDateFullYear(
                row.repeat_time
              )} at ${shortDisplayTimeOnly(row.repeat_time)}
            </span>
            `;
        }

        return `
          ${freq.charAt(0).toUpperCase()}${freq.substring(1)}
          <br/><span class='next-run'> on ${getRepeatOnDay(row.repeat_on)} at 
          ${shortDisplayTimeOnly(row.repeat_time)}
          </span>
          `;
      }
    },
    {
      data: "last_run",
      title: i18nMessageBundle.reporTableColLastRunLabel,
      searchable: true,
      searchPopover: true,
      render: (data, type, row) => {
        let content = "--";
        if (Number.isInteger(data)) {
          //TO DO it need to be add green tick
          //`<i class="dnac-icon-check-circle-filled-sm" style="color: rgb(0, 171, 80);"></i>` +
          content =
            shortDisplayDateFullYear(data) +
            " at " +
            shortDisplayTimeOnly(data);
        } else if (Number.isInteger(row.last_run)) {
          content =
            shortDisplayDateFullYear(row.last_run) +
            " at " +
            shortDisplayTimeOnly(row.next_run);
        }
        if (
          row.next_run !== null &&
          Number.isInteger(row.next_run) &&
          !isFreqNow(row.schedule_freq)
        ) {
          content +=
            "<br><span class='next-run'> Next Run: " +
            shortDisplayDateFullYear(row.next_run) +
            " at " +
            shortDisplayTimeOnly(row.next_run) +
            "</span>";
        }
        return content;
      }
    },
    {
      data: "report_count",
      title: i18nMessageBundle.reporTableColCountLabel,
      searchable: true,
      searchPopover: true,
      render: data => {
        if (Number.isInteger(data)) return data;
        else return 0;
      }
    },
    {
      data: "report_passkey",
      title: i18nMessageBundle.reporTableColDocKeyLabel,
      orderable: false,
      createdCell: (td, rowData) => {
        td.addEventListener("click", event => {
          if (event.target.className === "hydrated") {
            navigator.clipboard.writeText(rowData);
            toast({
              message: i18nMessageBundle.workflowSuccessMessage,
              flavor: "success"
            });
          }
        });
      },
      render: data => {
        if (!_.isEmpty(data)) {
          return `<div class="passkey-row" data-cy="copyActions"> 
                  <hbr-icon name="copy"></hbr-icon>
                  <dnx-textfield password={true}  maxWidth="152px"  value=${data} textreadonly=true></dnx-textfield>
                </div>`;
        } else {
          return `<div>--</div>`;
        }
      }
    },
    {
      data: "recipient_email",
      title: i18nMessageBundle.reporTableColDeliveryLabel,
      searchable: true,
      searchPopover: true,
      render: (data, type, row) => {
        if (_.isEmpty(data)) return "";
        return `<div class="recipient-email-row" data-cy="copyActions"> 
        <hbr-tag name="copy">${row.file_type}</hbr-tag>
        <br/><span class='email'> ${data.split("|").join(", ")}</span></div>`;
      }
    },
    {
      data: "template_name",
      title: i18nMessageBundle.reportTemplate,
      searchable: true,
      searchPopover: true
    },
    {
      data: "action",
      title: i18nMessageBundle.reporTableActions,
      orderable: false,
      searchable: false,
      createdCell: (td) => {
        td.addEventListener("click", event => {
          event.preventDefault();
          handleActionClick(event);
        });
      },
      render: function(data, type, rowData) {
        return type === "display"
          ? `<div class="action-row">
            <hbr-icon name="pencil-simple" size="20px" arialabel="edit" data-btnname="edit" data-reportid=${rowData.report_uuid}></hbr-icon>
            <hbr-icon name="trash-simple" size="20px" data-btnname="delete" data-reportid=${rowData.report_uuid} data-reportname=${rowData.report_name}></hbr-icon>
          </div>`
          : data;
      }
    }
  ];
};

export const getTools = () => [
  {
    ref: {
      name: "search"
    }
  }
];
