import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";

const reduxContext = createContext();

export default reduxContext;

const storeKey = "vanalytics.siteSummary";

const initialState = {
  reportOptions: null,
  isLoading: true
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_REPORT_OPTIONS:
      return {
        ...state,
        reportOptions: payload,
        isLoading: false
      };
    case ACTIONS.API_ERROR:
      return {
        ...state,
        isLoading: false,
        reportData: null,
        error: payload
      };

    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [ACTIONS.SET_REPORT_OPTIONS, ACTIONS.API_ERROR]
});
