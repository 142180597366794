import React, { useEffect } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//components
import AppHoc from "../../../../../generics/AppHOC";
import Spinner from "../../../../../common/Spinner";
import ErrorComponent from "../../../../../common/ErrorComponent";
import AppFlowTable from "../../../../app360/AppFlow/AppFlowTable";

//reducers
import reduxContext from "../reducer";
import * as actions from "../actions";

//style
import css from "../../../reportsMagneticStyle.less";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    app360: state.vanalytics.app360_report
  };
};
const [HbrCard] = reactWrapper(["hbr-card"]);

const AppFlow = props => {
  const { tunnel_data, tunnel_table_loader, tunnel_table_error } = props.app360;
  const appName = props.match.params.appName;

  const getTableData = () => {
    const { getTunnelData } = props.actions;
    const { current_period_start, current_period_end } = props.location.state;
    const current_period = [current_period_start, current_period_end];
    getTunnelData(current_period, appName);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className={css["app360-report-app-flow-table"]} data-cy="app360-report-app-flow-table">
      {tunnel_table_loader ? (
        <Spinner />
      ) : tunnel_data ? (
        <HbrCard className="app-flow-table">
          <AppFlowTable
            {...props}
            data={tunnel_data.slice(0, 50)}
            reportView={true}
          />
        </HbrCard>
      ) : (
            <div className="error">
              <ErrorComponent
                {...tunnel_table_error}
                width={"210px"}
              />
            </div>
          )}
    </div>
  );
};

AppFlow.propTypes = {
  app360: PropTypes.object.isRequired,
  actions: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(AppFlow))
);