import { getQoeScore } from "../../../../../utils/common";
import { getQualityColorHbr } from "../../../../../utils/colors";
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export const columnsName = () => [
  {
    prop: "application",
    name: i18nMessageBundle.appViewApplication,
    size: 105,
    cellTemplate: (createElement, props) => {
      const getHTML = () => {
        return `<div style="color: var(--hbr-color-interact);">${props.model.application}<div>`;
      };
      const div = createElement("div", {
        style: {
          height: "100%",
          color: "var(--hbr-color-on-surface)",
          display: "flex",
          alignItems: "center"
        },
        innerHTML: getHTML()
      });
      return [div];
    }
  },
  {
    prop: "vqoe_score",
    name: i18nMessageBundle.appViewByQoE,
    size: 60,
    cellTemplate: (createElement, props) => {
      const getHTML = () => {
        const qoeScore = getQoeScore(props.model.vqoe_score);

        return `<div style="color: ${getQualityColorHbr(
          qoeScore
        )};">${props.model.vqoe_score.toFixed(0)}<div>`;
      };
      const div = createElement("div", {
        style: {
          height: "100%",
          color: "var(--hbr-color-on-surface)",
          display: "flex",
          alignItems: "center"
        },
        innerHTML: getHTML()
      });
      return [div];
    },
    columnTemplate: createElement => {
      const content = createElement("div", {
        style: {
          display: "flex",
          alignItems: "center"
        },
        innerHTML: `${i18nMessageBundle.appViewByQoE}
                      <hbr-tooltip
                      content-id="some-id"
                      content="Application QoE and trend (+/-) compared with previous time range selection"
                    >
                      <hbr-icon name="info" sentiment="neutral"></hbr-icon>
                    </hbr-tooltip>`
      });
      return [content];
    }
  },
  {
    prop: "max",
    name: i18nMessageBundle.appQoeSiteCount,
    size: 88
  },
  {
    prop: "distributionTotal",
    name: i18nMessageBundle.appQoeDistribution,
    size: 142
  }
];

export const getSource = data => {
  return data.map(element => {
    return {
      application: element.application,
      vqoe_score: element.vqoe_score,
      max: element.max,
      distributionTotal: element.distributionTotal
    };
  });
};
