import axios from "axios";
import { createGenericErrorObject } from "../../../utils/common";
import ApiService from "../../../config/api-config";
import { customListRequestObject } from "../../../config/reqConfig/applications";
import {
  APP_BY_BANDWIDTH_URL,
  TOP_APPS_BY_BANDWIDTH_URL
} from "../../../apis/apiConstants";

export const appClassList = (type, filter) => dispatch => {
  const appClassList = require(`../../../config/sidebar/TrendAnalysis/CustomListTable/${type}`);

  dispatch({
    type: `SET_${type}`,
    filter,
    payload: appClassList
  });
};

export const updateSelections = (type, filter) => dispatch => {
  dispatch({
    type: `SET_${type}`,
    filter,
    payload: filter.selections
  });
};

export const selectedCustomFilter = (type, filter) => dispatch => {
  dispatch({
    type: `SET_${type}`,
    filter,
    payload: filter.customFilters
  });
};
export const setSelectedTimeFilter = (type, filter) => dispatch => {
  dispatch({
    type: `SET_${type}`,
    filter,
    payload: filter.timeFilter
  });
};
export const getTableCustomList = (type, filter) => dispatch => {
  startLoader(type, dispatch);
  let request_obj = customListRequestObject(filter.timeFilter);
  if (filter.site_id > 0) {
    request_obj.filter = { site_id: filter.site_id };
  }
  let qoeFilter = filter.customFilters.qoeFilter;
  let classFilter = filter.customFilters.classFilter;
  if (qoeFilter !== "all") {
    request_obj["filter"]["vqoe_score"] = [qoeFilter];
  }
  if (classFilter !== "all") {
    request_obj["filter"]["application_class"] = [classFilter];
  }
  ApiService.getTableCustomList(request_obj)
    .then(res => {
      let selectedAppNames = res.data.data
        .slice(0, 5)
        .map(app => app.application);
      //customList updation
      dispatch({
        type: `SET_${type}`,
        filter,
        payload: res.data.data
      });
      //customList default Top 5 app selection
      dispatch({
        type: `SET_SELECTIONS`,
        filter,
        payload: selectedAppNames
      });
    })
    .catch(err => {
      dispatch({
        type: `SET_${type}`,
        payload: err,
        error: true
      });
    });
};
export const getAppCountByQoe = (type, filter, globalV4PayloadSidebar) => dispatch => {
  startLoader(type, dispatch);

  const request_obj = {
    current_period: filter.current_period
  };

  if (filter.site_id > 0) {
    request_obj.filter = { site_id: filter.site_id };
  } else {
    delete request_obj.filter;
  }

  ApiService.getAppCountByQoe(globalV4PayloadSidebar).then(res => {
    if (!res.errorObject)
      dispatch({
        type: `SET_${type}`,
        filter,
        globalV4PayloadSidebar,
        payload: res.data.data
      });
    else
      dispatch({
        type: "SET_ERROR",
        loading: { appCount_loader: false },
        error: { appCountError: res.errorObject }
      });
  });
};
const startLoader = (type, dispatch) => {
  dispatch({
    type: `SET_${type}_LOADING`
  });
};

export const getAppTotalUsageData = async v4Payload => {
  try {
    const payload = { ...v4Payload, sort: { entry_ts: "asc" } };
    return await axios.post(APP_BY_BANDWIDTH_URL, payload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};

export const getAppQoEData = async (globalV4PayloadSidebar, selections) => {
  const payload = {
    ...globalV4PayloadSidebar,
    sort: { entry_ts: "asc" },
    application: selections
  };
  return await ApiService.getAggregateData(payload);
};

export const getAppBWData = async (v4Payload, applications) => {
  try {
    const payload = { ...v4Payload, sort: { entry_ts: "asc" } };
    if (Array.isArray(applications)) payload.application = applications;
    else payload.bandwidth_rank = [1, 2, 3, 4, 5];
    return await axios.post(TOP_APPS_BY_BANDWIDTH_URL, payload);
  } catch (e) {
    return createGenericErrorObject(e);
  }
};
