import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import _ from "lodash";
import { connect, useDispatch } from "react-redux";

import AppHocReport from "../../../../generics/AppHOCReport";
import css from "../../reportsMagneticStyle.less";
import {
  DnxTextfieldGroup,
  DnxTextfield
} from "../../../../loaders/DNXLoader";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import { setWorkflow } from "../../../../generics/AppHOCReport/actions";
import reduxContext from "../../../../generics/AppHOCReport/reducer";
import {
  createObjFromEmailList,
  isWidgetGradeOut,
  getWorkflowState
} from "../../../../utils/common";
import { regexEmail } from "../../../../utils/enums";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const [HbrCheckboxGroup, HbrCheckbox] = reactWrapper(["hbr-checkbox-group", "hbr-checkbox"]);

const DashletDelivery = () => {
  const workflowState = getWorkflowState();
  const dispatch = useDispatch();
  const _groupState = createObjFromEmailList(workflowState.recipientEmail);
  const isDisabledWidget = isWidgetGradeOut(location);
  const [textFieldGroupState, setTextFieldGroupState] = useState(_groupState);
  const [initEmailRemoveIcon, setInitEmailRemoveIcon] = useState(false);

  useEffect(() => {
    if (
      _groupState?.textfieldgroup?.length === 1 &&
      _.isEmpty(_groupState.textfieldgroup[0].value)
    ) {
      dispatch(setWorkflow({ isNextDisabled: true }));
      setInitEmailRemoveIcon(true);
    }
  }, []);

  useEffect(() => {
    if (_groupState?.textfieldgroup?.length === 1) {
      if (document.querySelector(".span-dnac-icon-trash-sm")) {
        document.querySelector(".span-dnac-icon-trash-sm").remove();
      }
    }
  }, [initEmailRemoveIcon]);

  const checkForDuplicateEmails = (textfieldgroup) => {
    const set = new Set();
    let duplicateStatus;
    textfieldgroup.some((object) => { 
      const isDuplicate = set.size === (set.add(object.value), set.size);
      if(isDuplicate) {
        duplicateStatus = isDuplicate;
        textfieldgroup[object.order].errormsg = i18nMessageBundle.duplicateEmailMsg;
      } else {
        textfieldgroup[object.order].errormsg = "";
      } 
    })
    return duplicateStatus
  }

  const _change = event => {
    const { textfieldgroup } = textFieldGroupState;
    textfieldgroup.forEach((input, index) => {
      if (input.inputid === event.target.id) {
        textfieldgroup[index].value = event.target.value;
      }    
    });
    setTextFieldGroupState({ textfieldgroup });
    checkForDuplicateEmails(textfieldgroup) === true ? dispatch(setWorkflow({ isNextDisabled: true })) : validateListOfEmails(textfieldgroup);
    saveState(textfieldgroup);
  };

  const validateListOfEmails = list => {
    const _workflowState = getWorkflowState();

    for (let i = 0; i < list.length; i++) {
      if (!regexEmail.test(String(list[i].value).toLowerCase())) {
        if (!_workflowState.isNextDisabled) {
          dispatch(setWorkflow({ isNextDisabled: true }));
        }
        break;
      }
      //if for loop run complete cycle all emails are validated and so we can enable the next btn
      if (
        _workflowState.isNextDisabled &&
        i === list.length - 1 &&
        !_.isEmpty(list[i].value)
      ) {
        dispatch(setWorkflow({ isNextDisabled: false }));
      }
    }
  };

  const _add = () => {
    const { textfieldgroup } = textFieldGroupState;
    const currentLength = textfieldgroup.length;
    textfieldgroup.push({
      name: `name${currentLength + 1}`,
      validator: "email",
      inputid: `name${currentLength + 1}`,
      label: `${i18nMessageBundle.workflowEmail} ${currentLength + 1}`,
      value: "",
      order: currentLength,
      errormsg: ""
    });
    dispatch(setWorkflow({ isNextDisabled: true })); //Disabling next button on click of add email icon
    setTextFieldGroupState({ textfieldgroup });
  };

  const _close = event => {
    const { textfieldgroup } = textFieldGroupState;
    const pos = parseInt(event.detail.item.split("-")[1]);
    textfieldgroup.splice(pos, 1);
    textfieldgroup.forEach((input, index) => {
      input.order = index;
    });
    setTextFieldGroupState({ textfieldgroup });
    checkForDuplicateEmails(textfieldgroup) === true ? dispatch(setWorkflow({ isNextDisabled: true })) : validateListOfEmails(textfieldgroup);
    saveState(textfieldgroup);
  };

  const saveState = textfieldgroup => {
    const _workflowState = getWorkflowState();

    const listEmails = textfieldgroup
      .filter(function(e) {
        return !_.isEmpty(e.value);
      })
      .map(el => el.value);

    const emails = listEmails.join();
    const updatedWorkflow = {
      ..._workflowState,
      ...{ recipientEmail: emails }
    };

    dispatch(setWorkflow({ ...updatedWorkflow }));
  };

  const _onInput = e => {
    const { textfieldgroup } = textFieldGroupState;
    const workflowState = getWorkflowState();
    const isNextDisabled = _.isEmpty(e.currentTarget.value) ? true : false;
    const updatedWorkflow = {
      ...workflowState,
      ...{ report_passkey: e.currentTarget.value },
      ...{ isNextDisabled: isNextDisabled }
    };
    dispatch(setWorkflow({ ...updatedWorkflow }));

    if (!isNextDisabled) validateListOfEmails(textfieldgroup);
  };

  return (
    <div className={css["workflow"]}>
      <React.Fragment>
        {
          <div className="left-side-container">
            <div className="header">{i18nMessageBundle.worklfowDelivery}</div>
            <div>
              <br />
              <div className="main-title">
                {i18nMessageBundle.worklfowEmailReport}
              </div>
              <br />
              <HbrCheckboxGroup
                direction="column"
                data-cy="attachmentSwitch"
              >
                <HbrCheckbox name="attachment" checked disabled>{i18nMessageBundle.workflowAsAttachment}</HbrCheckbox>
              </HbrCheckboxGroup>
              <br />
              <div>
                <br />
                <DnxTextfield
                  password={true}
                  textreadonly={true}
                  label={i18nMessageBundle.workflowDeliveryDocumentKey}
                  value={workflowState.report_passkey}
                  maxWidth={"350px"}
                  input={_onInput}
                />
              </div>
              <div style={{ width: "860px" }}>
                {
                  <div className="main-title">
                    {i18nMessageBundle.worklfowEmailRecipient}
                  </div>
                }
                <div
                  className={isDisabledWidget ? "disabledDiv" : ""}
                  data-cy="emailClass"
                >
                  <DnxTextfieldGroup
                    limit={5}
                    textfieldprops={textFieldGroupState.textfieldgroup}
                    change={_change}
                    add={_add}
                    close={_close}
                  ></DnxTextfieldGroup>
                </div>
              </div>
              <br />
            </div>
          </div>
        }
      </React.Fragment>
    </div>
  );
};

DashletDelivery.propTypes = {
  globalReport: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect()(AppHocReport(DashletDelivery))
);
