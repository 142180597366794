import React from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

//components
import ReportTable from "../ReportTable";
import ReportTemplates from "../ReportTemplates";

//style
import css from "../../reportsMagneticStyle.less";

const [HbrCard] = reactWrapper(["hbr-card"]);

const SeletedTabView = ({ tabContent }) => {
  switch (tabContent) {
    case "generatedReports": {
      return (
        <HbrCard className={css["report-selected-tab-view"]}>
          <div>
            <ReportTable />
          </div>
        </HbrCard>
      );
    }
    case "reportTemplates":
      return <ReportTemplates />;
    default:
      return <></>;
  }
};

SeletedTabView.propTypes = {
  tabContent: PropTypes.string
};

export default SeletedTabView;
