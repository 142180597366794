import React, { useEffect } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//components
import AppHoc from "../../../../../generics/AppHOC";
import Spinner from "../../../../../common/Spinner";
import ErrorComponent from "../../../../../common/ErrorComponent";
import TopUsersTable from "../../../../app360/TopUsers/TopUsersTable";

//reducers
import reduxContext from "../reducer";
import * as actions from "../actions";

//style
import css from "../../../reportsMagneticStyle.less";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    app360: state.vanalytics.app360_report
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

const TopClients = props => {
  const { top_users_table, top_users_loader, top_users_error } = props.app360;
  const appName = props.match.params.appName;

  const getTableData = () => {
    const { getTopUsersData } = props.actions;
    const {
      current_period_start,
      current_period_end,
      overlay_id
    } = props.location.state;
    const current_period = [current_period_start, current_period_end];
    getTopUsersData(current_period, appName, overlay_id);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className={css["app360-reports-tops-clients-table"]} data-cy="app360-reports-tops-users-table">
      {top_users_loader ? (
        <Spinner />
      ) : top_users_table ? (
        <HbrCard>
          <TopUsersTable
            {...props}
            tableId="topusers"
            data={top_users_table}
            reportView={true}
          />
        </HbrCard>

      ) : (
            <div className="error">
              <ErrorComponent
                {...top_users_error}
                width={"210px"}
              />
            </div>
          )}
    </div>
  );
};

TopClients.propTypes = {
  app360: PropTypes.object.isRequired,
  actions: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(TopClients))
);