import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

import {
  DnxTextfield,
  DnxSwitchGroup,
  DnxSwitch,
  DnxDropdown
} from "../../../../../loaders/DNXLoader";
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";
import {
  SITE,
  APP,
  getPageFromStep,
  regexName
} from "../../../../../utils/enums";
import {
  isWidgetGradeOut,
  isExecutiveTemplate,
  getWorkflowState
} from "../../../../../utils/common";
import ApiService from "../../../../../config/api-config";

const [HbrBanner, HbrIcon, HbrLink] = reactWrapper(["hbr-banner", "hbr-icon", "hbr-link"]);

const ScopeView = ({ scopeWorkflowState, saveState, pageName, isAllSitesDropdownShown }) => {
  const isDisabledWidget = isWidgetGradeOut(location);
  const {
    scope,
    siteChoice,
    appChoice,
    reportName,
    showAllApplications,
    applications,
    sites,
    overlayId,
    isReportNameValid
  } = scopeWorkflowState;
  const site = scope && scope.site_id ? scope.site_id : "";
  const app = scope && scope.application ? scope.application : "";
  const isSiteDropdownShown = siteChoice === SITE.CUSTOM;
  const isAppDropdownShown = appChoice === APP.CUSTOM;
  const workflowState = getWorkflowState();
  const { fileType } = workflowState;
  const [error, setError] = useState("");
  const [showLoadingBanner, setShowLoadingBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState();
  const [errorReport, setErrorReport] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (_.isEmpty(reportName)) {
      const updatedWorkflow = {
        ...getWorkflowState(),
        ...{ isNextDisabled: true },
        ...{ pageName: pageName }
      };
      saveState(updatedWorkflow);
    }
  }, []);

  useEffect(() => {
    if (isReportNameValid) {
      const workflowState = getWorkflowState();
      onNext(workflowState.reportName);
      return () => {
        setSuccess(false);
      };
    }
  }, [isReportNameValid]);

  useEffect(() => {
    if (isSuccess) {
      const updatedWorkflow = {
        ...getWorkflowState(),
        ...{ step: 2, isNextDisabled: false, isReportNameValid: false }
      };
      saveState(updatedWorkflow);
      history.push(getPageFromStep[2]);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errorReport && _.isEmpty(bannerMessage)) {
      setBannerMessage(`${i18nMessageBundle.workflowNext} `);
    }
  }, [errorReport]);

  const onNext = async reportName => {
    setShowLoadingBanner(true);
    setBannerMessage("");
    setErrorReport(false);
    setSuccess(false);

    try {
      const result = await ApiService.validateReportName(reportName, overlayId);
      if (result.data.length === 1) {
        setErrorReport(true);
        setShowLoadingBanner(false);
        saveState({
          ...getWorkflowState(),
          ...{
            isReportNameValid: false
          }
        })
      } else {
        setSuccess(true);
        setShowLoadingBanner(false);
        setBannerMessage("");
        saveState({
          ...getWorkflowState(),
          ...{
            isReportNameValid: true
          }
        })
      }
    } catch (e) {
      saveState({
        ...getWorkflowState(),
        ...{
          isReportNameApiFailed: true
        }
      });
    }
  };

  const _onTitleInput = e => {
    const inputValue = e.currentTarget.value;

    if (!regexName.test(String(inputValue).toLowerCase())) {
      setError(i18nMessageBundle.workflowEmptyReportNameErrorRegexName);
      saveState({
        ...scopeWorkflowState,
        ...{ reportName: inputValue, isNextDisabled: true }
      });
    } else {
      if (showLoadingBanner) setShowLoadingBanner(false);
      if (!_.isEmpty(bannerMessage)) setBannerMessage("");

      setError("");
      saveState({
        ...scopeWorkflowState,
        ...{
          reportName: inputValue,
          isNextDisabled:
            appChoice &&
            appChoice === "custom-application" &&
            scope.application === "all"
        }
      });
    }
  };

  const onChangeSite = e => {
    const updatedWorkflow = {
      ...getWorkflowState(),
      ...{
        siteChoice: e.currentTarget.value,
        scope: { site_id: "all", application: "" }
      }
    };
    saveState(updatedWorkflow);
  };

  const onChangeApplication = e => {
    const workflowState = getWorkflowState();
    const updatedWorkflow = {
      ...workflowState,
      ...{
        appChoice: e.currentTarget.value,
        scope: { site_id: "", application: "all" },
        isNextDisabled:
          (e.currentTarget.value &&
            e.currentTarget.value === "custom-application" &&
            (workflowState.scope.application === "" ||
              workflowState.scope.application === "all")) ||
          workflowState.reportName === ""
      }
    };
    saveState(updatedWorkflow);
  };

  const onChangeCustomApplication = e => {
    const workflowState = getWorkflowState();
    const updatedWorkflow = {
      ...workflowState,
      ...{
        scope: {
          ...workflowState.scope,
          ...{ application: e.currentTarget.value }
        },
        isNextDisabled:
          e.currentTarget.value === APP.ALL ||
          (workflowState.reportName === "" && e.currentTarget.value !== APP.ALL)
      }
    };
    saveState(updatedWorkflow);
  };

  const onValidateAgain = () => {
    const workflowState = getWorkflowState();
    onNext(workflowState.reportName);
  }

  return (
    <div className="left-side-container" data-cy="pageOne">
      <div className="header">{i18nMessageBundle.worklfowSetUp}</div>
      <div className="subHeadline">{i18nMessageBundle.worklfowName}</div>
      <div>
        {showLoadingBanner && (
          <HbrBanner sentiment="info" variant="fill" open>
            <HbrIcon slot="icon" name="hbr-error-filled"></HbrIcon>
            {i18nMessageBundle.workflowValidateReport}
          </HbrBanner>
        )}
        {!_.isEmpty(bannerMessage) && (
          <div onClick={onValidateAgain}>
            <HbrBanner sentiment="danger" variant="fill" open>
              <HbrIcon slot="icon" name="hbr-error-filled"></HbrIcon>
              {bannerMessage}
              <HbrLink target="_parent">
                {i18nMessageBundle.workflowValidateAgain}
              </HbrLink>
            </HbrBanner>
          </div>
        )}
        <br />
        <div className="main-title" data-cy="scope"></div>
        <DnxTextfield
          input={_onTitleInput}
          errormsg={error}
          value={reportName}
          maxlength={32}
          disabled={isDisabledWidget}
          data-cy="reportName"
          label={i18nMessageBundle.workflowReportNameLabel}
          required={true}
          validator={"name"}
          deleteicon={false}
        />
        <br />
        <div className="main-title">{i18nMessageBundle.worklfowScope}</div>
        {isAllSitesDropdownShown && (
          <div className="radio-title">{i18nMessageBundle.worklfowSite}</div>
        )}
        {isAllSitesDropdownShown && (
          <DnxSwitchGroup
            name="all-sites"
            direction="column"
            change={onChangeSite}
            value={siteChoice ? siteChoice : SITE.ALL}
          >
            <DnxSwitch
              flavor="radio"
              name="all-sites"
              data-cy="allSiteRadioButton"
              label={i18nMessageBundle.worklfowAllSites}
            />
            <DnxSwitch
              flavor="radio"
              name="custom-sites"
              data-cy="customeSiteRadioButton"
              disabled={true}
              label={i18nMessageBundle.worklfowAllCustomSite}
            />
          </DnxSwitchGroup>
        )}
        {isSiteDropdownShown && isAllSitesDropdownShown && (
          <div style={{ paddingTop: "10px" }}>
            <DnxDropdown
              name="single-site"
              type="single"
              placeholder={i18nMessageBundle.worklfowEnterSite}
              value={site}
              change={e => {
                const workflowState = getWorkflowState();
                const updatedWorkflow = {
                  ...workflowState,
                  ...{
                    scope: {
                      ...workflowState.scope,
                      ...{ site_id: e.currentTarget.value }
                    }
                  }
                };
                saveState(updatedWorkflow);
              }}
              data={sites}
            ></DnxDropdown>
          </div>
        )}
        {isAllSitesDropdownShown && <br />}
        {showAllApplications && (
          <div className="radio-title">
            {i18nMessageBundle.worklfowApplication}
          </div>
        )}
        {showAllApplications && (
          <DnxSwitchGroup
            name="all-application"
            direction="column"
            change={onChangeApplication}
            value={appChoice ? appChoice : APP.ALL}
          >
            <DnxSwitch
              flavor="radio"
              name="all-application"
              data-cy="allAppRadioButton"
              disabled={
                !isExecutiveTemplate(pageName) &&
                isDisabledWidget &&
                fileType === "csv"
              }
              label={i18nMessageBundle.worklfowAllApp}
            />
            <DnxSwitch
              name="custom-application"
              flavor="radio"
              data-cy="customAppRadioButton"
              disabled={
                isExecutiveTemplate(pageName) ||
                (!isExecutiveTemplate(pageName) &&
                  isDisabledWidget &&
                  fileType === "csv")
              }
              label={i18nMessageBundle.worklfowCustomApp}
            />
          </DnxSwitchGroup>
        )}
        {isAppDropdownShown && showAllApplications && (
          <div style={{ paddingTop: "10px" }}>
            <DnxDropdown
              name="single-app"
              type="single"
              placeholder={i18nMessageBundle.worklfowEnterApp}
              flavor="combobox"
              disabled={
                isExecutiveTemplate(pageName) ||
                (!isExecutiveTemplate(pageName) &&
                  isDisabledWidget &&
                  fileType === "csv")
              }
              value={app}
              change={onChangeCustomApplication}
              data={applications}
            ></DnxDropdown>
          </div>
        )}
      </div>
    </div>
  );
};

ScopeView.propTypes = {
  scopeWorkflowState: PropTypes.object.isRequired,
  saveState: PropTypes.func.isRequired,
  pageName: PropTypes.string,
  isAllSitesDropdownShown: PropTypes.bool
};
export default ScopeView;
