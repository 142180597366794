import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { connect, useDispatch } from "react-redux";

import css from "../../reportsMagneticStyle.less";
import {
  DnxSwitchGroup,
  DnxSwitch,
  DnxTime,
  DnxDropdown
} from "../../../../loaders/DNXLoader";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import { isWidgetGradeOut, getWorkflowState } from "../../../../utils/common";
import {
  updateCurrTimeMinute,
  getTimeRepeats,
  getStartOfMinute
} from "../../../../utils/displayTime";
import { REPEATS, SCHEDULER } from "../../../../utils/enums";
import reduxContext from "../../../../generics/AppHOCReport/reducer";
import { setWorkflow } from "../../../../generics/AppHOCReport/actions";
import AppHocReport from "../../../../generics/AppHOCReport";

const DashletSchedule = () => {
  const workflowState = getWorkflowState();
  const isDisabledWidget = isWidgetGradeOut(location);
  const [showRecurringTimeWidget, setShowRecurringTimeWidget] = useState(
    workflowState.scheduleType === SCHEDULER.RECURRING
  );
  const timeValue = workflowState.repeatTime
    ? new Date(workflowState.repeatTime).toString()
    : new Date().toString();
  const repeats = workflowState.scheduleFreq
    ? workflowState.scheduleFreq
    : REPEATS.DAILY;
  const dropdownData = [
    { text: i18nMessageBundle.workflowDaily, value: REPEATS.DAILY },
    { text: i18nMessageBundle.workflowWeekly, value: REPEATS.WEEKLY },
    { text: i18nMessageBundle.workflowMonthly, value: REPEATS.MONTLY }
  ];
  /** Hide the list of weeek days in the schedule page of the workflow 
  const day = workflowState.repeatOn
    ? getRepeatOnDay(workflowState.repeatOn)
    : getRepeatOnDay(1);
  const [dayOfWeek, setdayOfWeek] = useState([day]);
  */
  const dispatch = useDispatch();
  const onSchedule = e => {
    const updatedWorkflow = {
      ...getWorkflowState(),
      ...{ scheduleType: e.currentTarget.value }
    };
    if (e.currentTarget.value === SCHEDULER.RECURRING) {
      setShowRecurringTimeWidget(true);
    } else {
      setShowRecurringTimeWidget(false);
    }
    saveState(updatedWorkflow);
  };

  const changeTimeRange = e => {
    const updatedWorkflow = {
      ...getWorkflowState(),
      ...{ timeRange: e.currentTarget.value }
    };
    saveState(updatedWorkflow);
  };

  const handleChangeTime = e => {
    const currTime = updateCurrTimeMinute(e.currentTarget.value);
    const _workflowState = getWorkflowState();
    const nextRun = getTimeRepeats(_workflowState.scheduleFreq, currTime);
    const updatedWorkflow = {
      ..._workflowState,
      ...{ repeatTime: currTime, nextRun: nextRun }
    };

    saveState(updatedWorkflow);
  };

  const handleChangeDropdown = e => {
    const currTime = getStartOfMinute();
    //if weekly then next_run = currTime + 24*7 hours
    //if daily then next_run = currTime + 24 hours
    const freq = e.currentTarget.value;
    const nextRun = getTimeRepeats(freq, currTime);

    const updatedWorkflow = {
      ...getWorkflowState(),
      ...{ nextRun: nextRun, scheduleFreq: freq }
    };

    saveState(updatedWorkflow);
  };

  /** Hide the list of weeek days in the schedule page of the workflow
  const _changeGroupOfDays = e => {
    const day = e.target.name;

    setdayOfWeek([day]);

    const repeatOn = getRepeatOn(day);
    const updatedWorkflow = {
      ...getWorkflowState(),
      ...{ repeatOn: repeatOn }
    };
    saveState(updatedWorkflow);
  };*/

  const saveState = updatedWorkflow => {
    dispatch(setWorkflow(updatedWorkflow));
  };

  return (
    <div className={css["workflow"]}>
      <React.Fragment>
        {
          <div className="left-side-container" data-cy="pageTwo">
            <div className="header">
              {i18nMessageBundle.worklfowScheduleReport}
            </div>
            <div>
              <br />
              <div className="main-title">
                {i18nMessageBundle.worklfowTimeRange}
              </div>
              <DnxSwitchGroup
                name="time-range"
                direction="column"
                change={changeTimeRange}
                value={workflowState.timeRange}
                disabled={isDisabledWidget}
                data-cy="timeRangeGroup"
              >
                <DnxSwitch
                  flavor="radio"
                  name="daily"
                  data-cy="dailyRadioButton"
                  label={i18nMessageBundle.worklfowDaily}
                />
                <DnxSwitch
                  flavor="radio"
                  name="weekly"
                  data-cy="weeklyRadioButton"
                  label={i18nMessageBundle.worklfowLastWeek}
                />
                <DnxSwitch
                  flavor="radio"
                  name="monthly"
                  data-cy="monthlyRadioButton"
                  label={i18nMessageBundle.worklfowLastMonth}
                />
              </DnxSwitchGroup>
              <br />
              <div className="main-title">
                {i18nMessageBundle.worklfowSchedule}
              </div>
              <DnxSwitchGroup
                name="all-appliation"
                direction="column"
                change={onSchedule}
                value={workflowState.scheduleType}
                disabled={isDisabledWidget}
                data-cy="schedulerGroup"
              >
                <DnxSwitch
                  flavor="radio"
                  name="once"
                  data-cy="onceRadioButton"
                  label={i18nMessageBundle.worklfowRunNow}
                />
                <DnxSwitch
                  flavor="radio"
                  name="recurring"
                  data-cy="recurringRadioButton"
                  label={i18nMessageBundle.worklfowReccuring}
                />
              </DnxSwitchGroup>
              <br />
              <div className="recuring-widget">
                {showRecurringTimeWidget && !isDisabledWidget && (
                  <DnxDropdown
                    name="dp1"
                    type="single"
                    value={repeats}
                    placeholder={i18nMessageBundle.workflowRepeats}
                    change={handleChangeDropdown}
                    data={dropdownData}
                    disabled={isDisabledWidget}
                    maxwidth={"200px"}
                    data-cy="repeatsSelector"
                  ></DnxDropdown>
                )}
                {/** Hide the list of weeek days in the schedule page of the workflow
                {showRecurringTimeWidget && !isDisabledWidget && (
                  <DnxSwitchGroup
                    value={dayOfWeek}
                    flavor="circles"
                    name="selected_days"
                    direction="row"
                    change={_changeGroupOfDays}
                    disabled={isDisabledWidget}
                  >
                    <DnxSwitch
                      flavor="checkbox"
                      label="S"
                      name="Sunday"
                      data-cy="Sunday"
                    ></DnxSwitch>
                    <DnxSwitch
                      flavor="checkbox"
                      label="M"
                      name="Monday"
                      data-cy="Monday"
                    ></DnxSwitch>
                    <DnxSwitch
                      flavor="checkbox"
                      label="T"
                      name="Tuesday"
                      data-cy="Tuesday"
                    ></DnxSwitch>
                    <DnxSwitch
                      flavor="checkbox"
                      label="W"
                      name="Wednesday"
                    ></DnxSwitch>
                    <DnxSwitch
                      flavor="checkbox"
                      label="T"
                      name="Thursday"
                    ></DnxSwitch>
                    <DnxSwitch
                      flavor="checkbox"
                      label="F"
                      name="Friday"
                    ></DnxSwitch>
                    <DnxSwitch
                      flavor="checkbox"
                      label="S"
                      name="Saturday"
                    ></DnxSwitch>
                  </DnxSwitchGroup>
                )} */}
                {showRecurringTimeWidget && !isDisabledWidget && (
                  <DnxTime
                    flavor={12}
                    label={i18nMessageBundle.workflowStart}
                    change={handleChangeTime}
                    value={timeValue}
                    disabled={isDisabledWidget}
                    data-cy="timeWidget"
                  />
                )}
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    </div>
  );
};

DashletSchedule.propTypes = {
  location: PropTypes.object
};

export default reduxContext.withProvider(
  connect()(AppHocReport(DashletSchedule))
);
