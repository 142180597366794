import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

import TimeStamp from "../../../../generics/TimeStamp";
import AppHOC from "../../../../generics/AppHOC";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import PageNotFound from "../../../../common/PageNotFound";
import Spinner from "../../../../common/Spinner";
import MapSummaryComponent from "../../../../common/MapSummaryComponent";
import TopSitesComponent from "../../../../common/MapSummaryComponent/TopSitesComponent";
import GeoMapComponent from "../../../../common/GeoMapComponent";
import SiteListComponent from "../../../sites/SiteListComponent";
import SectionHeading from "../../../../common/SectionHeading";

//utils
import {
  getReportsHeader,
} from "../../../../../src/utils/common";
import { setRumUser } from "../../../../monitoring";
import {
  saveSitesDonutData,
  saveSitesDetailsData,
  saveSitesSummaryData,
  saveSitesOverviewTableData
} from "../../../sites/sitesUtils";
import { EXPERIENCE_COLORS, CUSTOM_MAKER, SWITCH, timeFilterTypesCustomV4, defaultCellValue } from "../../../../utils/enums";

//reducer files
import reduxContext from "./reducer";
import * as actions from "./actions";

//i18n file
import i18n from "amdi18n-loader!../../../nls/i18n";

//style
import css from "../../reportsMagneticStyle.less";

//api config
import ApiService from "../../../../config/api-config";
import { shortDisplayDate24HourWithSec, formatDate } from "../../../../utils/displayTime";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    siteSummary: state.vanalytics.siteSummary
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

const SiteSummary = props => {
  const { OVERVIEW } = SWITCH;
  const envVarForReports = process.env.REACT_APP_SKIP_RBAC_DISABLE_RBAC;
  const {
    scopeHeading,
    allSiteSitesLabel,
    sitesOnMap,
    top50Sites
  } = i18n;

  const reportId = props.match.params.id;

  useEffect(() => {
    const { setReportOptions, setReportApiError } = props.actions;

    const { getReportByUUID, getSitesTableTimeAvailability } = ApiService;

    getReportByUUID(reportId)
      .then(res => {
        if (res.errorObject) throw res;
        const reportObj = res.data[0];

        const {
          overlay_id: overlayId,
          current_period_start: current_period_start,
          current_period_end: current_period_end,
          previous_period_start: previousPeriodStart,
          previous_period_end: previousPeriodEnd,
          created_by: created_by,
          overlay_name: overlayName,
          is_debug: is_debug,
          time_range: timeRange
        } = reportObj;

        localStorage.setItem("currentOverlay", overlayId);
        localStorage.setItem("currentOverlayName", overlayName);

        setRumUser(overlayId);
        let globalV4PayloadReport = {
          time_frame: timeFilterTypesCustomV4[reportObj.time_range],
          entry_ts: {
            start: shortDisplayDate24HourWithSec(formatDate(reportObj.current_period_start).utc()),
            end: shortDisplayDate24HourWithSec(formatDate(reportObj.current_period_end).utc())
          }
        }
        const defaultRequest = { ...globalV4PayloadReport }
        Promise.all([
          getSitesTableTimeAvailability(defaultRequest, overlayId)
        ])
          .then(res => {
            if (res[0].errorObject) {
              props.setOverviewError(res[0]);
            } else {
              let finalData = res[0].data;
              let reportOptions = {
                current_period_start,
                current_period_end,
                previousPeriodStart,
                previousPeriodEnd,
                created_by,
                overlayName,
                is_debug,
                timeRange
              };
              setReportOptions(reportOptions);
              const sitesOverviewTab = saveSitesOverviewTableData(
                finalData.data
              );
              const overviewDonut = saveSitesDonutData(finalData.data);
              const overviewStacked = saveSitesDetailsData(finalData);
              const overviewSummary = saveSitesSummaryData(finalData.data);
              /**** Replace negative values to hyphen for availability status unknown ****/
              finalData &&
                finalData?.data &&
                finalData.data.map(
                  data =>
                    (data.availability === -1
                      ? (data.availability = defaultCellValue)
                      : data.availability) &&
                    (data.availability_prev === -1
                      ? (data.availability_prev = defaultCellValue)
                      : data.availability_prev)
                );

              const result = {
                sitesTableData: finalData,
                overviewSiteDonut: overviewDonut,
                overviewSiteStacked: overviewStacked,
                overviewSiteSummary: overviewSummary,
                sitesOverviewTab: sitesOverviewTab
              };
              props.setSitesTableAvailability(result);
            }
          })
          .catch(err => {
            console.log(err);
            setReportApiError(err);
            props.setOverviewError(err);
          });
      })
      .catch(err => {
        console.log(err);
        setReportApiError(err);
        props.setOverviewError(err);
      });
  }, []);

  if (props.globalFilter.siteAPIData.overviewSiteLoader) {
    return <Spinner />;
  } else {
    const { siteSummary, globalFilter } = props;
    const { siteAPIData } = globalFilter;
    const { reportOptions, error } = siteSummary;

    let fullData = [];
    let mapOptions = {};
    let topSites = [];
    if (
      siteAPIData?.sitesTableData?.data &&
      siteAPIData?.sitesTableData?.data.length > 0
    ) {
      fullData = siteAPIData?.sitesTableData?.data;
      mapOptions = {
        customMarker: CUSTOM_MAKER.CIRCLE,
        disableClustering: false,
        experienceColors: EXPERIENCE_COLORS,
        showNavigation: false,
        showLegend: false
      };

      /*****TOP SITES ******/
      const validSites = fullData?.filter(
        data => data.availability !== defaultCellValue
      );
      if (validSites && validSites.length > 0) {
        const sitesAscending = validSites.sort(
          (a, b) => a.availability - b.availability
        );
        topSites = sitesAscending.slice(0, 10);
      }
    }

    if (
      (siteAPIData &&
        siteAPIData.overviewSiteError &&
        siteAPIData.overviewSiteError.errorObject !== "") ||
      (error && error.errorObject)
    ) {
      return (
        <ErrorComponent
          {...siteAPIData.overviewSiteError}
          className={"large-dashlet-error"}
        />
      );
    }
    if (envVarForReports === "FALSE") {
      if (!reportOptions?.is_debug) {
        return <PageNotFound />;
      }
    }

    if (fullData) {
      return (
        <div className={css["all-sites-report"]}>
          <div className="site-summary">
            {getReportsHeader(reportOptions.overlayName, props.location)}
            <div className="all-sites-scope-section">
              <span className="details">
                <span className="title hbr-type-body3">{scopeHeading}</span>
                <span className="value hbr-type-body3">{allSiteSitesLabel}</span>
              </span>
              <TimeStamp {...props} isReport={true} reportData={reportOptions}/>
            </div>
            <SectionHeading title={i18n.siteSummary} />
            {/***** sites dashboard : overview and top sites table ***/}
            {fullData && fullData.length > 0 ? (
              <div className="site-overview">
                <MapSummaryComponent
                  {...props} sites={fullData}
                  state={OVERVIEW.ACTION}
                  isReport={true}
                  countActionForAllSitesObj={{ sitesLen: fullData.length }}
                  sitesView={"availability"}
                />
                <HbrCard className="overview-summary-top-sites">
                  <TopSitesComponent
                    sites={topSites}
                    reporting={true}
                    sitesView={"availability"}
                  />{" "}
                </HbrCard>
              </div>
            ) : (
                <div>
                  <NoDataAvailable />
                </div>
              )}

            <SectionHeading title={sitesOnMap} />

            {/***** sites dashboard : map ***/}
            {fullData && fullData.length > 0 ? (
              <div className="map-container">
                <GeoMapComponent
                  sites={fullData}
                  options={mapOptions}
                  sitesView={"availability"}
                  isAvailabilityScore={true}
                />
              </div>
            ) : (
                <div id="isLoaded_chart">
                  <NoDataAvailable />
                </div>
              )}

            <SectionHeading title={top50Sites} />

            {fullData && fullData.length > 0 ? (
              <div className="top-fifty-sites-table">
                <SiteListComponent
                  {...props}
                  sitesView={"availability"}
                  reporting={true}
                />
              </div>
            ) : (
                <div id="isLoaded_chart">
                  <NoDataAvailable />
                </div>
              )}
          </div>
        </div>);
    } else {
      if (fullData && fullData.length === 0) {
        return (
          <div id="isLoaded_chart">
            <NoDataAvailable />
          </div>
        );
      } else {
        return (
          <div id="isLoading">
            <Spinner />
          </div>
        );
      }
    }
  }
};

SiteSummary.propTypes = {
  siteSummary: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object,
  globalFilter: PropTypes.object.isRequired,
  setSitesTableAvailability: PropTypes.func,
  setOverviewError: PropTypes.func
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(SiteSummary))
);
