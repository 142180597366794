import React, { useState, useEffect, Suspense, useRef, useMemo } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import reactWrapper from "@harbor/elements/utils/react/wrapper";
import reduxContext from "../../../../generics/AppHOC/reducer";
import AppHoc from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import "../../securityMagneticStyle.less";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import {
  EXPERIENCE_COLORS,
  CUSTOM_MAKER,
  timeFilterTypes,
  TOGGLER
} from "../../../../utils/enums";
import { shortDisplayDate24HourWithSec, getStartfDayUTCFormat, getPreviousYearMonthDateTime } from "../../../../utils/displayTime";

import { isCustom } from "../../../../utils/common";
import ApiService from "../../../../config/api-config";

const [HbrIcon, HbrViewSwitcher, HbrRadioButton, HbrCard] = reactWrapper([
  "hbr-icon",
  "hbr-view-switcher",
  "hbr-radio-button",
  "hbr-card",
]);

const NoDataAvailable = React.lazy(() => import("../../../../common/NoDataAvailable"));
const GeoMapComponent = React.lazy(() => import("../../../../common/GeoMapComponent"));
const ErrorComponent = React.lazy(() => import("../../../../common/ErrorComponent"));

const mapStateToProps = state => {
  return {
    globalFilter: state.vanalytics.app.globalFilter
  };
};

const TalosComponent = props => {
  const { globalFilter } = props;
  const [ toggle, setToggle ] = useState(TOGGLER.TALOSGLOBALTHREATS);
  const [ iconState, setIconState ] = useState(false);
  
  const talosSitesRef = useRef({});
  const mapOptions = {
    customMarker: CUSTOM_MAKER.CIRCLE,
    disableClustering: false,
    experienceColors: EXPERIENCE_COLORS,
    showNavigation: false,
    showLegend: false,
    mapSettings: {
        zoom: 1,
        center: [0,0],
        doubleClickZoom: false,
        scrollZoom: false 
    },
    tooltipSettings : {
        showTooltipOnClusterClick: false,
        tooltipOnHover: false
    }
  };

  const extraStyleMapContainer = props.mapSummaryStyle
    ? props.mapSummaryStyle
    : { padding: "12px" };

  const handleMapView = evt => {
    setToggle(evt.currentTarget.value); // toggle over talos global threats or talos accessed threats
  };

  const showHideMapSummary = () => {
    setIconState( iconState ? false : true);
  };

  const getInfoIcon = () => (
    <hbr-tooltip hoist>
        <div slot="content">{i18nMessageBundle.securityTabView.talosInfoAllThreats}</div>
        <div slot="content">{i18nMessageBundle.securityTabView.talosInfoThreatsAccessed}</div>
      <hbr-icon name="info" sentiment="neutral"></hbr-icon>
    </hbr-tooltip>);

  useEffect(async () => {
    talosSitesRef.current = undefined;
    getPreviousYearMonthDateTime(globalFilter.timeFilter.current_period[1]);
    const timeFilterType = isCustom(globalFilter.timePeriodSelected) ? '12h' : timeFilterTypes[globalFilter.timePeriodSelected];

    const payload = {
      entry_ts: {
        start: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(globalFilter.timeFilter.current_period[0])),
        end: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(globalFilter.timeFilter.current_period[1]))
      },
      time_frame: timeFilterType,
      size: 10000
    }

    const query = {
      selectedOverlay: localStorage.getItem("currentOverlay"),
      timePeriodSelected: globalFilter.timePeriodSelected,
      timeRange1: globalFilter.timeFilter.current_period[0],
      timeRange2: globalFilter.timeFilter.current_period[1],
      currentTimeStamp: globalFilter.currentTimeStamp
    };

    if (
      query.timePeriodSelected !==
      globalFilter.talosAPIQuery?.timePeriodSelected ||
      query.timeRange1 !== globalFilter.talosAPIQuery?.timeRange1 ||
      query.timeRange2 !== globalFilter.talosAPIQuery?.timeRange2 ||
      query.currentTimeStamp !==
      globalFilter.talosAPIQuery?.currentTimeStamp ||
      query.selectedOverlay !== globalFilter.talosAPIQuery?.selectedOverlay
    ) {
      props.setTalosLoader(true);
      const promises = [
        await ApiService.getTalosGlobalThreats(payload),
        await ApiService.getTalosAccessSummary(payload)
      ];

      Promise.all(promises)
        .then(results => {
          if (results[0].errorObject) {
            props.setTalosError(results[0]);
          } else if (results[1].errorObject) {
            props.setTalosError(results[1]);
          } else {
            const talosUntrustedSites = results[1].data.data.map(itm => ({
              ...results[0].data.data.find((item) => (item.country === itm.country && itm.sdwan_threat_count !== undefined) && item),
              ...itm
            }));
            const talosGlobalSites = results[0].data.data;
            const result = {
              talosData : {
                talosUntrustedSites: talosUntrustedSites,
                talosGlobalSites : talosGlobalSites,
              },
              talosAPIQuery: query
            };
              props.setTalosSites(result);       
          }
        })
        .catch(error => {
          props.setTalosError(error);
        });
    }
  }, [
    globalFilter.refreshTo,
    globalFilter.timePeriodSelected,
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp
  ]);

  useMemo(() => {
    const talosData = toggle === TOGGLER.TALOSGLOBALTHREATS ? globalFilter.talosData?.talosGlobalSites : globalFilter.talosData?.talosUntrustedSites;
    talosSitesRef.current = talosData;
  },[toggle, globalFilter.talosSitesLoader, iconState, globalFilter.talosData]);

  if (globalFilter.talosSitesError && globalFilter.talosSitesError !== null) {
    return (
      <div>
        <Suspense fallback={<Spinner />}>
          <ErrorComponent
            {...globalFilter?.talosSitesError}
            width={"300px"}
            className={"large-dashlet-error"}
          />
        </Suspense>
      </div>
    );
  } else if(globalFilter.talosSitesLoader || talosSitesRef.current === undefined) {
    return <Spinner /> 
  } else if (globalFilter.talosData !== undefined && talosSitesRef.current && talosSitesRef.current?.length > 0) {
      return (
        <div className="map-container-sites" style={extraStyleMapContainer}>
          <div className="outer-div">
            <HbrCard
              id="non-interactive"
              slot="label"
              className="sites-widget-card"
            >
              <div className="map-summary-total-sites hbr-type-h3">
                <div className={!iconState ? 'heading-text' : 'heading-text-adjust'}>{i18nMessageBundle.securityTabView.threatsOverview} &nbsp; {getInfoIcon()}</div>
                <div className="site-toggle-icon" onClick={showHideMapSummary}>
                  {iconState ? (
                    <HbrIcon
                      className="qoeTitleIcon"
                      name="caret-down"
                      sentiment="neutral"
                    ></HbrIcon>
                  ) : (
                      <HbrIcon
                        className="qoeTitleIcon"
                        name="caret-up"
                        sentiment="neutral"
                      ></HbrIcon>
                    )}
                </div>
              </div>
              {!iconState && (<div>
                <HbrViewSwitcher
                  value={toggle}
                  name="selected_view"
                  onHbr-change={handleMapView}
                  className="site-map-switch"
                >
                  <HbrRadioButton value={TOGGLER.TALOSGLOBALTHREATS}>
                    {i18nMessageBundle.securityTabView.all}
                  </HbrRadioButton>
                  <HbrRadioButton value={TOGGLER.TALOSACCESSTHREATS}>
                    {i18nMessageBundle.securityTabView.threatsAccessed}
                  </HbrRadioButton>
                </HbrViewSwitcher>
              </div>)}
            </HbrCard>
          </div>
          {talosSitesRef.current && talosSitesRef.current.length > 0 && (
            <Suspense fallback={<Spinner />}>
              <GeoMapComponent
                {...props}
                sitesView={props.mapView}
                sites={talosSitesRef.current}
                options={mapOptions}
                isAvailabilityScore={true}
              />
            </Suspense>
          )}
        </div>
      );
  } else {
    return (
      <Suspense fallback={<Spinner />}>
        <div className="map-container-sites" style={extraStyleMapContainer}>
          <div className="outer-div">
            <HbrCard
              id="non-interactive"
              slot="label"
              className="sites-widget-card"
            >
              <div className="map-summary-total-sites hbr-type-h3">
                <div className={!iconState ? 'heading-text' : 'heading-text-adjust'}>{i18nMessageBundle.securityTabView.threatsOverview} &nbsp; {getInfoIcon()}</div>
                <div className="site-toggle-icon" onClick={showHideMapSummary}>
                  {iconState ? (
                    <HbrIcon
                      className="qoeTitleIcon"
                      name="caret-down"
                      sentiment="neutral"
                    ></HbrIcon>
                  ) : (
                      <HbrIcon
                        className="qoeTitleIcon"
                        name="caret-up"
                        sentiment="neutral"
                      ></HbrIcon>
                    )}
                </div>
              </div>
              {!iconState && (<div>
                <HbrViewSwitcher
                  value={toggle}
                  name="selected_view"
                  onHbr-change={handleMapView}
                  className="site-map-switch"
                >
                  <HbrRadioButton value={TOGGLER.TALOSGLOBALTHREATS}>
                    {i18nMessageBundle.securityTabView.all}
                  </HbrRadioButton>
                  <HbrRadioButton value={TOGGLER.TALOSACCESSTHREATS}>
                    {i18nMessageBundle.securityTabView.threatsAccessed}
                  </HbrRadioButton>
                </HbrViewSwitcher>
              </div>)}
            </HbrCard>
          </div>
          <NoDataAvailable
            text={toggle === TOGGLER.TALOSACCESSTHREATS ? i18nMessageBundle.securityTabView.noThreatsAccessedSiteOnMap : i18nMessageBundle.securityTabView.noThreatsGlobalSiteOnMap}
            customClass="talos-no-sites"
          />
        </div>
      </Suspense>
    )
  }
};

TalosComponent.propTypes = {
  setTalosSites: PropTypes.func,
  setTalosLoader: PropTypes.func,
  setTalosError: PropTypes.func,
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object,
  mapSummaryStyle: PropTypes.object,
  mapView: PropTypes.string.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, null)(AppHoc(TalosComponent))
);