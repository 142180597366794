import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export default {
  title: i18nMessageBundle.circuitViewCircuits,
  theme: "quality",
  text: {
    good: {
      title: i18nMessageBundle.applicationDonutLabelGoodAvailable,
      tooltip: i18nMessageBundle.applicationOverviewLabelGood
    },
    fair: {
      title: i18nMessageBundle.applicationDonutLabelFairAvailable,
      tooltip: i18nMessageBundle.applicationOverviewLabelFair
    },
    bad: {
      title: i18nMessageBundle.applicationDonutLabelPoorAvailable,
      tooltip: i18nMessageBundle.applicationOverviewLabelPoor
    },
    unknown: {
      title: i18nMessageBundle.applicationDonutLabelNAAvailable,
      tooltip: i18nMessageBundle.applicationOverviewLabelNotAvailable
    }
  }
};
