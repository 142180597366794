import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export default {
  title: i18nMessageBundle.applicationOverviewLabelTopDevices,
  subTitle: i18nMessageBundle.applicationOverviewLabelTopDevices,
  columns: [
    {
      title: i18nMessageBundle.applicationOverviewLabelDevices,
      colSpan: 1,
      property: "local_host_name",
      type: "string",
      format: "link",
      maxLength: 15,
      width: "30%"
    },
    {
      title: `% ${i18nMessageBundle.dashletSiteViewAvailability}`,
      colSpan: 2,
      property: "curr_up_percent",
      type: "number",
      format: "percent",
      theme: "availability",
      width: "0%"
    },
    {
      title: null,
      colSpan: 0,
      property: "change_percentage",
      type: "number",
      format: "changePercent",
      theme: "changeQuality",
      width: "15%"
    },
    {
      title: i18nMessageBundle.applicationOverviewLabelBandwidthMbps,
      colSpan: 1,
      property: "bandwidth",
      type: "bits",
      format: "Kbps",
      width: "25%"
    },
    {
      title: i18nMessageBundle.userDataUsage,
      colSpan: 1,
      property: "usage",
      type: "bytes",
      format: "GB",
      decimal: 1
    }
  ]
};
