import React, { useEffect, useRef, useState } from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//components
import AppHoc from "../../../../generics/AppHOC";
import TimeStamp from "../../../../generics/TimeStamp";
import GeoMapComponent from "../../../../common/GeoMapComponent";
import QoeChart from "./QoeChart";
import SiteList from "./SiteList";
import TopUsers from "./TopClients";
import AppFlow from "./AppFlow";
import SectionHeading from "../../../../common/SectionHeading";
import MapSummaryComponent from "../../../../common/MapSummaryComponent";
import TopSitesComponent from "../../../../common/MapSummaryComponent/TopSitesComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";

//styles
import css from "../../reportsMagneticStyle.less";

//reducers files
import reduxContext from "./reducer";
import * as actions from "./actions";

//i18n files
import i18n from "amdi18n-loader!../../../nls/i18n";

//utils
import {
  getReportsHeader,
  getReportTimeRangeDate
} from "../../../../utils/common";
import apiService from "../../../../config/api-config";
import {
  saveSitesDonutData,
  saveSitesDetailsData,
  saveSitesSummaryData,
  saveSitesOverviewTableData
} from "../../../sites/sitesUtils";
import {
  EXPERIENCE_COLORS, CUSTOM_MAKER,
  SWITCH,
  fields,
  timeFilterTypesCustomV4
} from "../../../../utils/enums";
import Spinner from "../../../../common/Spinner";
import {
  shortDisplayDate24HourWithSec,
  getStartfDayUTCFormat,
  getFormattedUTCDate
} from "../../../../utils/displayTime";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    app360: state.vanalytics.app360_report
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

const App360Summary = props => {
  const {
    qoe_chart_loader,
    tunnel_table_loader,
    top_users_loader,
  } = props.app360;
  const { siteAPIData } = props.globalFilter;
  const { OVERVIEW } = SWITCH;
  const mapOptions = {
    customMarker: CUSTOM_MAKER.CIRCLE,
    disableClustering: false,
    experienceColors: EXPERIENCE_COLORS,
    showNavigation: false,
    showLegend: false
  };
  const appName = props.match.params.appName;
  const previewRef = useRef(null);
  const report_data = props.location.state;
  const v4Payload = {
    time_frame: timeFilterTypesCustomV4[report_data.time_range],
    entry_ts: {
      start: getFormattedUTCDate(report_data.current_period_start),
      end: getFormattedUTCDate(report_data.current_period_end)
    },
    application: appName
  };
  const [siteTableLoader, setSiteTableLoader] = useState(true);
  const hasPageLoaded = !qoe_chart_loader &&
    !tunnel_table_loader &&
    !top_users_loader

  let fullData = siteAPIData?.app360Sites?.data?.data
  let topSites = {};

  /*****TOP SITES ******/
  const validSites = fullData?.filter(
    data => data.vqoe_status !== "unknown"
  );

  if (validSites && validSites.length > 0) {
    const sitesAscending = validSites.sort(
      (a, b) => a.vqoe_score - b.vqoe_score
    );
    topSites = sitesAscending.slice(0, 10);
  }

  useEffect(() => {
    const getSites = async () => {
      setSiteTableLoader(true);
      const overlayId = report_data.overlay_id;
      const timeRange = report_data.time_range;
      const timeFrame = getReportTimeRangeDate(timeRange);

      const payload = {
        application: appName,
        time_frame: timeFrame,
        ...{ fields: fields },
        entry_ts: {
          start: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(report_data.current_period_start)),
          end: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(report_data.current_period_end))
        }
      };

      const sitesResponse = await apiService.getMapViewSitesOptimized(payload, overlayId);

      if (!sitesResponse.errorObject) {

        let finalData = sitesResponse.data.data;

        const sitesOverviewTab = saveSitesOverviewTableData(finalData);
        const overviewDonut = saveSitesDonutData(finalData);
        const overviewStacked = saveSitesDetailsData(finalData.data);
        const overviewSummary = saveSitesSummaryData(finalData);
        /**** Replace negative values to hyphen for availability status unknown ****/
        finalData &&
          finalData.map(
            data =>
              (data.availability === -1
                ? (data.availability = "-")
                : data.availability) &&
              (data.availability_prev === -1
                ? (data.availability_prev = "-")
                : data.availability_prev)
          );

        const result = {
          sitesTableData: finalData,
          overviewSiteDonut: overviewDonut,
          overviewSiteStacked: overviewStacked,
          overviewSiteSummary: overviewSummary,
          sitesOverviewTab: sitesOverviewTab,
          app360Sites: sitesResponse
        };

        setSiteTableLoader(false);
        props.setSitesTableAvailability(result);
      } else {
        setSiteTableLoader(false);
        props.setOverviewError(sitesResponse);
      }
    }
    getSites();
  }, []);

  return (
    <>
      {report_data && !siteTableLoader && (
        <div className={css["app360-summary-report"]} ref={previewRef}>
          {getReportsHeader(report_data.overlay_name, props.location)}
          <div className="app-360-scope-section">
            <span className="details">
              <span className="title hbr-type-body3">{i18n.scopeHeading}</span>
              <span className="app-360-name hbr-type-body3">{appName.charAt(0).toUpperCase() + appName.slice(1)}</span>
              <span className="value hbr-type-body3">{i18n.applicationsHeading}</span>
            </span>
            <TimeStamp {...props} isReport={true} reportData={report_data} />
          </div>

          {/***** Map summary overview ***/}
          <SectionHeading title={"Application"} />
          {fullData && fullData.length > 0 ? (
            <div className="widget-wrapper">
              <div className="app-360-site-overview">
                <MapSummaryComponent
                  {...props}
                  isQoEMetric={true}
                  isReporting={true}
                  sites={fullData}
                  state={OVERVIEW.ACTION}
                  sitesView={"availability"}
                />
                <HbrCard className="app-360-top-sites">
                  <TopSitesComponent
                    {...props}
                    sites={topSites}
                    isReporting={true}
                    sitesView={"availability"}
                  />
                </HbrCard>
              </div>
            </div>
          ) : (
              <div>
                <NoDataAvailable />
              </div>
            )}
          <SectionHeading title={i18n.app360QoeSummary} />
          <div className="widget-wrapper">
            <HbrCard className="app-360-qoe-chart">
              <QoeChart
                globalV4PayloadReport={v4Payload}
                reportData={report_data}
              />
            </HbrCard>
          </div>

          {/***** Map view ***/}
          <SectionHeading title={"Map view"} />
          {fullData && fullData.length > 0 && hasPageLoaded ? (
            <div className="widget-wrapper">
              <div className="app360-report-map-container">
                <GeoMapComponent
                  sites={fullData}
                  options={mapOptions}
                  sitesView={"availability"}
                />
              </div>
            </div>
          ) : hasPageLoaded ?
              (
                <div id="isLoaded_chart">
                  <NoDataAvailable />
                </div>
              ) : (
                <div className="map-spinner">
                  <Spinner />
                </div>
              )
          }
          <SectionHeading title={i18n.reportApp360Top50SitesList} />
          <div className="widget-wrapper">
            {fullData && <SiteList sitesData={fullData.length > 50 ? fullData.slice(0, 50) : fullData} />}
          </div>
          <SectionHeading title={i18n.reportApp360TopAppFlowLabel} />
          <div className="widget-wrapper">
            <AppFlow />
          </div>
          <SectionHeading title={i18n.reportApp360Top10UsersLabel} />
          <div className="widget-wrapper">
            <TopUsers />
          </div>
        </div>
      )}
    </>
  );
};

App360Summary.propTypes = {
  app360: PropTypes.object.isRequired,
  actions: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  setSitesTableAvailability: PropTypes.func,
  setOverviewError: PropTypes.func,
  globalFilter: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(App360Summary))
);
