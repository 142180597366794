import React, { useState, useEffect, Suspense } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import reduxContext from "../../../../generics/AppHOC/reducer";
import AppHoc from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import "../../securityMagneticStyle.less";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import {
  EXPERIENCE_COLORS,
  CUSTOM_MAKER,
  TOGGLER,
  timeFilterTypes
} from "../../../../utils/enums";
import map from "../../../../assets/map.svg";

const SdwanTable = React.lazy(() => import("./SdwanTable"));
const NoDataAvailable = React.lazy(() => import("../../../../common/NoDataAvailable"));
const GeoMapComponent = React.lazy(() => import("../../../../common/GeoMapComponent"));
const ErrorComponent = React.lazy(() => import("../../../../common/ErrorComponent"));
import { isCustom } from "../../../../utils/common";
import ApiService from "../../../../config/api-config";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { shortDisplayDate24HourWithSec, getStartfDayUTCFormat, getPreviousYearMonthDateTime } from "../../../../utils/displayTime";
const [HbrIcon, HbrViewSwitcher, HbrRadioButton] = reactWrapper([
  "hbr-icon",
  "hbr-view-switcher",
  "hbr-radio-button"
]);

const mapStateToProps = state => {
  return {
    globalFilter: state.vanalytics.app.globalFilter
  };
};

const SdwanComp = props => {
  const { globalFilter } = props;
  const [toggle, setToggle] = useState(TOGGLER.MAP);
  const [siteMapData, setSiteMapData] = useState(undefined);
  const [siteSummaryData, setSiteSummaryData] = useState(undefined);

  const handleMapView = evt => {
    // taggler over map or site list table
    setToggle(evt.currentTarget.value);
  };

  useEffect(async () => {
    getPreviousYearMonthDateTime(globalFilter.timeFilter.current_period[1]);
    const timeFilterType = isCustom(globalFilter.timePeriodSelected) ? '12h' : timeFilterTypes[globalFilter.timePeriodSelected];

    const payload = {
      entry_ts: {
        start: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(globalFilter.timeFilter.current_period[0])),
        end: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(globalFilter.timeFilter.current_period[1]))
      },
      fields: [
        "site_id",
        "site_name",
        "threat_type",
        "threat_count",
        "threat_access_count"
      ],
      time_frame: timeFilterType,
      size: 10000,
      sort: {
        site_id: 'desc',
      }
    }
    const query = {
      selectedOverlay: localStorage.getItem("currentOverlay"),
      timePeriodSelected: globalFilter.timePeriodSelected,
      timeRange1: globalFilter.timeFilter.current_period[0],
      timeRange2: globalFilter.timeFilter.current_period[1],
      currentTimeStamp: globalFilter.currentTimeStamp
    };
    if (
      query.timePeriodSelected !==
      globalFilter.sdwanAPIQuery.timePeriodSelected ||
      query.timeRange1 !== globalFilter.sdwanAPIQuery.timeRange1 ||
      query.timeRange2 !== globalFilter.sdwanAPIQuery.timeRange2 ||
      query.currentTimeStamp !==
      globalFilter.sdwanAPIQuery.currentTimeStamp ||
      query.selectedOverlay !== globalFilter.sdwanAPIQuery.selectedOverlay
    ) {
      props.setSdwanSiteSummaryLoader(true);
      ApiService.getSecuritySdwanData(payload).then(
        res => {
          if (res && !res.errorObject) {
            const resultAPI = {
              sdwanSiteSummary: res.data,
              sdwanAPIQuery: query
            };
            props.setSdwanSiteSummary(resultAPI);
          } else {
            props.setSdwanSiteSummaryError(res);
          }
        }
      );
    }
  }, [
    globalFilter.refreshTo,
    globalFilter.timePeriodSelected,
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp
  ]);

  const extraStyleMapContainer = props.mapSummaryStyle
    ? props.mapSummaryStyle
    : { padding: "12px" };

  const mapOptions = {
    customMarker: CUSTOM_MAKER.CIRCLE,
    disableClustering: false,
    experienceColors: EXPERIENCE_COLORS,
    mapSettings: {
      zoom: 0,
    }
  };

  useEffect(() => {
    if (siteSummaryData !== undefined || siteMapData !== undefined) {
      setSiteSummaryData(undefined);
      setSiteMapData(undefined);
    }

    if (!globalFilter.sdwanSiteSummaryLoader && !globalFilter.siteAPIData.overviewSiteLoader) {
      let sdwanSiteData = globalFilter?.sdwanSiteSummary?.data;  // sdwan site summary data 
      let siteTableData = globalFilter?.siteAPIData?.sitesTableData?.data;    // site api data       

      if (siteTableData && siteTableData?.length > 0 && sdwanSiteData && sdwanSiteData?.length > 0) {
        /**** change sdwan sites avaialablity to 0 in site table data *******/
        const newData = siteTableData && siteTableData.map(item => {
          const matchingData = sdwanSiteData.some(
            siteDataObj => item.site_id === siteDataObj.site_id
          );
          return {
            ...item,
            availability: matchingData ? 0 : 100
          };
        });
        setSiteMapData(newData);

        /**** Add location to sdwan site summary data *******/
        sdwanSiteData.forEach(siteListObj => {
          const matchingSite = siteTableData.find(
            siteDataObj => siteListObj.site_id === siteDataObj.site_id
          );
          if (matchingSite) {
            siteListObj.location = matchingSite.city; // Getting location for Site Table from Site Data
            siteListObj.site_name = matchingSite.site_name;
          }
        });
        setSiteSummaryData(sdwanSiteData);
      } else {
        const newData = siteTableData && siteTableData.map(item => {
          return {
            ...item,
            availability: 100
          };
        });
        setSiteMapData(newData);
        setSiteSummaryData([]);
      }
    }
  }, [
    globalFilter.sdwanSiteSummary,
    globalFilter.siteAPIData
  ]);

  if (globalFilter?.sdwanSiteSummaryError || globalFilter?.siteAPIData?.overviewSiteError) {
    return (
      <div>
        <Suspense fallback={<Spinner />}>
          {globalFilter?.sdwanSiteSummaryError !== null ?
            (<ErrorComponent
              {...globalFilter?.sdwanSiteSummaryError}
              width={"300px"}
              className={"large-dashlet-error"}
            />) :
            (<ErrorComponent
              {...globalFilter?.siteAPIData?.overviewSiteError}
              width={"300px"}
              className={"large-dashlet-error"}
            />)
          }
        </Suspense>
      </div>
    );
  }
  else if (props.globalFilter.sdwanSiteSummaryLoader || siteSummaryData === undefined || siteMapData === undefined) {
    return (
      <Spinner />
    )
  }
  else if (siteMapData?.length > 0) {
    return (
      <div className="map-container-sites" style={extraStyleMapContainer}>
        <div className="map-list-toggler-sites">
          <HbrViewSwitcher
            value={toggle}
            name="selected_view"
            onHbr-change={handleMapView}
            className="site-map-switch"
          >
            <HbrRadioButton value={TOGGLER.MAP}>
              <HbrIcon sentiment="neutral" src={map} />
            </HbrRadioButton>
            <HbrRadioButton value={TOGGLER.LIST}>
              <HbrIcon name="list-bullets"></HbrIcon>
            </HbrRadioButton>
          </HbrViewSwitcher>
        </div>
        {toggle === TOGGLER.MAP ? (
          <div className="map-sdwan-list-sites">
            {siteMapData && siteMapData.length > 0 ? (
              <Suspense fallback={<Spinner />}>
                <GeoMapComponent
                  {...props}
                  sitesView={props.mapView}
                  sites={siteMapData}
                  options={mapOptions}
                  isAvailabilityScore={true}
                />
              </Suspense>
            ) : (
                <Suspense fallback={<Spinner />}>
                  <NoDataAvailable
                    text={i18nMessageBundle.securityTabView.noSitesOnMap}
                  />
                </Suspense>
              )}
          </div>
        ) : siteSummaryData && siteSummaryData.length > 0 ? (
          <Suspense fallback={<Spinner />}>
            <SdwanTable {...props} siteSummaryData={siteSummaryData} />
          </Suspense>
        ) : (
              <Suspense fallback={<Spinner />}>
                <NoDataAvailable
                  text={i18nMessageBundle.securityTabView.noSitesOnMap}
                />
              </Suspense>
            )}
      </div>
    );
  } else {
    return (
      <Suspense fallback={<Spinner />}>
        <NoDataAvailable
          text={i18nMessageBundle.securityTabView.noSitesOnMap}
        />

      </Suspense>
    )
  }
};

SdwanComp.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  sdwanData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setSdwanSiteSummary: PropTypes.func,
  setSdwanSiteSummaryLoader: PropTypes.func,
  setSdwanSiteSummaryError: PropTypes.func,
  actions: PropTypes.object,
  mapSummaryStyle: PropTypes.object,
  mapView: PropTypes.string.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, null)(AppHoc(SdwanComp))
);

