import { parseSiteSummary } from "../../config/apiParsers/overview";
import { defaultCellValue, tLocState } from "../../utils/enums";

/*** get availabilty status count  */
export const getAvailStatuscount = (data, status) => {
  return data.filter(i => i.availability_status === status).length;
};

export const getPrevAvailStatuscount = (data, status) => {
  return data.filter(i => i.availability_status_prev === status).length;
};

/***   overiew page sites widget donut data json object struture formation  ***/

export const saveSitesDonutData = data => {
     let good = 0;
      let fair = 0;
      let bad = 0;
      let unknown = 0;
        data.forEach(item => {
        const availability = item.availability ===  defaultCellValue ?  defaultCellValue : parseInt(item.availability);
        if (availability < 80) bad++;
        else if (availability < 95) fair++;
        else if (availability === defaultCellValue) unknown++;
        else good++;
      });

  return {
    summary: {
      bad: bad,
      fair: fair,
      good: good,
      notAvailable: unknown
    }
  };
};

const sortedSitesList = sitesList => {
  return sitesList.sort((a, b) => {
    return parseFloat(a.up_percent) - parseFloat(b.up_percent);
  });
};

export const sortedSitesListDesc = sitesList => {
  return sitesList.sort((a, b) => {
    return parseFloat(b.up_percent) - parseFloat(a.up_percent);
  });
};

export const availabilityCheck = res => {
  /**** Replace negative values to hyphen for availability status unknown ****/

  res &&
    res?.data &&
    res.data.map(data => {
      if (data.availability < -1) {
        data.availability = 0;
      } else if (data.availability === -1) {
        data.availability = defaultCellValue;
      } else {
        data.availability;
      }
      if (data.availability_prev < -1) {
        data.availability_prev = 0;
      } else if (data.availability_prev === -1) {
        data.availability_prev = defaultCellValue;
      } else {
        data.availability_prev;
      }
    });
  return res;
};

/***   overiew page sites widget bottom avaialbility sites data json object struture formation  ***/

export const saveSitesDetailsData = res => {
  const resultData = availabilityCheck(res);
  let details = [];
  var details_array = [];
  resultData &&
    resultData?.data
        .filter(datalist => Number.isFinite(datalist.availability ))
      .map(item => {
        details = {
          site_name: item.site_name,
          city: item.city,
          up_percent: item.availability,
          up_percent_change: item.availability_change
        };
        details_array.push(details);
      });
  const sortedResult = sortedSitesList(details_array);
  let detail = { detail: sortedResult };
  const parsedResult = parseSiteSummary(detail);
  const result = parsedResult.detail.slice(0, 5);
  return result;
};

/***   sites dashboard top 10 sites json object struture formation  ***/

export const getTopSiteListData = data => {
  const sites = [];

  for (const item of data) {
    if (
      ![tLocState.adminDown, tLocState.unknown].includes(item.site_state)
      && item.availability !== defaultCellValue
      && item.availability_prev !== defaultCellValue
    ) {
      sites.push({
        site_name: item.site_name,
        city: item.city,
        up_percent: item.availability,
        up_percent_change: item.availability_change
       });
    }
  }

  const result = parseSiteSummary({ detail: sortedSitesListDesc(sites) });
  return result.detail.slice(0, 5);
};

/***   overview page sites summary data object struture formation  ***/

export const saveSitesSummaryData = data => {
  let changeSiteAvail = 0;
  let currentSiteAvail = 0;
  if (data.length > 0) {
    let goodCount = 0;
    let goodCountPrev = 0;
    data.forEach(item => {
      let availability = parseInt(item.availability);
      let availability_prev = parseInt(item.availability_prev)
      if (availability > 95) goodCount++;
      if (availability_prev > 95) goodCountPrev++;
    });
    const validCount = data.filter(i => i.availability != defaultCellValue).length;
    const validCountPrev = data.filter(i => i.availability_prev != defaultCellValue).length;

    currentSiteAvail = validCount === 0 ? validCount : (100 * goodCount) / validCount;

    const prevSiteAvail = validCountPrev === 0 ? validCountPrev : (100 * goodCountPrev) / validCountPrev;

    changeSiteAvail = currentSiteAvail - prevSiteAvail;
  }

  const summary = {
    site_availability: {
      // overview high availability
      change: changeSiteAvail,
      current: currentSiteAvail
    }
  };
  return summary;
};

/**** site dashboard page : oveview popup data object struture formation  ***/

export const saveSitesOverviewTableData = data => {

  let goodCount = 0;
  let fairCount = 0;
  let lowCount = 0;
  let grayCount = 0;
  let goodPrevCount = 0;
  let fairPrevCount = 0;
  let lowPrevCount = 0;
  let grayPrevCount = 0;
  data.forEach(item => {
    const availability = Number.isFinite(item.availability ) ? item.availability: defaultCellValue ;
    if (availability < 80) lowCount++;
    else if (availability < 95) fairCount++;
    else if (availability === defaultCellValue) grayCount++;
    else goodCount++;

          const availability_prev = parseInt(item.availability_prev);
        if (availability_prev < 80) lowPrevCount++;
        else if (availability_prev < 95) fairPrevCount++;
        else if (availability_prev === defaultCellValue) grayPrevCount++;
        else goodPrevCount++;
  });

  const totalSites = goodCount + fairCount + lowCount + grayCount;

  const overviewSiteData = {
    fair: {
      count: fairCount,
      count_change: fairCount - fairPrevCount
    },
    high: {
      count: goodCount,
      count_change: goodCount - lowPrevCount
    },
    low: {
      count: lowCount,
      count_change: lowCount - goodPrevCount
    },
    gray: {
      count: grayCount,
      count_change: grayCount - grayPrevCount
    },
    total_sites: totalSites
  };
  return overviewSiteData;
};

/**** Formating site location names to have first letters in uppercase. for example: "San Jose" instead of "san jose" ***/
export const formatLocationName = (sites) => {
  return sites.map(item => {
    return {...item, city: item.city.replace(/(^\w|\s\w)/g, m => m.toUpperCase())};
  });
}
