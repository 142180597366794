import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";
import { formatBits, formatSiteTableBytes, formatString } from "../../../../../utils/format";

export const columnsName = () => [
  {
    prop: "site_name",
    name: i18nMessageBundle.dashletSiteViewSites,
    size: 60,
    readonly: true,
    cellProperties: () => {
      return {
        class: {
          "hbr-table-cell-readonly-background-color": true
        }
      };
    }
  },
  {
    prop: "up_percent",
    name: i18nMessageBundle.dashletSiteViewAvailability,
    size: 108,
    readonly: true,
    cellProperties: () => {
      return {
        class: {
          "hbr-table-cell-readonly-background-color": true
        }
      };
    },
    columnTemplate: createElement => {
      const content = createElement("div", {
        style: {
          display: "flex",
          alignItems: "center"
        },
        innerHTML: `${i18nMessageBundle.dashletSiteViewAvailability}
                      <hbr-tooltip
                      content-id="some-id"
                      content="Site availability and trend (+/-) compared with previous time range selection"
                    >
                      <hbr-icon name="info" sentiment="neutral"></hbr-icon>
                    </hbr-tooltip>`
      });
      return [content];
    }
  },
  {
    prop: "location",
    name: i18nMessageBundle.siteViewLocation,
    size: 100,
    readonly: true,
    cellProperties: () => {
      return {
        class: {
          "hbr-table-cell-readonly-background-color": true
        }
      };
    }
  }
];

export const getSource = data => {
  return data.map(element => {
    return {
      application: element.site_name,
      vqoe_score: element.up_percent
    };
  });
};

export const columnsDeviceName = [
  {
    prop: "local_host_name",
    name: i18nMessageBundle.applicationOverviewLabelTopDevices,
    size: 180,
    readonly: true,
    cellProperties: () => {
      return {
        class: {
          "hbr-table-cell-readonly-background-color": true
        }
      };
    },
    cellTemplate: (createElement, props) => {
      let truncatedData = formatString(props.model.local_host_name, 12);
      const getHTML = () => {
        return `
         <div class="application-trending-apps-qoe-widget-link"
             id="sites-table-site-${props.model.local_host_name}">${truncatedData}</div>
            <hbr-portal>
             <hbr-tooltip anchor="#sites-table-site-${props.model.local_host_name}">
               <div slot="content"><strong>${props.model.local_host_name}</strong></div>
              </hbr-tooltip>
            </hbr-portal>`;
      };
      const div = createElement("div", { innerHTML: getHTML() });
      return [div];
    }
  },
  {
    prop: "curr_up_percent",
    name: i18nMessageBundle.dashletSiteViewAvailability,
    size: 120,
    readonly: true,
    cellProperties: () => {
      return {
        class: {
          "hbr-table-cell-readonly-background-color": true
        }
      };
    },
    cellTemplate: (createElement, props) => {
      const getHTML = () => {
        return `
                <span style="color: var(--hbr-color-neutral-border, #889099);">${props.model.curr_up_percent}%<span> 
                    <hbr-icon name="trend" style="color: var(--hbr-color-success);"></hbr-icon>
                <span style="color: var(--hbr-color-neutral-border, #889099);">${props.model.change_percentage}%<span>`;
      };
      const div = createElement("div", {
        style: {
          height: "100%",
          color: "var(--hbr-color-on-surface)",
          display: "flex",
          alignItems: "center"
        },
        innerHTML: getHTML()
      });
      return [div];
    }
  },
  {
    prop: "bandwidth",
    name: i18nMessageBundle.applicationOverviewLabelBandwidthMbps,
    readonly: true,
    cellProperties: () => {
      return {
        class: {
          "hbr-table-cell-readonly-background-color": true
        }
      };
    }
  },
  {
    prop: "usage",
    name: i18nMessageBundle.applicationOverviewLabelDataUsage,
    readonly: true,
    cellProperties: () => {
      return {
        class: {
          "hbr-table-cell-readonly-background-color": true
        }
      };
    }
  }
];

export const getSourceDevice = data => {
  return data.map(element => {
    return {
      local_host_name: element.local_host_name,
      curr_up_percent: element.curr_up_percent,
      change_percentage: element.change_percentage,
      bandwidth: formatBits(element.bandwidth),
      usage: formatSiteTableBytes(element.usage)
    };
  });
};
