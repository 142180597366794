import ApiService from "../../config/api-config";

export const ACTIONS = {
  SET_DASHLET_LOADING: "SET_DASHLET_LOADING",
  SET_FILTERVIEW_APP: "SET_FILTERVIEW_APP",
  SET_APPLICATION_DONUT: "SET_APPLICATION_DONUT",
  SET_APPLICATION_STACKED: "SET_APPLICATION_STACKED",
  SET_USER_SUMMARY: "SET_USER_SUMMARY",
  SET_DASHLET_FILTER: "SET_DASHLET_FILTER",
  SET_ERROR: "SET_ERROR"
};

export const TYPE = {
  APP: "APP",
  USER: "USER",
  SITE: "SITE",
  FILTER: "FILTER"
};

const generateFilter = filter => {
  if (filter && filter.filter && filter.filter.site_id)
    return { filter: { site_id: filter.filter.site_id } };

  return filter && filter.site_id
    ? { filter: { site_id: filter.site_id } }
    : {};
};

export const setDashletLoading = type => ({
  type: ACTIONS.SET_DASHLET_LOADING,
  payload: type
});

export const getFilterViewApps = filter => dispatch => {
  ApiService.getFilterViewApps({
    current_period: filter.current_period,
    previous_period: filter.previous_period,
    ...generateFilter(filter)
  }).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.SET_FILTERVIEW_APP,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          errorFilterViewApp: res.errorObject,
          filterViewLoaderApp: false
        }
      });
    }
  });
};

export const getTopUsers = (globalV4Payload, setIsOverviewPage) => dispatch => {
  ApiService.getTopUsers(globalV4Payload).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.SET_USER_SUMMARY,
        payload: res.data
      });
      setIsOverviewPage(false);
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: { errorUser: res.errorObject, userSummaryLoader: false }
      });
    }
  });
};

export const setDashletFilter = (type, filter) => dispatch => {
  return dispatch({
    type: ACTIONS.SET_DASHLET_FILTER,
    payload: {
      type,
      filter
    }
  });
};
