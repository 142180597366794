import Authorization from "../../generics/authorization";
import TabNavigation from "../../common/TabNavigation";
import TimeStampFilterComponent from "../../common/TimeStampFilterComponent";
import FilterView from "./FilterView";
import DashletSiteView from "./SiteView";
import DashletAppView from "./AppView";
import DashletCircuitView from "./CircuitView";
import DashletUserView from "./UserView";

const xValue = 0;
const wValue = 12;

const pageProps = {
  filterDashlets: [
    {
      component: TabNavigation,
      name: "tab-nav",
      layout: { x: xValue, y: 0, w: wValue, h: 9 },
      style: { zIndex: 2 }
    },
    {
      component: TimeStampFilterComponent,
      name: "time-filter",
      layout: { x: xValue, y: 10, w: wValue, h: 4.6 }
    },
    {
      component: FilterView,
      name: "filter-view",
      layout: { x: xValue, y: 20, w: wValue, h: 10.5 },
      style: { zIndex: 1 }
    }
  ],
  dashlets: [
    {
      component: DashletSiteView,
      name: "site-view",
      layout: { x: xValue, y: 10, w: 6, h: 28 },
      style: { zIndex: 0 }
    },
    {
      component: DashletAppView,
      name: "app-view",
      layout: { x: 7.25, y: 10, w: 6, h: 28 },
      style: { zIndex: 0 }
    },
    {
      component: DashletCircuitView,
      name: "circuit-view",
      layout: { x: xValue, y: 40, w: 6, h: 28 }
    },
    {
      component: DashletUserView,
      name: "user-view",
      layout: { x: 7.25, y: 40, w: 6, h: 28 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
