import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export default {
  title: i18nMessageBundle.appViewApplication,
  theme: "quality",
  text: {
    good: {
      title: i18nMessageBundle.appQoeDonutGood,
      tooltip: i18nMessageBundle.applicationOverviewLabelGood
    },
    fair: {
      title: i18nMessageBundle.appQoeDonutFair,
      tooltip: i18nMessageBundle.applicationOverviewLabelFair
    },
    bad: {
      title: i18nMessageBundle.appQoeDonutPoor,
      tooltip: i18nMessageBundle.applicationOverviewLabelPoor
    },
    unknown: {
      title: i18nMessageBundle.appQoeDGray,
      tooltip: i18nMessageBundle.applicationOverviewLabelGray
    }
  }
};
