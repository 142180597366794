import React, { useCallback, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import TotalUsage from "./TotalUsage";
import i18n from "amdi18n-loader!../../../nls/i18n";
import css from "../trendAnalysisMagneticStyle.less";
import StackedMultiLineChart from "../../../../common/StackedMultiLineChart";
import { colors } from "../../../../utils/colors";
import { getTrendInitialLoad } from "../../../../utils/common";

const [HbrCard] = reactWrapper(["hbr-card"]);
const seriesColors = {
  Poor: colors.red45,
  Fair: colors.amber60,
  Good: colors.green55,
};
const OverallTrendView = ({ globalFilter, actions, overallTrend }) => {
  const { appCount_loader, appCount, error } = overallTrend;
  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (getTrendInitialLoad(globalFilter, initialLoad)) {
      const appCountByQoePayload = {
        ...globalFilter.globalV4PayloadSidebar,
        sort: { "entry_ts": "asc" }
      };
      actions.getAppCountByQoe("APP_COUNT",
        { ...globalFilter.sideBarTimeFilter },
        appCountByQoePayload);
    }
    setInitialLoad(false);
  }, [
    globalFilter.sideBarTimeFilter.current_period[0],
    globalFilter.sideBarTimeFilter.current_period[1]
  ]);

  const customizeChart = useCallback(chart => {
    chart.series.values.forEach(series => {
      series.adapter.add("tooltipHTML", (html, target) => {
        const data = target.tooltipDataItem.dataContext;
        if (data && data.tooltipData) {
          const series = [...chart.series.values].reverse();
          html = `
             <div class="ttip-header">
             <span class="date">${data.tooltipData.date}</span>
             </div>
             <table class="ttip-content">
             ${series
              .map(item =>
                !item.isHidden
                  ? `
                   <tr>
                     <td>
                       <div class="flex-items">
                         <span
                           class="square-legend"
                           style="background-color:${item.fill.hex};"
                         ></span>
                         ${item.name}
                       </div>
                     </td>
                     <td>${data.tooltipData[item.dataFields.valueY]}</td>
                   </tr>
                   `
                  : ""
              )
              .join("")}
             </table>`;
        }
        return html;
      });
    });
    chart.yAxes.values[0].maxPrecision = 0;
    chart.yAxes.values[0].renderer.minGridDistance = 90;
  }, []);
  return (
    <div className={css["overall-trend-view"]} data-cy="overallTrendView">
      <HbrCard
        id="non-interactive"
        slot="label"
        className="application-trend-analysis-hbr-card"
      >
        <div data-cy="overallTrendViewAppCount" className="topChart">
          <div className="hbr-type-h2 trend-applicaiton-title-bottom">
            {i18n.overallTrendViewAppCount}
          </div>
          {appCount_loader ? (
            <div
              data-cy="spinner-overAllTrendAppCount"
              style={{ height: "200px" }}
            >
              <Spinner />
            </div>
          ) : error.appCountError ? (
            <div className="error_components">
              <ErrorComponent
                {...error.appCountError}
                width="110px"
                className="super-small-dashlet-error"
              />
            </div>
          ) : appCount && appCount.length === 0 ? (
            <div className="error_components">
              <NoDataAvailable text={i18n.applicationDashboardNoData} />
            </div>
          ) : (
            appCount && (
              <>
                <StackedMultiLineChart
                  id="apps_sidebar_appCount"
                  colorset={seriesColors}
                  callbackFromParent={customizeChart}
                  data={appCount}
                  min={globalFilter.sideBarTimeFilter.current_period[0]}
                  max={globalFilter.sideBarTimeFilter.current_period[1]}
                  sidebar
                />
              </>
            )
          )}
        </div>
      </HbrCard>
      <TotalUsage />
    </div>
  );
};

OverallTrendView.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  overallTrend: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ overallTrend: state.vanalytics.trend });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(OverallTrendView))
);
