import Authorization from "../../../../generics/authorization";
import DashletProgressBar from "../../genericWorkflowComponents/commonDashlets/DashletProgressBar";
import DashletFileType from "../../genericWorkflowComponents/DashletFileType";
import DashletFooter from "../../genericWorkflowComponents/commonDashlets/DashletFooter";
import { colors } from "../../../../utils/colors";

const pageProps = {
  workflowContainer: true,
  dashlets: [
    {
      component: DashletProgressBar,
      name: "report-progress",
      layout: { x: 6, y: 4, w: 12, h: 0.5 },
      style: {
        background: colors.gray90,
        display: "flex"
      }
    },
    {
      component: DashletFileType,
      name: "filetype-page",
      layout: { x: 0, y: 5, w: 20, h: 44 }
    },
    {
      component: DashletFooter,
      name: "footer",
      layout: { x: 0, y: 8, w: 12, h: 12 }
    }
  ]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
