import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import OverLappingSpinner from "../../../common/Spinner/OverLappingSpinner";
import ErrorComponent from "../../../common/ErrorComponent";
import SiteFilter from "./siteFilter";
import { useMount } from "../../../utils/genericCommon";
import { downloadAsCSVFile } from "../../../utils/common";
import { getTableColumns, setSitesData } from "./tableConfig";
import apiService from "../../../config/api-config";
import i18n from "amdi18n-loader!../../nls/i18n";

const [HbrTable, HbrPagination, HbrInput, HbrIcon, HbrButton] =
  reactWrapper([
    "hbr-table",
    "hbr-pagination",
    "hbr-input",
    "hbr-icon",
    "hbr-button" 
  ]);
// default sort column and sort order
const defaultSort = ["availability", "desc"]; 

const Table = ({ history, globalFilter, setSelectedSite, reporting }) => {
  const [loader, setLoader] = useState(true);
  const mount = useMount();
  const dataRef = useRef({
    timestamp: 0,
    error: null,
    noData: false,
    // sort field
    sort: [defaultSort],
    // qoe status selection
    availabilityStatus: "all",
    // search text
    search: "",
    // records count
    itemCount: 0 
  });
  const tableRef = useRef();
  const paginationRef = useRef();

  // event for dashboard table
  useEffect(() => {
    // set page number and page size after initial load of pagination component
    if (mount.initMount === true) {
      if (paginationRef.current !== null) {
        paginationRef.current.element.itemCount = 0;
        paginationRef.current.element.pageSize = reporting ? 50 : 30;
      }
    }
    // load table data
    getTableData(globalFilter, { page: 0 });
  }, [
    globalFilter.timeFilter.current_period[0],
    globalFilter.timeFilter.current_period[1],
    globalFilter.currentTimeStamp,
    globalFilter.selectedSite
  ]); 

  const getTableData = useCallback(async (gFilter, customProps) => {
    if (paginationRef.current !== null) {
      const timestamp = Date.now();
      // reset state values
      Object.assign(
        dataRef.current,
        { timestamp, error: null, noData: false }
      );
      // get payload
      const pagination = paginationRef.current.element;
      let pageNumber = null;
      if (customProps !== undefined) {
        if (Number.isInteger(customProps.page)) pageNumber = customProps.page;
      }
      const page = Number.isInteger(pageNumber) ? pageNumber : pagination.page;      
      const v4Payload = {
          entry_ts: gFilter.globalV4Payload.entry_ts,
          time_frame: gFilter.globalV4Payload.time_frame
        }
      
      const payload = {
        ...v4Payload,
        size: pagination.pageSize,
        offset: page * pagination.pageSize,
        sort: Object.fromEntries(dataRef.current.sort)
      };
      if (dataRef.current.availabilityStatus !== "all") {
        payload.availability_status = dataRef.current.availabilityStatus === "unknown"
          ? ["unknown"]
          : [dataRef.current.availabilityStatus];
      }
      if (dataRef.current.search !== "")
        payload.search = dataRef.current.search;
      setLoader(true); 
      const res = await apiService.getSitesTableList(payload);
      if (
        mount.mounted === true && timestamp === dataRef.current.timestamp
        && tableRef.current !== null && paginationRef.current !== null
      ) { 
        if (
          res.errorObject instanceof Object === false
          && res.data instanceof Object && Array.isArray(res.data.data)
          && res.data.data.length > 0
        ) {
          const resData = res.data.data;
          // set sites data 
          setSitesData(resData);           
          // set table data
          tableRef.current.element.source = resData;
          pagination.itemCount = res.data.count;
          if (Number.isInteger(pageNumber)) pagination.page = pageNumber;
        }
        else {
          if (res.errorObject instanceof Object)
            dataRef.current.error = res.errorObject;
          dataRef.current.noData = true;
          // set table data
          tableRef.current.element.source = [];
          pagination.itemCount = 0;
        }
        dataRef.current.itemCount = pagination.itemCount;        
        setLoader(false);
      }
    }
  }, []);

  const columns = useMemo(() => {
    const tableColumns = getTableColumns(history, setSelectedSite); 
    return tableColumns;
  }, [globalFilter.selectedSite]);

  // load table data on qoe status selection
  const onavailabilityStatusChange = event => {
    dataRef.current.availabilityStatus = event.currentTarget.value;
    getTableData(globalFilter, { page: 0 });
  };

  // load table data on search
  const onSearch = ({ target: { value } }) => {
    if (value !== dataRef.current.search) {
      dataRef.current.search = value;
      getTableData(globalFilter, { page: 0 });
    }
  }; 

  const getExportData = useCallback(async v4Payload => {
    // get payload
    const exportPayload = {
      entry_ts: v4Payload.entry_ts,
      time_frame: v4Payload.time_frame
    }
    const payload = {
      ...exportPayload,
      sort: Object.fromEntries(dataRef.current.sort)
    };
    if (dataRef.current.availabilityStatus !== "all") {
      payload.availability_status = dataRef.current.availabilityStatus === "unknown"
        ? ["unknown"]
        : [dataRef.current.availabilityStatus];
    }
    if (dataRef.current.search !== "") payload.search = dataRef.current.search;
    const res = await apiService.getSitesTableList(payload);
    if (
      res.errorObject instanceof Object === false
      && res.data instanceof Object
      && Array.isArray(res.data.data)
    ) {
      const resData = res.data.data;
      // get colum header row
      const columnTitles = columns.map(item => `"${item.name}"`);
      // exclude checkbox column header 
      const data = [columnTitles.join(",")];
      setSitesData(resData);
      // add records
      for (const item of resData) {
        data.push([
          `"${item.site_name}"`,
          `"${item.availability}"`,
          `"${item.city}"`,
          `"${item.usage}"`,
          `"${item.loss}"`,
          `"${item.latency}"`,
          `"${item.jitter}"`
        ].join(","));
      }
      // download the csv file
      downloadAsCSVFile(data.join("\n"), i18n.allSiteSitesLabel);
    }
  }, []); 
  return (
    <div
      className="table-container hbr-css__layout-col-md"
      data-cy="applicationTableView"
    >
      {loader === true && <OverLappingSpinner />}
      <div className="hbr-css__layout-col-md">
        <div className="hbr-css__text-heading-lg-bold">
          {i18n.allSiteSitesLabel}
        </div>
        {!reporting && (
        <> 
        <SiteFilter
          value={dataRef.current.availabilityStatus}
          onChange={onavailabilityStatusChange}
          loader={loader}
        />
        <div
          className="hbr-css__layout-row-xs hbr-css__layout-justify-between"
        >
          <div className="hbr-css__layout-row-xs">
            <HbrInput
              placeholder="Search"
              clearable
              onHbr-clear={onSearch}
              onkeydown={event => {
                if (event.code === "Enter") onSearch(event);
              }}
              onHbr-blur={onSearch}
              disabled={loader}
            >
              <HbrIcon
                slot="prefix"
                name="magnifying-glass"
                sentiment="neutral"
              />
            </HbrInput>
            <div className="hbr-css__filter-description">
              {`${dataRef.current.itemCount} ${i18n.sites}`}
            </div>
          </div>
          <HbrButton
            variant="outline"
            onClick={() => getExportData(globalFilter.globalV4Payload)}
            disabled={loader}
          >
            <HbrIcon slot="prefix" sentiment="interact" name="upload-simple" />
            {i18n.export}
          </HbrButton>
        </div>
        </>
        )}
      </div>
      <HbrTable
        ref={tableRef} 
        columns={columns}
        className={reporting ? 'reportSitesTable' : 'allSitesTable'}
        readonly
        resize
        serverSide
        canFocus={false}
        onBeforesortingapply={event => {
          if (tableRef.current !== null) {
            event.preventDefault();
            const { column, order } = event.detail;
            if (order) dataRef.current.sort[0] = [column.prop, order];
            else {
              const sortOrder =
                column.prop === defaultSort[0] ? "asc" : defaultSort[1];
              dataRef.current.sort[0] = [defaultSort[0], sortOrder];
              const columnIndex =
                columns.findIndex(item => item.prop === defaultSort[0]);
              tableRef.current.element.updateColumnSorting(
                columns[columnIndex],
                columnIndex,
                sortOrder
              );
            }
            getTableData(globalFilter, { page: 0 });
          }
        }}
        onBeforesourcesortingapply={event => {
          event.preventDefault();
        }}
      >
        {dataRef.current.noData === true && (
          <div slot="empty" className="hbr-css__table-empty-state">
            {dataRef.current.error === null ? (
              <>
                <HbrIcon size="75px" sentiment="neutral" name="empty-state" />
                <span className="hbr-css__text-secondary-sm">
                  {i18n.c360.tEmpty}
                </span>
              </>
            ) : (
              <ErrorComponent
                {...dataRef.current.error}
                className="small-dashlet-error"
                width="110px"
              />
            )}
          </div>
        )}
        <HbrPagination
          ref={paginationRef}
          className={reporting ? 'reportPagination' : ''}
          slot="footer"
          orderArray={[1, 2, 0]}
          showAll
          onHbr-page-changed={event => {
            if (Number.isInteger(event.detail)) getTableData(globalFilter);
            else getTableData(globalFilter, { page: 0 });
          }}
          onHbr-size-changed={() => getTableData(globalFilter)}
        />
      </HbrTable>       
    </div>
  );
};
 

Table.propTypes = {
  history: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,  
  setSelectedSite:  PropTypes.func,
  reporting: PropTypes.bool 
}; 

export default AppHOC(Table);