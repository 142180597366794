import Authorization from "../../../generics/authorization";
import ReportTabList from "./ReportTabList";

const pageProps = {
  dashlets: [{
    component: ReportTabList,
    name: "tab-view-reports",
    layout: { x: 0, y: 0, w: 12, h: 45 },
    style: { margin: "-55px 10px 10px 10px" }
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
