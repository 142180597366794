import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";

const reduxContext = createContext();
const storeKey = "vanalytics.reports";

export default reduxContext;

const initialState = {
  reports: [],
  reportTemplates: null,
  reportTemplateLoading: true,
  reports_loading: false
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_ALL_REPORTS:
      return {
        ...state,
        reports: payload,
        reportTemplateLoading: false
      };

    case ACTIONS.SET_ALL_REPORT_TEMPLATES:
      return {
        ...state,
        reportTemplates: payload,
        reportTemplateLoading: false
      };
    case ACTIONS.SET_REPORTS_LOADING:
      return {
        ...state,
        reports_loading: payload,
        reportTemplateLoading: false
      };
    case ACTIONS.SET_REPORTS_TEMPLATES_LOADING:
      return {
        ...state,
        reports_loading: false,
        reportTemplateLoading: payload
      };
    case ACTIONS.SET_DELETE_REPORT: {
      return {
        ...state,
        reports: state.reports.filter(
          report => report.report_uuid !== payload.uuid
        ),
        reportTemplateLoading: false
      };
    }

    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_ALL_REPORTS,
    ACTIONS.SET_REPORTS_LOADING,
    ACTIONS.SET_DELETE_REPORT,
    ACTIONS.SET_ALL_REPORT_TEMPLATES,
    ACTIONS.SET_REPORTS_TEMPLATES_LOADING
  ]
});
