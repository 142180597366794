import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";

const reduxContext = createContext();

export default reduxContext;

const storeKey = "vanalytics.applicationSummary";

const initialState = {
  reportData: null,
  reportLoader: true
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_REPORT_LOADING:
      return {
        ...state,
        reportLoader: true
      };
    case ACTIONS.SET_REPORT_DATA:
      return {
        ...state,
        reportData: payload,
        reportLoader: false
      };
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_REPORT_LOADING,
    ACTIONS.SET_REPORT_DATA,
    ACTIONS.SET_ERROR
  ]
});
