import React, { Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";

import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import reduxContext from "./reducer";
import * as actions from "./actions";
import css from "./trendAnalysisMagneticStyle.less";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";

const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);
import FilterView from "./FilterView";
import OverallTrendView from "./OverallTrendView";
import ApplicationsTable from "../../applications/ApplicationsTableView/AppTable";
import SelectedAppView from "./SelectedAppView";

const [HbrCard, HbrRadioGroup, HbrRadio] = reactWrapper([
  "hbr-card",
  "hbr-radio-group",
  "hbr-radio"
]);

const mapStateToProps = state => ({
  trend: state.vanalytics.trend
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

class TrendAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: this.props.globalFilter.selected_apps,
      radioGroup: [
        {
          value: "bandwidth",
          text: i18nMessageBundle.trendAnalysisTop,
          checked: true
        },
        {
          value: "custom",
          text: i18nMessageBundle.trendAnalysisCustomList,
          checked: false
        }
      ]
    };
    this.radioValue = "bandwidth";
  }

  componentWillUnmount() {
    this.props.setSelectedAppList(
      this.props.globalFilter.selected_apps_default
    );
  }

  onRadioChange = e => {
    const newValue = this.state.radioGroup.map(radio => {
      if (radio.value === e.target.value) {
        radio.checked = true;
      } else {
        radio.checked = false;
      }
      return radio;
    });
    const selectedName = e.target.value;
    if (selectedName === "bandwidth") {
      this.props.setSelectedAppList(
        this.props.globalFilter.selected_apps_default
      );
    }
    this.radioValue = selectedName;
    this.setState({ radioGroup: newValue });
  };

  onCustomListChange(selections) {
    this.setState({ selectedList: selections });
  }

  render() {
    const { selectionError } = this.props.trend.error;
    return (
      <div className={css["trend-analysis"]}>
        <div className="trend-ctr-content">
          <div className="trend-ctr">
            <FilterView />
            <OverallTrendView />
          </div>
          <div>
            <HbrCard
              id="non-interactive"
              slot="label"
              className="application-trend-analysis-hbr-card"
            >
              <div className="trend-ctr">
                <div className="hbr-type-h2">
                  {i18nMessageBundle.trendAnalysisApplication}
                </div>

                <HbrRadioGroup onHbr-click={this.onRadioChange}>
                  {this.state.radioGroup.map((radio, i) => {
                    const { text, ...props } = radio;
                    return (
                      <HbrRadio key={i} {...props}>
                        {text}
                      </HbrRadio>
                    );
                  })}
                </HbrRadioGroup>
              </div>
              {selectionError ? (
                <div className="error_components">
                  <Suspense fallback={<Spinner />}>
                    <ErrorComponent
                      {...selectionError}
                      width={"200px"}
                      className={"super-small-dashlet-error"}
                    />
                  </Suspense>
                </div>
              ) : (
                <div className="trend-ctr">
                  {this.radioValue === "custom" ? (
                    <div className={css["custom-list-view"]}>
                      <ApplicationsTable rowSelect />
                    </div>
                  ) : null}
                  <SelectedAppView
                    selectedList={this.state.selectedList}
                    isCustom={this.radioValue === "custom"}
                  />
                </div>
              )}
            </HbrCard>
          </div>
        </div>
      </div>
    );
  }
}

TrendAnalysis.propTypes = {
  actions: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  trend: PropTypes.object.isRequired,
  setSelectedAppList: PropTypes.func.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(TrendAnalysis))
);
