import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export default {
  title: i18nMessageBundle.circuitTableAvailability,
  subTitle: i18nMessageBundle.circuitViewCircuits,
  legend: {
    theme: "overlay",
    labels: [
      i18nMessageBundle.applicationCircuitViewLabelAvailable,
      i18nMessageBundle.applicationCircuitViewLabelPrevious,
      i18nMessageBundle.applicationOverviewTotal
    ]
  },
  columns: [
    {
      title: i18nMessageBundle.circuitViewCircuits,
      colSpan: 1,
      property: "circuit_display_name",
      type: "string",
      format: "link",
      maxLength: 22,
    },
    {
      title: `% ${i18nMessageBundle.circuitViewAvailability}`,
      colSpan: 1,
      property: "availability",
      type: "number",
      format: "percent",
      theme: "changeQuality",
      tooltip: true,
      tooltipTitle: i18nMessageBundle.circuitAvailBarChart,
      tooltipDefinition: i18nMessageBundle.circuitAvailBarChartDefinition,
      padding: "5px"
    },
    {
      title: i18nMessageBundle.circuitViewTotalTime,
      colSpan: 1,
      property: "chart",
      type: "chart",
      format: "overlayBarChart",
      padding: "0px 0px 0px 4px"
    }
  ]
};
