import ApiService from "../../../../config/api-config";
import { isSortingAscending } from "../../../../utils/common";
import { usageFilter } from "../../../../utils/enums";

export const ACTIONS = {
  SET_REPORT_LOADING: "SET_REPORT_LOADING",
  SET_REPORT_DATA: "SET_REPORT_DATA",
  SET_OVERALL_SUMMARY_LOADING: "SET_OVERALL_SUMMARY_LOADING",
  SET_OVERALL_SUMMARY: "SET_OVERALL_SUMMARY",
  SET_APPS_STACKED_ASC: "SET_APPS_STACKED_ASC",
  SET_APPS_STACKED_DESC: "SET_APPS_STACKED_DESC",
  SET_APPS_DONUT: "SET_APPS_DONUT",
  SET_TOP_USERS: "SET_TOP_USERS",
  SET_ERROR: "SET_ERROR"
};

export const setReportLoading = () => ({
  type: ACTIONS.SET_REPORT_LOADING
});

export const getReportData = reportId => dispatch => {
  ApiService.getReportByUUID(reportId).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.SET_REPORT_DATA,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          reportData: null,
          reportLoader: false,
          errorMessage: res.errorObject
        }
      });
    }
  });
};

export const getSummary = (filter, overlayId) => dispatch => {
  ApiService.getSummaryData(filter, overlayId).then(res => {
    if (res && !res.data.errMsg) {
      dispatch({
        type: ACTIONS.SET_OVERALL_SUMMARY,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: { overallSummary: null, overallSummaryLoader: false }
      });
    }
  });
};

export const getApplicationsDonut = (filter, overlayId) => dispatch => {
  ApiService.getTopApplicationsDonut(
    {
      current_period: filter.current_period,
      previous_period: filter.previous_period,
      table_filter: { vqoe_status: ["good", "fair", "poor"] },
      sort: {
        vqoe_score: filter.sortingType
      },
      size: 5,
      offset: 0
    },
    overlayId
  ).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.SET_APPS_DONUT,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          appsDonutLoader: false,
          appsDonut: null
        }
      });
    }
  });
};

export const getApplicationStacked = (filter, overlayId) => dispatch => {
  ApiService.getTopApplicationsStacked(
    {
      current_period: filter.current_period,
      previous_period: filter.previous_period,
      table_filter: { vqoe_status: ["good", "fair", "poor"] },
      sort: {
        vqoe_score: filter.sortingType
      },
      size: 5,
      offset: 0
    },
    overlayId
  ).then(res => {
    if (!res.errorObject) {
      if (isSortingAscending(filter.sortingType)) {
        dispatch({
          type: ACTIONS.SET_APPS_STACKED_ASC,
          payload: res.data
        });
      } else {
        dispatch({
          type: ACTIONS.SET_APPS_STACKED_DESC,
          payload: res.data
        });
      }
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          appsAscLoader: false,
          appsAsc: null,
          appsDescLoader: false,
          appsDesc: null
        }
      });
    }
  });
};

export const getUsers = (filter, overlayId) => dispatch => {
  ApiService.getTopUsers(
    {
      current_period: filter.current_period,
      previous_period: filter.previous_period,
      ...usageFilter,
      size: 5
    },
    overlayId
  ).then(res => {
    if (!res.errorObject) {
      dispatch({
        type: ACTIONS.SET_TOP_USERS,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          topUsersLoader: false,
          topUsersError: true
        }
      });
    }
  });
};
