import React from "react";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import { DtableWrapper } from "dnx-web-components/dist/index-with-deps-react.min";
import table from "dnx-web-components/dist/table.min";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//components
import AppHoc from "../../../../../generics/AppHOC";

//reducers
import reduxContext from "../reducer";
import * as actions from "../actions";

//style
import css from "../../../reportsMagneticStyle.less";

//utils
import { getColumns } from "../../../../app360/SiteList/tableConfig";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    app360: state.vanalytics.app360_report
  };
};

const [HbrCard] = reactWrapper(["hbr-card"]);

const SiteList = props => {
  const siteData = props.sitesData;
  const DnxTable = DtableWrapper(table);

  const getTableProps = props => {
    const tableConfig = {
      data: siteData,
      columns: getColumns(props),
      idData: "site_id",
      dtOptions: {
        paging: false,
        stateSave: true,
        stateDuration: 0
      }
    };
    return tableConfig;
  };

  let tableProps = getTableProps();

  return (
    <div className={css["app360-report-sites-list-table"]} data-cy="app360-report-sites-list-table">
      <HbrCard>
        <div className="site-list-table" data-cy="site-list-table-view">
          <div
            className="dnx--css_table-container"
            style={{
              maxWidth: "100vw"
            }}
          >
            <DnxTable {...tableProps} />
          </div>
        </div>
      </HbrCard>
    </div>
  );
};

SiteList.propTypes = {
  app360: PropTypes.object.isRequired,
  actions: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  sitesData: PropTypes.array
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(SiteList))
);