import ApiService from "../../../config/api-config";
export const ACTIONS = {
  SET_ALL_REPORTS: "SET_ALL_REPORTS",
  SET_ALL_REPORT_TEMPLATES: "SET_ALL_REPORT_TEMPLATES",
  SET_REPORTS_LOADING: "SET_REPORTS_LOADING",
  SET_DELETE_REPORT: "SET_DELETE_REPORT",
  SET_REPORTS_TEMPLATES_LOADING: "SET_REPORTS_TEMPLATES_LOADING"
};

export const getReportsList = overlayId => dispatch => {
  startLoader(dispatch, false);
  ApiService.getAllReports(overlayId)
    .then(res => {
      dispatch({
        type: ACTIONS.SET_ALL_REPORTS,
        payload: res.data
      });
      startLoader(dispatch, true);
    })
    .catch(err => {
      console.log(err);
    });
};
export const deleteReport = report_uuid => dispatch => {
  ApiService.deleteReport(report_uuid)
    .then(() => {
      dispatch({
        type: ACTIONS.SET_DELETE_REPORT,
        payload: { uuid: report_uuid }
      });
    })
    .catch(err => {
      console.log(err);
    });
};
export const getReportTemplates = () => dispatch => {
  startLoader(dispatch, true, true);
  ApiService.getAllReportTemplates()
    .then(res => {
      dispatch({
        type: ACTIONS.SET_ALL_REPORT_TEMPLATES,
        payload: res.data
      });
      startLoader(dispatch, false, true);
    })
    .catch(err => {
      console.log(err);
    });
};

const startLoader = (dispatch, load, template = false) => {
  dispatch({
    type: template
      ? ACTIONS.SET_REPORTS_TEMPLATES_LOADING
      : ACTIONS.SET_REPORTS_LOADING,
    payload: load
  });
};
