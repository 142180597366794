import React from "react";
import { PropTypes } from "prop-types";
import { connect, useDispatch } from "react-redux";

import css from "../../reportsMagneticStyle.less";
import { DnxSwitchGroup, DnxSwitch } from "../../../../loaders/DNXLoader";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import reduxContext from "../../../../generics/AppHOCReport/reducer";
import { setWorkflow } from "../../../../generics/AppHOCReport/actions";
import AppHocReport from "../../../../generics/AppHOCReport";
import {
  getWorkflowState,
  isExecutiveTemplate,
  isWidgetGradeOut
} from "../../../../utils/common";

const DashletFileType = () => {
  const workflowState = getWorkflowState();
  const isDisabledWidget = isWidgetGradeOut(location);
  const { pageName } = workflowState;
  const dispatch = useDispatch();
  const changeFileType = e => {
    const updatedWorkflow = {
      ...getWorkflowState(),
      ...{ fileType: e.currentTarget.value }
    };
    saveState(updatedWorkflow);
  };

  const saveState = updatedWorkflow => {
    dispatch(setWorkflow(updatedWorkflow));
  };

  return (
    <div className={css["workflow"]}>
      <React.Fragment>
        {
          <div className="left-side-container" data-cy="pageOneTwo">
            <div className="header">
              {i18nMessageBundle.workflowSelectFileType}
            </div>
            <div className="subHeadline">
              {i18nMessageBundle.workflowSelectFileHint}
            </div>
            <div>
              <DnxSwitchGroup
                name="file-type"
                direction="column"
                change={changeFileType}
                value={workflowState.fileType}
                disabled={isDisabledWidget}
                data-cy="fileTypeGroup"
              >
                <DnxSwitch
                  flavor="radio"
                  name="pdf"
                  data-cy="pdfRadioButton"
                  label={i18nMessageBundle.workflowSelectFilePdf}
                />
                <DnxSwitch
                  flavor="radio"
                  name="csv"
                  data-cy="csvRadioButton"
                  disabled={isExecutiveTemplate(pageName)}
                  label={i18nMessageBundle.workflowSelectFileCsv}
                />
              </DnxSwitchGroup>
            </div>
          </div>
        }
      </React.Fragment>
    </div>
  );
};

DashletFileType.propTypes = {
  location: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect()(AppHocReport(DashletFileType))
);
