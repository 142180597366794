import React, { useEffect, useState, useRef, Suspense } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import Spinner from "../../../../../common/Spinner";
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n"; 
import "../../../securityMagneticStyle.less";  
import ApiService from "../../../../../config/api-config";  
import { 
  timeFilterTypes
} from "../../../../../utils/enums"; 
import { 
  DisplayDateMonth,
  getPreviousMonthDate, 
  getPreviousYearMonthDate,
  shortDisplayDate24HourWithSec,
  shortDisplayDate24HourWithSecUTC,
  getStartfDayUTCFormat,
  shortDisplayDateForSdwan,
  getPreviousYearMonthDateTime  
} from "../../../../../utils/displayTime";  
const DefaultSiteTable = React.lazy(() => import("../DefaultSiteTable"));
import ThreatStackedBarChart from "../ThreatStackedBarChart";
const NoDataAvailable = React.lazy(() => import("../../../../../common/NoDataAvailable"));
 
const ErrorComponent = React.lazy(() => import("../../../../../common/ErrorComponent"));
const [HbrIcon] = reactWrapper(["hbr-icon"]);
const SdwanSidebar = props => { 
  const { globalFilter } = props; 

  const [showThreatTable, setShowThreatTable] = useState(true);
  const [showUserTable, setShowUserTable] = useState(false); 
  const [showClusterTableView, setShowClusterTableView] = useState(false); 
  const [sdwanUserTableIP1, setSdwanUserTableIP1] = useState();
  const [sdwanUserTableIP2, setSdwanUserTableIP2] = useState();  
  
  const [siteThreatOverview, setSdwanSiteThreatOverview] = useState();    
  const [siteThreatTrend, setSdwanSiteThreatTrend] = useState();   
  const [siteThreatOverviewError, setSdwanSiteThreatOverviewError] = useState();    
  const [siteThreatTrendError, setSdwanSiteThreatTrendError] = useState(); 
  const [siteThreatTrendDetail, setSiteThreatTrendDetail] = useState();  
  const [siteThreatTrendDetailError, setSiteThreatTrendDetailError] = useState();  
  const [selectedSiteId, setSelectedSiteId] = useState();
  const [showLoader, setShowLoader] = useState(true); 
  const [detailLastElement, setDetailLastElement] = useState(true); 
  const [detailLoader, setDetailLoader] = useState(true);  
  const [threadDetailTimeEntry, setThreadDetailTimeEntry] = useState(); 
  const [threadDetailIP, setThreadDetailIP] = useState();    
  const [selectedHour, setSelectedHour] = useState(null);     
  const [sdwanSiteId, setSiteId] = useState();  
  const [ipAddressBreadCrumb, setIPAddressBreadCrumb] = useState();  
  const [siteName, setSiteName] = useState();  
  
  const clusterSiteId = useRef(null); 
  const dataRef = useRef({timestamp: 0});
  /*** sidebar table api call - sdwan threat overview- FIRST API CALL *****/
  useEffect(() => {   
    if((selectedSiteId !== props.siteId) || 
      (props.clusterTable && sdwanSiteId !== undefined))
    {  
      setShowLoader(true); 
      const timeFilterType = timeFilterTypes[props.globalFilter.timePeriodSelected];    
      const payload = {
        entry_ts : { 
          start: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(globalFilter.timeFilter.current_period[0])),
          end: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(globalFilter.timeFilter.current_period[1]))      
        },
        fields : [ 
          "threat_start_ip",
          "threat_end_ip",
          "threat_type",
          "threat_country",
          "threat_access_count",
          "last_access_ts"
          ],
        site_id : props.clusterTable ? sdwanSiteId : props?.siteId, 
        time_frame: timeFilterType, 
        sort: {
          last_access_ts: 'desc',
        }, 
        size: 10000 
      } 
      ApiService.getSdwanThreatOverview(payload).then(
        res => {
          if (res && !res.errorObject) { 
            setSdwanSiteThreatOverview(res?.data?.data);
          } else {
            setSdwanSiteThreatOverviewError(res.errorObject); 
          }
          setShowLoader(false);
        }
      );  
      setSelectedSiteId(props.siteId);
    }
  },[
    props.siteId,
    sdwanSiteId
  ])  
    
  /***** when cluster click on mapview *******/
  useEffect(() => {
    if(props.clusterTable)
    {  
      let clustersSiteId = props?.clustersSiteId;
      let sdwanSiteData = props.globalFilter?.sdwanSiteSummary?.data;  
      let siteTableData = props.globalFilter?.siteAPIData?.sitesTableData?.data;   
      /**** Add location to sdwan data *******/
      if(sdwanSiteData && sdwanSiteData?.length > 0) 
      {
        let matchingSiteIds = clustersSiteId.map(siteId => { 
        const secondItem = sdwanSiteData && sdwanSiteData.find(secondItem => secondItem.site_id === siteId);
        if(secondItem) {
          return {
            ...secondItem, 
          };
        }  

        }); 
        if(matchingSiteIds?.length > 0) { 
          let result = matchingSiteIds.map(item => {
            const secondItem = siteTableData && siteTableData.find(secondItem => secondItem.site_id === item.site_id);
              if(secondItem) {
               return {
                ...item,
                location: secondItem.city
              };
             } else {
              return {
                ...item,
                location: ''
              };
            }
          }); 
          setShowLoader(false);  
          setShowClusterTableView(true);
          setShowThreatTable(false); 
          setShowUserTable(false); 
          clusterSiteId.current = result; 
        }  
      }
    }  
  },[
    globalFilter.sdwanSiteSummary,
    globalFilter.siteAPIData,
    props.clusterTable,
    props.clustersSiteId]);  

  /*****show and hide tables and set drawer heading*******/

  /****Breadcrumb click on selected site text  and render threat overview table(second)*/
   const getTableThreatOverview = () => { 
    setDetailLastElement(true); // to reset last elemet of the chart 
    setThreadDetailTimeEntry(); 
    setThreadDetailIP();
    setSdwanSiteThreatTrendError();
    setShowThreatTable(true);
    setShowClusterTableView(false);
    setShowUserTable(false);
    props.drawerHeading(i18nMessageBundle.securityTabView.selectedSite); 
  } 


  /****Breadcrumb click on selected cluster text   and render site id table(first)*/
  const getTableSiteView = () => { 
    setDetailLastElement(true); // to reset last elemet of the chart 
    setThreadDetailTimeEntry(); 
    setThreadDetailIP();
    setSdwanSiteThreatTrendError();
    setShowThreatTable(false);
    setShowClusterTableView(true);
    setShowUserTable(false);
    props.drawerHeading(i18nMessageBundle.securityTabView.selectedCluster); 
  } 

  /*** sidebar table api call - sdwan threat trend- SECOND API CALL 
   *** input payload- site_id , threat_ip_start  and threat_ip_end   * 
   ******/

  const getSdwanThreatTrendCallBack = (ipaddress) => {    
      const ipAddressList = ipaddress.split(" - ");  
      setSdwanUserTableIP1(ipAddressList[0]);
      ipAddressList.length > 1 ? setSdwanUserTableIP2(ipAddressList[1]) : setSdwanUserTableIP2(ipAddressList[0]);
      setShowLoader(true);       
        const payload = {
          entry_ts : { 
            start: getPreviousYearMonthDateTime(globalFilter.timeFilter.current_period[1]),
            end: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(globalFilter.timeFilter.current_period[1]))      
          },
          fields : [  
            "client_ip_access_count",
            "entry_ts" 
            ],    
          site_id : props.clusterTable ? sdwanSiteId : props?.siteId,
          threat_start_ip: ipAddressList[0],
          threat_end_ip : ipAddressList.length > 1 ? ipAddressList[1] : ipAddressList[0],
          sort: {
            entry_ts: 'desc',
          }, 
          size: 10000 
        }
        ApiService.getSdwanThreatTrend(payload).then(
          res => {
            if (res && !res.errorObject) {  
              setSdwanSiteThreatTrend(res?.data?.data);
            } else {
              setSdwanSiteThreatTrendError(res.errorObject); 
            }            
            setShowLoader(false);
          }
        ); 
      setSdwanSiteThreatOverviewError();           
      setShowThreatTable(false);
      setShowClusterTableView(false);
      setShowUserTable(true);  
      const drawerIPaddress =  ipAddressList.length > 1 ? ipAddressList[0] + ' - ' + ipAddressList[1] : ipAddressList[0]; 
      setIPAddressBreadCrumb(drawerIPaddress);
      const heading = i18nMessageBundle.securityTabView.sideBarUntrusted + drawerIPaddress; 
      props.drawerHeading(heading);  
  };
   

  /***   table - sdwan threat overview - data set *****/
  siteThreatOverview && siteThreatOverview.map(threat => {
    threat.ipaddress = threat.threat_start_ip === threat.threat_end_ip ? threat.threat_end_ip : threat.threat_start_ip + ' - ' + threat.threat_end_ip; 
  });  

  /*** sidebar table api call - sdwan threat Detail - THIRD API CALL  
   *** input payload- site_id , threat_ip_start ,  threat_ip_end  and entry_ts  * 
   ******/ 
 const getApiSdwanSiteThreatDetail =(inputEntryTs)=> {  
  const timestamp = Date.now();
  Object.assign(dataRef.current, {
    timestamp
  });
  if(threadDetailTimeEntry !== inputEntryTs || threadDetailIP !== sdwanUserTableIP1 ) {   
  setDetailLoader(true);
  setThreadDetailTimeEntry(inputEntryTs); 
  setThreadDetailIP(sdwanUserTableIP1);
  const payload = {
    entry_ts : inputEntryTs,
    fields : [   
      "client_ip",
      "threat_access_count",
      "last_access_ts" 
    ],    
    site_id : props.clusterTable ? sdwanSiteId : props?.siteId,
    threat_start_ip : sdwanUserTableIP1,
    threat_end_ip : sdwanUserTableIP2, 
    sort: {
      last_access_ts: 'desc',
    }, 
    size: 10000
  }
  ApiService.getSdwanThreatDetail(payload).then(
    res => {
      if(timestamp === dataRef.current.timestamp) {
      if (res && !res.errorObject) {  
          setSiteThreatTrendDetail(res?.data?.data);
      } else {
          setSiteThreatTrendDetailError(res.errorObject); 
      }
      setDetailLoader(false); 
    }
    }
  );  
  }
 }

 
/**** On Default load ,set last elment to click and get details info from 3rd api ********/
const getLastElementDetail = (event) => {   
  setTimeout(() => {  
    if(detailLastElement) {
      getApiSdwanSiteThreatDetail(event?.entry_ts);
      setDetailLastElement(false);
    } 
  }, 300); 
 } 

/******check the missing date and push into sitethread array and sort */
const siteThreadDetailMissingDateUpdate =(siteThreatTrend)=> {   
  const startDate = getPreviousYearMonthDate(30);
  const endDate = getPreviousYearMonthDate();  
  const startDateData = {
    entry_ts :startDate,
    client_ip_access_count:0
  };
  const endDateData = {
    entry_ts :endDate,
    client_ip_access_count:0
  };
  const startItem = siteThreatTrend && siteThreatTrend.find(secondItem => secondItem.entry_ts === startDateData.entry_ts)
 
  if(startItem === undefined) {
    siteThreatTrend.push(startDateData);
  }
  const lastItem = siteThreatTrend && siteThreatTrend.find(secondItem => secondItem.entry_ts === endDateData.entry_ts)
   
  if(lastItem === undefined) {
    siteThreatTrend.push(endDateData);
  }
    
  var result = siteThreatTrend.sort(function(a,b){
    return Date.parse(a.entry_ts) - Date.parse(b.entry_ts);
 }).reduce(function(hash){
   return function(p,c){
     var missingMonthsNo= (Date.parse(c.entry_ts) - hash.prev) / (1000 * 3600 * 24);
     if(hash.prev && missingMonthsNo> 1) {
       for(var i=1;i<missingMonthsNo;i++)
       p.push(shortDisplayDate24HourWithSecUTC(new Date(hash.prev+i*(1000 * 3600 * 24))));
     }
     hash.prev = Date.parse(c.entry_ts);
     return p;
   };
 }(Object.create(null)),[]); 

 result && result.map(data => { 
  siteThreatTrend.push({entry_ts :data,client_ip_access_count:0});
 });   

 /**********change time stamp to date month********************/ 
 siteThreatTrend.map(threatTrend => { 
  threatTrend.date = DisplayDateMonth(threatTrend.entry_ts); 
});  

 /**********sort date *************/
 siteThreatTrend.sort((a, b) => new Date(a.entry_ts) - new Date(b.entry_ts));  
}
 
const reverse = arr => {
  let cpy = [...arr];
  cpy.reverse();
  return cpy;
};

// check last element of the site threat trend array unique count value not equal to 0
const checkNullSiteThreatTrend = arr => {
  let res = arr.length - 1 - reverse(arr).findIndex(e => e.client_ip_access_count !== 0)

  return res === arr.length ? -1 : res;
};

 /**** siteThreatTrend data update useeffect hook 
  **** siteThreadDetailMissingDateUpdate function to add missing date in data  
  **** checkNullSiteThreatTrend function to get last not empty index 
  **** getLastElementDetail function to trigger treat detail api call
  *********/
 useEffect(() => {   
  if(siteThreatTrend && siteThreatTrend.length > 0) { 
      siteThreadDetailMissingDateUpdate(siteThreatTrend);  
      let result = checkNullSiteThreatTrend(siteThreatTrend);  
      getLastElementDetail(siteThreatTrend[result]); 
      let hour = siteThreatTrend[result]?.entry_ts;
      setSelectedHour(hour);  
    }  
 },[siteThreatTrend]); 

 const setSiteDetails = (site_id,site_name) => { 
  setSiteId(site_id); 
  setSiteName(site_name); 
  setShowThreatTable(true);
  setShowClusterTableView(false);
  setShowUserTable(false); 
  props.drawerHeading(i18nMessageBundle.securityTabView.selectedSite); 
};
 
 
 if(showLoader) {
    return (
      <div> 
          <Spinner /> 
      </div>
    );
  }
  else if (showClusterTableView && clusterSiteId.current?.length > 0 ) { 
    return (
      <div className="sidebar-sites-graph"  >
        
        {clusterSiteId.current.length > 0 && (
          <>  
            <div data-cy="pdeTableView">
              <div
                className="dnx--css_table-container commonTableStyle securityTable"
                style={{
                  maxWidth: "100vw"
                }}
              >
                <Suspense fallback={<Spinner />}>
                  <DefaultSiteTable 
                    {...props} 
                    siteSummaryData={clusterSiteId.current} 
                    tableType={'siteTable'} 
                    setSiteDetails={setSiteDetails}  
                  />
                </Suspense>
              </div>
          </div> 
        </> 
        )}
      </div>
    );
  } 
  else if (showThreatTable && siteThreatOverview?.length > 0 ) {
    return (
      <div className="sidebar-sites-graph">
         <div className="sidebar-thread-user-sites">
          {props.clusterTable && (
            <>
            <div className="flex-items back-arrow hbr-type-body4" onClick={getTableSiteView}> {i18nMessageBundle.securityTabView.selectedCluster}</div> 
            <HbrIcon className="sidebar-thread-icon" name="caret-right"></HbrIcon>
            </>
          )} 
            <div className="hbr-type-body4">{i18nMessageBundle.securityTabView.site} : {props.clusterTable ? siteName : props.siteNameTable} </div>
        </div> 
         
        {siteThreatOverview.length > 0 && (
          <>  
            <div data-cy="pdeTableView">
              <div
                className="dnx--css_table-container commonTableStyle securityTable"
                style={{
                  maxWidth: "100vw"
                }}
              >
                <Suspense fallback={<Spinner />}>
                  <DefaultSiteTable 
                    {...props} 
                    siteThreatOverview={siteThreatOverview}
                    getSdwanThreatTrendTable={getSdwanThreatTrendCallBack}   
                    tableType={'threadTable'}  
                  />
                </Suspense>
              </div>
          </div> 
        </> 
        )}
      </div>
    );
  } 
  else if (showUserTable && siteThreatTrend?.length > 0 ) {  
    return (
      <div className="sidebar-sites-thread-overview">
        <div className="sidebar-thread-user-sites">
          {props.clusterTable && (
            <>
            <div className="flex-items back-arrow hbr-type-body4" onClick={getTableSiteView}> {i18nMessageBundle.securityTabView.selectedCluster}</div> 
            <HbrIcon className="sidebar-thread-icon" name="caret-right"></HbrIcon>
            </>
          )}
            <div className="flex-items back-arrow hbr-type-body4" onClick={getTableThreatOverview}> 
            {i18nMessageBundle.securityTabView.selectedSite}: {props.clusterTable ? siteName : props.siteNameTable}
            </div> 
            <HbrIcon className="sidebar-thread-icon" name="caret-right"></HbrIcon>
            <div className="hbr-type-body4">{ipAddressBreadCrumb}</div>
        </div>
        <div className="sidebar-thread-user-heading">
            <div className="sidebar-thread-user-sites"> 
              <div className="sites-trend-timestamp sites-trend-tableip"> 
               {i18nMessageBundle.securityTabView.threatSourceCount} 
              </div> 
            </div>
            <div className="hbr-type-body4 sites-trend-timestamp"> {i18nMessageBundle.securityTabView.last30Days}  ({getPreviousMonthDate(30)}  - {getPreviousMonthDate()}) {i18nMessageBundle.securityTabView.timeInUTC}</div>
        </div>
        <div className="sidebar-table-heading">
          {siteThreatTrend && siteThreatTrend.length > 0 && (
            <>

        <ThreatStackedBarChart  
            data={siteThreatTrend}
            disabledScrollbar={false}
            isTitleShown={true}
            chartsData={[]}
            chartProps={{
              chartId: "anomaly-chart",
              leftTitleForY: "Anomaly counts",
            }} 
            selectedBar={selectedHour}
            callThreadDetailApi={getApiSdwanSiteThreatDetail}
          /> 
           
            </>
          )}
        </div>

        {/******sdwan thread source detail table******* */}
        {detailLoader ? (
           <>
            <Spinner /> 
          </>
        ) :(!detailLoader && siteThreatTrendDetail && siteThreatTrendDetail.length > 0) ? (
          <>
            <div className="user-detail-table" >
            <div className="sites-trend-tableip"> {i18nMessageBundle.securityTabView.clientImpactedOn} {shortDisplayDateForSdwan(threadDetailTimeEntry)}</div>
            <div data-cy="pdeTableView">
              <div
                className="dnx--css_table-container commonTableStyle securityTable"
                style={{
                  maxWidth: "100vw"
                }}
              >
              <Suspense fallback={<Spinner />}>
                <DefaultSiteTable 
                    {...props}  
                    siteThreatTrendDetail={siteThreatTrendDetail} 
                    tableType={'threadDetailTable'}  
                  />
              </Suspense>
              </div>
              </div>
            </div>
          </>  
        ) : (
          <Suspense fallback={<Spinner />}>
          <NoDataAvailable
            text={i18nMessageBundle.securityTabView.noSitesOnMap}
          />
        </Suspense>
        )}
      </div>
    );
  }   
  else if (siteThreatOverviewError || siteThreatTrendError || siteThreatTrendDetailError) {
      return (
        <div>
          <Suspense fallback={<Spinner />}>
            <ErrorComponent
              {...siteThreatOverviewError}
              {...siteThreatTrendError}
              {...siteThreatTrendDetailError}
              width={"300px"}
              className={"large-dashlet-error"}
            />
          </Suspense>
        </div>
      );
  } else {
    return (
      <Suspense fallback={<Spinner />}>
        <NoDataAvailable
          text={i18nMessageBundle.securityTabView.noSitesOnMap}
        />
      </Suspense>
    )
  }
};

SdwanSidebar.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  sdwanData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]), 
  setSdwanSiteThreatOverview: PropTypes.func, 
  siteId: PropTypes.any,
  siteNameTable: PropTypes.any,
  clustersSiteId: PropTypes.array, 
  clusterTable: PropTypes.bool, 
  drawerHeading: PropTypes.any 
};

export default SdwanSidebar;
