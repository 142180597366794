import { createContext } from "@cisco-dna/redux-utils";
import _ from "lodash";

import {
  parseAppCount,
  updateCustomList,
  parseSelections
} from "../../../config/apiParsers/applications";

const reduxContext = createContext();

export default reduxContext;

const initialState = {
  appCount: null,
  customList: null,
  selections: [],
  error: {
    selectionError: null,
    appCountError: null
  },
  qoeHealth: [
    {
      key: "all",
      value: "All"
    },
    {
      key: "poor",
      value: "Poor"
    },
    {
      key: "fair",
      value: "Fair"
    },
    {
      key: "good",
      value: "Good"
    },
    {
      key: "gray",
      value: "Unknown"
    }
  ],
  appClassList: [],
  appCount_loader: true,
  customList_loader: true,
  timeFilter: [],
  selectedCustomFilter: {
    qoeFilter: "all",
    classFilter: "all"
  }
};

const reducer = (state = initialState, action) => {
  // TODO handle errors
  const { type, payload, filter, error, loading, globalV4PayloadSidebar } = action;
  switch (type) {
    case "SET_APP_COUNT_LOADING":
      return {
        ...state,
        appCount_loader: true,
        error: { ...state.error, appCountError: null }
      };
    case "SET_CUSTOM_LIST_LOADING":
      return {
        ...state,
        customList_loader: true
      };
    case "SET_SELECTIONS_LOADING":
      return {
        ...state,
        selectionLoader: true,
        error: { ...state.error, selectionError: null }
      };
    case "SET_APP_COUNT":
      return {
        ...state,
        appCount: _.isEmpty(payload) ? payload : parseAppCount(payload, filter, globalV4PayloadSidebar),
        appCountOriginal: payload,
        appCount_loader: false
      };
    case "SET_CUSTOM_LIST":
      return {
        ...state,
        customList: updateCustomList(payload),
        customList_loader: false
      };
    case "SET_APP_CLASS_LIST":
      return {
        ...state,
        appClassList: payload
      };
    case "SET_SELECTIONS":
      return {
        ...state,
        selections: parseSelections(payload, filter),
        selectionLoader: false
      };
    case "SET_TIMEFILTER":
      return {
        ...state,
        timeFilter: payload
      };
    case "SET_SELECTED_CUSTOM_FILTER":
      return {
        ...state,
        selectedCustomFilter: payload
      };
    case "SET_ERROR": {
      return {
        ...state,
        ...loading,
        error: { ...state.error, ...error }
      };
    }
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: "vanalytics.trend",
  REDUCER: reducer,
  ACTION_TYPE: [
    "SET_APP_COUNT",
    "SET_CUSTOM_LIST",
    "SET_APP_CLASS_LIST",
    "SET_SELECTIONS_LOADING",
    "SET_SELECTIONS",
    "SET_APP_COUNT_LOADING",
    "SET_CUSTOM_LIST_LOADING",
    "SET_APP_COUNT",
    "SET_TIMEFILTER",
    "SET_SELECTED_CUSTOM_FILTER",
    "SET_ERROR"
  ]
});
