import { formatBytes } from "../../../../utils/format";
import apiService from "../../../../config/api-config";

export const ACTIONS = {
  SET_REPORT_LOADING: "SET_REPORT_LOADING",
  SET_REPORT_DATA: "SET_REPORT_DATA",
  SET_QOESUMMARY_LOADING: "SET_QOESUMMARY_LOADING",
  SET_QOESUMMARY: "SET_QOESUMMARY",
  SET_QOECHART_LOADING: "SET_QOECHART_LOADING",
  SET_QOECHART: "SET_QOECHART",
  TUNNEL_TABLE_LOADING: "TUNNEL_TABLE_LOADING",
  SET_TUNNEL_TABLE: "SET_TUNNEL_TABLE",
  TOPUSERS_TABLE_LOADING: "TOPUSERS_TABLE_LOADING",
  SET_TOPUSERS_TABLE: "SET_TOPUSERS_TABLE",
  SITE_LIST_LOADING: "SITE_LIST_LOADING",
  MAP_LOADING: "MAP_LOADING",
  MAP_SITES: "MAP_SITES",
  SITE_SUMMARY: "SITE_SUMMARY",
  SITE_SUMMARY_LOADING: "SITE_SUMMARY_LOADING",
  GET_SUMMARY: "GET_SUMMARY",
  SET_ERROR: "SET_ERROR",
  SET_CUSTOM_LIST: "SET_CUSTOM_LIST"
};

export const setReportLoading = () => ({
  type: ACTIONS.SET_REPORT_LOADING
});

export const getReportData = reportId => dispatch => {
  apiService.getReportByUUID(reportId).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.SET_REPORT_DATA,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          report_data: null,
          report_loader: false
        }
      });
    }
  });
};

export const setQOESummaryLoading = () => ({
  type: ACTIONS.SET_QOESUMMARY_LOADING
});

export const setQOEChartLoading = () => ({
  type: ACTIONS.SET_QOECHART_LOADING
});

export const getChartQOE = payload => dispatch => {
  apiService.getAggregateData(payload).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.SET_QOECHART,
        payload: res.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          qoe_chart_data: null,
          qoe_chart_loader: false,
          qoe_chart_error: res.errorObject
        }
      });
    }
  });
};

export const setTunnelTableLoading = () => ({
  type: ACTIONS.TUNNEL_TABLE_LOADING
});

export const getTunnelData = (current_period, appName) => dispatch => {
  const query = {
    current_period: current_period,
    filter: {
      application: appName
    }
  };
  apiService.getApp360AppFlow(query).then(res => {
    if (res && !res.errorObject) {
      const data = res.data?.data ? res.data.data : [];
      for (const item of data) item.usage = formatBytes(item.octets_sum);
      dispatch({
        type: ACTIONS.SET_TUNNEL_TABLE,
        payload: data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          tunnel_data: null,
          tunnel_table_loader: false,
          tunnel_table_error: res.errorObject
        }
      });
    }
  });
};

export const setTopUsersTableLoading = () => ({
  type: ACTIONS.TOPUSERS_TABLE_LOADING
});

export const getTopUsersData = (
  current_period,
  appName,
  overlayId
) => dispatch => {
  const query = {
    current_period: current_period,
    size: 10,
    offset: 0,
    filter: {
      application: appName
    }
  };
  apiService.getApp360TopUsers(query, overlayId).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.SET_TOPUSERS_TABLE,
        payload: res.data.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          top_users_table: null,
          top_users_loader: false,
          top_users_error: res.errorObject
        }
      });
    }
  });
};

export const isSiteListLoading = () => ({
  type: ACTIONS.SITE_LIST_LOADING
});

export const isMapLoading = () => ({
  type: ACTIONS.MAP_LOADING
});

export const getMapViewSites = (filter, overlayId) => dispatch => {
  apiService.getMapViewSitesOptimized(filter, overlayId).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.MAP_SITES,
        payload: res.data.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          mapSites: null,
          map_loader: false,
          mapSitesError: res.errorObject
        }
      });
    }
  });
};

export const isSiteSummaryLoading = () => ({
  type: ACTIONS.SITE_SUMMARY_LOADING
});

export const getTableCustomList = (filter, overlayId) => dispatch => {
  apiService.getTableCustomList(filter, overlayId).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.SET_CUSTOM_LIST,
        payload: res.data.data
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          customList: [],
          customListError: res.errorObject,
          siteSummary_loader: false
        }
      });
    }
  });
};
