import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export const columnsCircuitName = () => [
  {
    prop: "circuit_display_name",
    name: i18nMessageBundle.circuitViewCircuits,
    size: 160
  },
  {
    prop: "availability",
    name: i18nMessageBundle.circuitViewAvailability,
    size: 130,
    columnTemplate: createElement => {
      const content = createElement("div", {
        style: {
          display: "flex",
          alignItems: "center"
        },
        innerHTML: `${i18nMessageBundle.circuitViewAvailability}
                      <hbr-tooltip
                      content-id="some-id"
                      content="Circuit availability and trend (+/-) compared with previous time range selection"
                    >
                      <hbr-icon name="info" sentiment="neutral"></hbr-icon>
                    </hbr-tooltip>`
      });
      return [content];
    }
  }
];

export const getSourceCircuit = data => {
  return data.map(element => {
    return {
      circuit_name: element.circuit_display_name,
      availability_display: element.availability_display
    };
  });
};
