import { useState, useEffect } from "react";
import axios from "axios";
import ApiService from "../../../../config/api-config";
import * as apiConstant from "../../../../apis/apiConstants";
import "./style.less";
import PageNotFound from "../../../../common/PageNotFound";
import _ from "lodash";
import {
  getApplicationsTableList
} from "../../../../utils/views/applications";
import { fields,timeFilterTypes, timeFilterTypesCustomV4 } from "../../../../utils/enums";
import { shortDisplayDate24HourWithSec, getStartfDayUTCFormat, formatDate } from "../../../../utils/displayTime";

const CSVReport = () => {
  const envVarForReports = process.env.REACT_APP_SKIP_RBAC_DISABLE_RBAC;
  const [csvData, setData] = useState("");
  const [appHealthData, setAppHealthData] = useState("");
  const [appFlowData, setAppFlowData] = useState("");
  const [appTopUsersData, setTopUsersData] = useState("");
  const [app360Data, setApp360Data] = useState("");
  // largest overlay, application on us-west is Autozone https, which has 65k app-site rows in 1 month app360 appflow view
  const maxSize = 200000;
  const [is_debug, setIsDebug] = useState();

  let templateName;
  let file_type;
  let page_name;
  let current_period_start;
  let current_period_end;
  let appScope;
  let appName;
  let report_current_period;
  const reqTimeout = 300000;
  let report_time_range;

  useEffect(async () => {
    let reportId = location.pathname.split("/")[6];
    if (reportId) {
      const res = await ApiService.getReportByUUID(reportId);
      if (!res.errorObject) {
        if (res.data.length !== 0) {
          const reportMetadata = res.data;
          if (!(envVarForReports === "FALSE") || reportMetadata[0]?.is_debug) {
            templateName = reportMetadata[0].template_name;
            file_type = reportMetadata[0].file_type;
            page_name = reportMetadata[0].page_name;
            current_period_start = reportMetadata[0].current_period_start;
            current_period_end = reportMetadata[0].current_period_end;
            appScope = JSON.parse(reportMetadata[0].scope);
            appName = appScope.application[0];
            report_current_period = [current_period_start, current_period_end];
            report_time_range = reportMetadata[0].time_range;
          }
          setIsDebug(reportMetadata[0]?.is_debug);
        } else {
          console.log("No data available for the report UUID: ", reportId);
        }
      } else {
        console.log(
          "Fetching meta data error for report ID: " +
          reportId +
          "; Error: " +
          res.errorObject.message
        );
        setData("Fetching meta data error for report ID: " + reportId);
      }
    } else {
      console.log("Invalid reportid path: ", reportId);
      setData("Invalid reportid path");
    }

    if (
      templateName === "All Sites" &&
      file_type === "csv" &&
      page_name === "all_sites_csv"
    ) {
      getAllSitesDetails();
    } else if (
      templateName === "Application Summary" &&
      file_type === "csv" &&
      page_name === "all_apps_csv"
    ) {
      getAllAppsDetails();
    } else if (
      templateName === "Application Summary" &&
      file_type === "csv" &&
      page_name === "app360_csv"
    ) {
      getApp360Details();
    }
  }, []);

  useEffect(() => {
    if (appHealthData != "" && appFlowData != "" && appTopUsersData != "") {
      let jsonApp360Data = {};
      jsonApp360Data["sites"] = JSON.parse(appHealthData).sites;
      jsonApp360Data["appflow"] = JSON.parse(appFlowData).appflow;
      jsonApp360Data["topusers"] = JSON.parse(appTopUsersData).topusers;
      setApp360Data(JSON.stringify(jsonApp360Data));
    }
  }, [appHealthData, appFlowData, appTopUsersData]);

  const getAllSitesDetails = async () => {
    // All sites template with only one table
    const jsonKey = "allsites";
    let globalV4PayloadReport = {
      time_frame: timeFilterTypesCustomV4[report_time_range],
      entry_ts: {
        start: shortDisplayDate24HourWithSec(formatDate(report_current_period[0]).utc()),
        end: shortDisplayDate24HourWithSec(formatDate(report_current_period[1]).utc())
        }
      };
    const defaultRequest = { ...globalV4PayloadReport }
    const url = apiConstant.SITES_TABLE_AVAILABILITY_TIMEFRAME;

    try {
      const result = await getData(url, defaultRequest);
      setData(getCsvSuccessObject(jsonKey, result.data.data));
    } catch (e) {
      setData(getCsvErrorObject(jsonKey, e));
    }
  };

  const getAllAppsDetails = async () => {
    //All Application template with only one table
    const jsonKey = "allapps";
    try {
      let promisesQueue = [];
          let globalV4PayloadReport = {
        time_frame:  timeFilterTypesCustomV4[report_time_range],
        entry_ts: {
          start: shortDisplayDate24HourWithSec(formatDate(report_current_period[0]).utc()),
          end: shortDisplayDate24HourWithSec(formatDate(report_current_period[1]).utc())
        }
      };
      const defaultRequest = { ...globalV4PayloadReport }
      defaultRequest.sort = { usage: "desc" };
      promisesQueue.push(
        ApiService.getTableCustomList(defaultRequest)
      );

      Promise.all([...promisesQueue]).then(aggregateApplicationsResults => {
        let tableList = getApplicationsTableList(
          aggregateApplicationsResults[0].data
        );

        const result = tableList;
        setData(getCsvSuccessObject(jsonKey, result));
      });
    } catch (e) {
      setData(getCsvErrorObject(jsonKey, e));
    }
  };

  const getApp360Details = async () => {
    //Application 360 template with 3 tables
    getAppHealthDetails();
    getAppflowDetails();
    getTopUsersDetails();
  };

  const getAppHealthDetails = async () => {
    //Sites - app360/app_health/across_sites
    const jsonKey = "sites";
    const url = apiConstant.OP_MAP_BY_SITES_URL;
    const timeFrame = timeFilterTypes[report_time_range];

    const request = {
      time_frame: timeFrame,
      application: appName,
      entry_ts: {
        start: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(report_current_period[0])),
        end: shortDisplayDate24HourWithSec(getStartfDayUTCFormat(report_current_period[1]))//"2024-03-28 00:00:00"
      },
      size: maxSize,
      offset: 0,
      ...{ fields: fields }
    }

    try {
      const result = await getData(url, request);
      setAppHealthData(getCsvSuccessObject(jsonKey, result.data.data));
    } catch (e) {
      setAppHealthData(getCsvErrorObject(jsonKey, e));
    }
  };

  const getAppflowDetails = async () => {
    //Appflow API - /api/dataservice/statistics/custom/app360/appflow/tunnel_list
    const jsonKey = "appflow";
    const url = apiConstant.APP360_APPFLOW_URL;

    let payloadAppFlow = {
      current_period: report_current_period,
      filter: {
        application: appName
      }
    };

    try {
      const result = await getData(url, payloadAppFlow);
      setAppFlowData(getCsvSuccessObject(jsonKey, result.data.data));
    } catch (e) {
      setAppFlowData(getCsvErrorObject(jsonKey, e));
    }
  };

  const getTopUsersDetails = async () => {
    //Top Users - app360/top_users/default
    const jsonKey = "topusers";
    const url = apiConstant.APP360_TOP_USERS_URL;
    let payloadTopUsers = {
      current_period: report_current_period,
      filter: {
        application: appName
      }
    };

    try {
      const result = await getData(url, payloadTopUsers);
      setTopUsersData(getCsvSuccessObject(jsonKey, result.data.data));
    } catch (e) {
      setTopUsersData(getCsvErrorObject(jsonKey, e));
    }
  };

  const getData = (url, data, headers, overlayId) => {
    headers = overlayId ? { "SDWAN-Overlay": overlayId } : {};
    return axios({
      method: "post",
      url: url,
      data: data,
      headers: headers,
      timeout: reqTimeout
    });
  };

  const getCsvSuccessObject = (jsonKey, response) => {
    let jsonData = {};
    jsonData[jsonKey] = {};
    let status = {};
    status["type"] = "success";
    status["message"] = "success";
    jsonData[jsonKey]["response"] = response;
    jsonData[jsonKey]["status"] = status;
    const respData = JSON.stringify(jsonData);
    return respData;
  };

  const getCsvErrorObject = (jsonKey, err) => {
    let jsonData = {};
    jsonData[jsonKey] = {};
    let status = {};
    status["type"] = "error";

    if (!err.response) {
      status["status"] = "error";
      status["statusText"] = err.message;
      status["message"] = err.message;
    } else if (err.response.status && err.response.statusText) {
      status["status"] = err.response.status;
      status["statusText"] = err.response.statusText;
      status["message"] = err.message;
    }

    jsonData[jsonKey]["response"] = {};
    jsonData[jsonKey]["status"] = status;
    const respData = JSON.stringify(jsonData);
    console.log("CSV Object Error while fetching " + jsonKey + " data: ", err);
    return respData;
  };

  //by default is_debug is false and report browser link is disable/block to show reports
  if (envVarForReports === "FALSE") {
    if (!is_debug) {
      if (_.isBoolean(is_debug)) return <PageNotFound />;
      else return null;
    }
  }

  return csvData === "" && app360Data === "" ? (
    <div className="csvReportDiv">Generating CSV Report Object ....</div>
  ) : (
      <div className="csvObjectDiv" id="isLoaded_chart">
        {csvData}
        {app360Data}
      </div>
    );
};

export default CSVReport;

