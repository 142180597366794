import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export default {
  title: i18nMessageBundle.dashletSiteViewSites,
  subTitle: i18nMessageBundle.dashletSiteViewSites,
  siteLegend: {
    theme: "overlay",
    labels: [
      i18nMessageBundle.applicationCircuitViewLabelAvailable,
      i18nMessageBundle.applicationCircuitViewLabelPrevious,
      i18nMessageBundle.applicationOverviewTotal
    ]
  },
  columns: [
    {
      title: i18nMessageBundle.dashletSiteViewSites,
      colSpan: 1,
      property: "site_name",
      type: "string",
      format: "link",
      maxLength: 15,
      width: "20%"
    },
    {
      title: `% ${i18nMessageBundle.dashletSiteViewAvailability}`,
      colSpan: 1,
      property: "up_percent",
      type: "number",
      format: "percent",
      theme: "changeQuality",
      tooltip: true,
      tooltipTitle: i18nMessageBundle.siteTableAvailBarChart,
      tooltipDefinition: i18nMessageBundle.siteTableAvailBarChartDefinition,
      width: "20%",
      padding: "0px"
    },
    {
      title: i18nMessageBundle.siteViewLocation,
      colSpan: 1,
      property: "city",
      type: "string",
      format: "link",
      maxLength: 20,
      padding: "2px"
    },
    {
      title: i18nMessageBundle.sitesViewTotalTime,
      colSpan: 1,
      property: "chart",
      type: "chart",
      format: "overlayBarChart",
      padding: "0px",
      width: "30%",
      maxLength: 10,
      tooltipEllipse: true
    }
  ]
};
