import Authorization from "../../../../generics/authorization";
import SiteSummary from "./index";

const pageProps = {
  workflowContainer: true,
  dashlets: [{
    component: SiteSummary,
    name: "SiteSummary",
    layout: { x: 0, y: 0, w: 100, h: 40 }
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
