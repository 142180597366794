import React, { useEffect } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//reducer
import reduxContext from "./reducer";
import * as actions from "./actions";

//components
import PageNotFound from "../../../../common/PageNotFound";
import Spinner from "../../../../common/Spinner";
import AppHoc from "../../../../generics/AppHOC";
import SectionHeading from "../../../../common/SectionHeading";
import ErrorComponent from "../../../../common/ErrorComponent";
import ApplicationsTableView from "../../../../views/applications/ApplicationsTableView";
import ApplicationExperienceView from "../../../../views/applications/ApplicationExperienceView/index.js";
import QoeDistributionView from "../../../../views/applications/QoeDistributionView/index.js";
import TrendingAppsView from "../../../../views/applications/TrendingAppsView/index.js";
import TimeStamp from "../../../../generics/TimeStamp";
import { getCustomListApi } from "../../../../utils/common";

//i18n file
import i18n from "amdi18n-loader!../../../nls/i18n";

//utils
import { getReportsHeader } from "../../../../../src/utils/common";
import { BASE_URL } from "../../../../apis/apiConstants";

//style
import css from "../../reportsMagneticStyle.less";
import {
  shortDisplayDate24HourWithSec,
  formatDate
} from "../../../../utils/displayTime";
import { timeFilterTypesCustomV4 } from "../../../../utils/enums";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    applicationSummary: state.vanalytics.applicationSummary
  };
};

const ApplicationSummary = props => {
  const envVarForReports = process.env.REACT_APP_SKIP_RBAC_DISABLE_RBAC;
  const reportId = props.match.params.id;

  useEffect(() => {
    const { getReportData } = props.actions;
    getReportData(reportId);
  }, []);

  useEffect(() => {
    if (props.applicationSummary?.reportData?.data?.length > 0) {
      const reportData = props.applicationSummary.reportData.data[0];

      let globalV4PayloadReport = {
        time_frame: timeFilterTypesCustomV4[reportData.time_range],
        entry_ts: {
          start: shortDisplayDate24HourWithSec(formatDate(reportData.current_period_start).utc()),
          end: shortDisplayDate24HourWithSec(formatDate(reportData.current_period_end).utc())
        }
      };

      getCustomListApi(
        props,
        globalV4PayloadReport,
        true
      )
    }
  }, [props.applicationSummary?.reportData?.data[0]]);

  if (props.applicationSummary.reportLoader) {
    return <Spinner />;
  } else if (props.applicationSummary.errorReportData) {
    return (
      <ErrorComponent
        {...props.applicationSummary.errorMessage}
        width={"210px"}
        className={"small-dashlet-error"}
      />
    );
  } else if (props.applicationSummary.reportData.data.length > 0) {
    const reportData = props.applicationSummary.reportData.data[0];
    const {
      overlay_id,
      current_period_start,
      current_period_end,
      previous_period_start,
      previous_period_end,
      scope,
      report_uuid,
      time_range
    } = reportData;

    const applicationType = JSON.parse(scope).application[0];
    if (!(applicationType == "all")) {
      if (envVarForReports === "FALSE")
        if (!reportData?.is_debug) return <PageNotFound />;

      props.history.push(
        `${BASE_URL}/api/dataservice/reporting/${report_uuid}/application_summary/${applicationType}`,
        reportData
      );
      return null;
    }

    const timeFilter = {
      current_period: [current_period_start, current_period_end],
      previous_period: [previous_period_start, previous_period_end]
    };
    let globalV4PayloadReport = {
      time_frame: timeFilterTypesCustomV4[time_range],
      entry_ts: {
        start: shortDisplayDate24HourWithSec(formatDate(current_period_start).utc()),
        end: shortDisplayDate24HourWithSec(formatDate(current_period_end).utc())
      }
    };
    const {
      appExperience,
      reportHeadingQoEDistribution,
      trendingApplications,
      top50Applications,
    } = i18n;

    //by default is_debug is false and report browser link is disable/block to show reports
    if (envVarForReports === "FALSE") {
      if (reportData && !reportData?.is_debug) {
        return <PageNotFound />;
      }
    }

    return (
      <div className={css["applicaiton-summary-report"]}>
        {getReportsHeader(reportData.overlay_name, props.location)}
        <div className="all-applicaiton-scope-section">
          <span className="details">
            <span className="title hbr-type-body3">{i18n.scopeHeading}</span>
            <span className="value hbr-type-body3">{i18n.allApplications}</span>
          </span>
          <TimeStamp {...props} isReport={true} reportData={reportData} />
        </div>
        <SectionHeading title={appExperience} />
        <div className="widget-wrapper">
          <ApplicationExperienceView
            timeFilter={timeFilter}
            overlayId={overlay_id}
            reporting={true}
          /></div>
        <SectionHeading title={reportHeadingQoEDistribution} />
        <div className="widget-wrapper">
          <QoeDistributionView
            timeFilter={timeFilter}
            overlayId={overlay_id}
            reporting={true}
          ></QoeDistributionView></div>
        <SectionHeading title={trendingApplications} />
        <div className="widget-wrapper">
          <TrendingAppsView
            timeFilter={timeFilter}
            overlayId={overlay_id}
            reporting={true}
          ></TrendingAppsView></div>
        <SectionHeading title={top50Applications} />
        <div className="widget-wrapper">
          <ApplicationsTableView
            globalV4PayloadReport={globalV4PayloadReport}
            overlayId={overlay_id}
            reporting={true}
          /></div>
      </div>
    );
  } else {
    return <PageNotFound />;
  }
};

ApplicationSummary.propTypes = {
  applicationSummary: PropTypes.object.isRequired,
  actions: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(ApplicationSummary))
);
