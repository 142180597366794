import ApiService from "../../../../config/api-config";

export const ACTIONS = {
  SET_REPORT_LOADING: "SET_REPORT_LOADING",
  SET_REPORT_DATA: "SET_REPORT_DATA",
  SET_ERROR: "SET_ERROR"
};

export const setReportLoading = () => ({
  type: ACTIONS.SET_REPORT_LOADING
});

export const getReportData = reportId => dispatch => {
  ApiService.getReportByUUID(reportId).then(res => {
    if (res && !res.errorObject) {
      dispatch({
        type: ACTIONS.SET_REPORT_DATA,
        payload: res
      });
    } else {
      dispatch({
        type: ACTIONS.SET_ERROR,
        payload: {
          errorReportData: true,
          reportLoader: false,
          errorMessage: res.errorObject
        }
      });
    }
  });
};
