import { useState } from "react";

import ApiService from "../../../config/api-config";

const useReportApis = () => {
  const [appsDetails, setAppsDetails] = useState({});
  const [isFetchingAppsDetailsDone, setIsFetchingAppsDetailsDone] = useState(
    false
  );
  const [isFetchingAppsDetailsError, setIsFetchingAppsDetailsError] = useState(
    false
  );
  const [sitesDetails, setSitesDetails] = useState({});
  const [isFetchingSitesDetailsDone, setIsFetchingSitesDetailsDone] = useState(
    false
  );
  const [
    isFetchingSitesDetailsError,
    setIsFetchingSitesDetailsError
  ] = useState(false);
  const [reportDetails, setReportDetails] = useState({});
  const [
    isFetchingReportDetailsDone,
    setIsFetchingreportDetailsDone
  ] = useState(false);
  const [
    isFetchingReportDetailsError,
    setIsFetchingreportDetailsError
  ] = useState(false);

  const getReportDetails = async id => {
    setIsFetchingreportDetailsDone(false);
    setIsFetchingreportDetailsError(false);

    try {
      const result = await ApiService.getReportByUUID(id);
      const details = result.data;

      setReportDetails(details);
      setIsFetchingreportDetailsDone(true);
    } catch (error) {
      setIsFetchingreportDetailsError(true);
    }
  };

  const getAppsDetails = async request => {
    setIsFetchingAppsDetailsDone(false);
    setIsFetchingAppsDetailsError(false);

    try {
      const result = await ApiService.getApplications(request);
      const details = result.data.data;

      setAppsDetails(details);
      setIsFetchingAppsDetailsDone(true);
    } catch (error) {
      setIsFetchingAppsDetailsError(true);
    }
  };

  const getSitesDetails = async () => {
    setIsFetchingSitesDetailsDone(false);
    setIsFetchingSitesDetailsError(false);

    const res = await ApiService.getSites();
    if (res.errorObject instanceof Object)
      setIsFetchingSitesDetailsError(true);
    else {
      const details = res.data.data;
      setSitesDetails(details);
      setIsFetchingSitesDetailsDone(true);
    }
  };

  return {
    state: {
      appsDetails,
      isFetchingAppsDetailsDone,
      isFetchingAppsDetailsError,
      sitesDetails,
      isFetchingSitesDetailsDone,
      isFetchingSitesDetailsError,
      reportDetails,
      isFetchingReportDetailsDone,
      isFetchingReportDetailsError
    },
    getAppsDetails,
    getSitesDetails,
    getReportDetails
  };
};
export default useReportApis;
