import React, { Suspense, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import _ from "lodash";
import format from "string-template";

import reactWrapper from "@harbor/elements/utils/react/wrapper";

//reducers
import reduxContext from "../reducer";
import * as actions from "../actions";

//component
import AppHoc from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);

//utils
import {
  formatNumber,
  formatBytes,
  formatNumberToPositiveInteger
} from "../../../utils/format";
import { siteSummary, singleSiteSummary } from "./config/summaryConfig";
import { isCustom, isReportingApi, getLocalStorageFlag } from "../../../utils/common";
import { getCircuitsHighAvailability } from "../../circuits/common/masterTableUtil";
import timeFilterConfig from "../../../generics/config/time-filter-config";
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";

//styles
import css from "../overviewMagneticStyle.less";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    overview: state.vanalytics.overview
  };
};

const [HbrCard, HbrTooltip, HbrIcon] = reactWrapper([
  "hbr-card",
  "hbr-tooltip",
  "hbr-icon",
  "hbr-icon-button"
]);

const FilterView = props => {
  let circuitData = {
    siteId: NaN,
    data: {
      current: 0,
      previous: 0,
      change: 0
    }
  };
  const {
    selectedSite,
    siteAPIData,
    filterViewDevice,
    filterViewLoaderDevice,
    errorFilterViewDevice,
    circuitsMasterTable,
    overviewSiteSummary,
    timePeriodSelected,
    currentTimeStamp,
    timeFilter,
    app_data_with_family_sla
  } = props.globalFilter;

  const noAppHeroCard = props.globalFilter.app_data_with_family_sla === undefined;

  const [currentPeriod, setCurrentPeriod] = useState(timeFilter.current_period[0]);
  const [prevPeriod, setPrevPeriod] = useState(timeFilter.current_period[1]);
  const { filterViewLoaderSite, errorFilterViewSite } = siteAPIData;
  let summary = selectedSite > 0 ? singleSiteSummary : siteSummary;
  let siteWidgetLoader =
    selectedSite > 0 ? filterViewLoaderDevice : filterViewLoaderSite;
  let filterViewSite =
    selectedSite > 0 ? filterViewDevice : overviewSiteSummary;
  let error =
    errorFilterViewSite ||
    errorFilterViewDevice ||
    noAppHeroCard ||
    circuitsMasterTable.error;

  useEffect(() => {
    /***** Remove all data from breadcrumbs array in overview page******/
    const breadcrumbsSessionStorage = JSON.parse(
      sessionStorage.getItem("breadcrumbs")
    );
    if (breadcrumbsSessionStorage?.length > 1) {
      breadcrumbsSessionStorage.splice(1);
    }
    sessionStorage.setItem(
      "breadcrumbs",
      JSON.stringify(breadcrumbsSessionStorage)
    );
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    circuitData.siteId = NaN;
    if (!isReportingApi() && (
      !(isCustom(timePeriodSelected)) ||
      !(currentPeriod === timeFilter.current_period[0]) ||
      !(prevPeriod === timeFilter.current_period[1]))
    ) {
      if (!(currentPeriod === timeFilter.current_period[0]))
        setCurrentPeriod(timeFilter.current_period[0]);
      if (!(prevPeriod === timeFilter.current_period[1]))
        setPrevPeriod(timeFilter.current_period[1])
      if (timePeriodSelected === getLocalStorageFlag("timePeriodSelected"))
        getSummary();
    } else if (isReportingApi()) {
      getSummary();
    }
  }, [
    timePeriodSelected,
    timeFilter.current_period[0],
    timeFilter.current_period[1],
    currentTimeStamp,
    selectedSite
  ]);

  const formatTooltip = stat => {
    const { tooltip, key, tooltipKey, title } = stat;
    const timeFilterType = props.globalFilter.timePeriodSelected;
    const timeLabel =
      timeFilterConfig[timeFilterType] &&
      timeFilterConfig[timeFilterType].label;
    const prevTimeLabel =
      timeFilterConfig[timeFilterType] && timeFilterConfig[timeFilterType].text;
    let currentValue, changeValue, changeText;

    currentValue =
      key === "usage"
        ? formatBytes(overviewSiteSummary.site_availability.current)
        : overviewSiteSummary.site_availability.current === "-"
          ? overviewSiteSummary.site_availability.current
          : formatNumberToPositiveInteger(
            overviewSiteSummary.site_availability.current
          );
    changeValue =
      overviewSiteSummary.site_availability.change === "-"
        ? overviewSiteSummary.site_availability.change
        : formatNumberToPositiveInteger(
          overviewSiteSummary.site_availability.change
        );
    changeText =
      overviewSiteSummary.site_availability.change > 0
        ? `${i18nMessageBundle.summaryIncreaseLabel} ${changeValue}%`
        : changeValue === 0
          ? i18nMessageBundle.summarynoChangeLabel
          : `${i18nMessageBundle.summaryDecreaseLabel} ${changeValue}%`;

    return format(i18nMessageBundle[tooltipKey], {
      title: title?.toLowerCase(),
      changeText: changeText,
      tooltip: tooltip,
      currentValue: currentValue,
      timeLabel: timeLabel,
      prevTimeLabel: prevTimeLabel
    });
  };

  const getSummary = () => {
    const { setDashletLoading } = props.actions;
    setDashletLoading("FILTER");
  };

  const formatData = (value, property, format, key) => {
    switch (key) {
      case "usage":
        return property === "current"
          ? formatBytes(value[property])
          : formatNumber(value[property], format);
      case "devices":
        return property === "current"
          ? formatNumber(value[property])
          : formatNumber(value[property], "percent");
      default:
        return formatNumber(value[property], format);
    }
  };

  const renderSummaryData = (value, key, subTitleToolTip) => {
    return summary.data.map(data => {
      const { property, format } = data;

      const getChangeValueComponent = trend => (
        <HbrTooltip
          content={subTitleToolTip}
          placement="bottom"
          key={Math.random()}
        >
          <span
            key={property}
            className={
              trend
                ? "percent-no-sign hbr-type-body3"
                : "percent-no-sign hbr-type-body1"
            }
          >
            {trend}
            {trend ? `${formatData(value, property, "percentNoSign", key)}` : ` ${formatData(value, property, "percentNoSign", key)}`}
          </span>
        </HbrTooltip>
      );

      const getZeroValueComponent = (trend, className) => (
        <HbrTooltip
          content={subTitleToolTip}
          placement="bottom"
          key={Math.random()}
        >
          <span
            key={Math.random()}
            className={
              trend
                ? `${className} no-color-change hbr-type-body3`
                : `${className} hbr-type-body1`
            }
          >{trend ? i18nMessageBundle.summaryNoChange : ` ${formatData(value, property, format, key)}`}</span>
        </HbrTooltip>
      );

      switch (true) {
        case value.current === "-" || value.change === "-":
          return (
            <span
              key={property}
              className={property === "current" ? "current" : "hyphen"}
            >
              {value.current}
            </span>
          );
        case key === "devices": {
          const trend = property === "current" ? null : <span> </span>;
          return getZeroValueComponent(trend, "device-widget");
        }
        case value.change < 0.5 && value.change > -0.5: {
          const trend =
            property === "current" ? null : (
              <span className="minus-icon"></span>
            );
          return getZeroValueComponent(trend, property);
        }
        case value.change < 0: {
          const trend =
            property === "current" ? null : (
              <span className="trend-icon">
                <HbrIcon name="trend-down" sentiment="danger"></HbrIcon>
              </span>
            );
          return getChangeValueComponent(trend, property);
        }
        default: {
          const trend =
            property === "current" ? null : (
              <span key={Math.random()}>
                <HbrIcon name="trend-up" sentiment="success"></HbrIcon>
              </span>
            );

          return getChangeValueComponent(trend);
        }
      }
    });
  };

  const calPercent = scoreArr => {
    let badCnt = 0, fairCnt = 0, goodCnt = 0, percentage = - 1;
    scoreArr.map(score => {
      if (score < 5)
        badCnt++;
      else if (score >= 5 && score < 8)
        fairCnt++;
      else if (score >= 8)
        goodCnt++;
    });
    if (goodCnt + fairCnt + badCnt == 0)
      percentage = 0;
    else {
      percentage = (100 * goodCnt) / (goodCnt + fairCnt + badCnt);
    }
    return percentage;
  };

  const renderSummaryHeroCard = (data, stat) => {
    if (_.isEmpty(data))
      return null;
    const { title, subTitle, tooltip, key, tooltipKey, subTitleToolTip } = stat;

    const timeFilterType = timePeriodSelected;
    const timeLabel =
      timeFilterConfig[timeFilterType] &&
      timeFilterConfig[timeFilterType].label;
    const prevTimeLabel =
      timeFilterConfig[timeFilterType] && timeFilterConfig[timeFilterType].text;
    let currentValue, changeValue, changeText, prev_Percentage, current_Percentage, change_Percentage;

    if (key === "application_vqoe_health") {
      let filteredApps = data?.filter(item => {
        if (item['vqoe_status'] != "unknown")
          return item;
      });
      let filteredPrevApps = data?.filter(item => {
        if (item['vqoe_status'] != "unknown" && item['prev_vqoe_score'] != 0)
          return item;
      });
      let vqoeArr = filteredApps.map(app => { return app['vqoe_score'] });
      let prev_vqoeArr = filteredPrevApps.map(app => { return app['prev_vqoe_score'] });
      prev_Percentage = calPercent(prev_vqoeArr);
      current_Percentage = calPercent(vqoeArr);
      change_Percentage = current_Percentage - prev_Percentage;
      data = { change: change_Percentage, current: current_Percentage };
    }
    currentValue =
      key === "usage"
        ? key === "application_vqoe_health"
          ? current_Percentage
          : formatBytes(data.current)
        : data.current === "-"
          ? data.current
          : formatNumberToPositiveInteger(data.current);
    changeValue =
      key === "application_vqoe_health" ?
        change_Percentage
        : data.change === "-"
          ? data.change
          : formatNumberToPositiveInteger(data.change);
    changeText =
      data.change > 0
        ? `${i18nMessageBundle.summaryIncreaseLabel} ${parseFloat(changeValue).toFixed(1)}%`
        : changeValue === 0
          ? i18nMessageBundle.summarynoChangeLabel
          : `${i18nMessageBundle.summaryDecreaseLabel} ${parseFloat(changeValue).toFixed(1)}%`;

    const tooltipMessage = format(i18nMessageBundle[tooltipKey], {
      title: title.toLowerCase(),
      changeText: changeText,
      tooltip: tooltip,
      currentValue: currentValue,
      timeLabel: timeLabel,
      prevTimeLabel: prevTimeLabel
    });
    const summary = renderSummaryData(data, key, tooltipMessage);

    return (
      <>
        <div className="hero-summary">{summary}</div>
        <div>
          <span className="subtitle hbr-type-body4">
            {subTitle}
            <HbrTooltip content={subTitleToolTip} placement="bottom">
              <HbrIcon name="info" sentiment="neutral"></HbrIcon>
            </HbrTooltip>
          </span>
        </div>
      </>
    );
  };

  const renderDeviceSummary = (overviewSiteSummary, stats) => {
    let toolTipArray = [];

    // stats is an array of length 2, each element of array in an object that has such keys:
    // key, title, subTitle, tooltip, tooltipKey
    for (const stat of stats) {
      toolTipArray.push(formatTooltip(stat));
    }

    return (
      <div className="hero-widget-summary">
        <HbrCard data-cy="hero-card-overview" container={true}>
          <div slot="header">
            <div className="main-header">
              <div className="hbr-type-h3">
                {stats[0].title}
              </div>
            </div>
          </div>
          <div className="summary-box-container">
            <div className="summary-box">
              <div className="data">
                {renderSummaryHeroCard(
                  overviewSiteSummary[stats[0].key],
                  stats[0]
                )}
              </div>
            </div>
            <div className="summary-box-right">
              <div className="data">
                {renderSummaryHeroCard(
                  overviewSiteSummary[stats[1].key],
                  stats[1]
                )}
              </div>
            </div>
          </div>
        </HbrCard>
      </div>
    );
  };

  const renderSummary = (filterView, stat) => {
    if (_.isEmpty(filterView)) return null;

    const { key, title } = stat;
    const result = summary.stats.filter(stat => stat.key === key);

    return result.map(stat => {
      const { key } = stat;
      const circuitsSummary = props.isReporting ? { data: props.circuitsSummary.availability } : circuitData;

      if (key === "circuit_availability") {
        const siteId = props.globalFilter.selectedSite;
        if (circuitData.siteId !== siteId) {
          circuitData.siteId = siteId;
          circuitData.data = getCircuitsHighAvailability(filterView, siteId);
        }
      }

      return (
        <div className="hero-widget-summary" key={Math.random()}>
          <HbrCard data-cy="hero-widget-summary-card" key={Math.random()} container={true}>
            <div slot="header">
              <div className="main-header">
                <div className="hbr-type-h3">
                  {title}
                </div>
              </div>
            </div>
            <div className="summary-box-device" key={Math.random()}>
              {key === "circuit_availability"
                ? renderSummaryHeroCard(circuitsSummary.data, stat)
                : renderSummaryHeroCard(filterView[key], stat)}
            </div>
          </HbrCard>
        </div>
      );
    });
  };

  return (
    <div className={css["ctrlDashlet"]}>
      {siteWidgetLoader || noAppHeroCard || (!isReportingApi && circuitsMasterTable.loader) ? (
        <div data-cy="spinner-componentFilterView">
          <Spinner />
        </div>
      ) : error ? (
        <Suspense fallback={<Spinner />}>
          <ErrorComponent
            {...error}
            width={"120px"}
            className={"one-line-dashlet-error"}
          />
        </Suspense>
      ) : (
            <div className="summary">
              {summary.siteSummary &&
                renderDeviceSummary(overviewSiteSummary, summary.siteSummary.stats)}
              {overviewSiteSummary ? (
                renderSummary(filterViewSite, summary.stats[0])
              ) : (
                  <Spinner />
                )}
              {app_data_with_family_sla ? (
                renderSummary({ "application_vqoe_health": app_data_with_family_sla }, summary.stats[1])
              ) : (
                  <Spinner />
                )}
              {renderSummary(circuitsMasterTable, summary.stats[2])}
            </div>
          )}
    </div>
  );
};

FilterView.propTypes = {
  actions: PropTypes.object.isRequired,
  timeFilter: PropTypes.object,
  globalFilter: PropTypes.object.isRequired,
  overview: PropTypes.object,
  isReporting: PropTypes.bool,
  circuitsSummary: PropTypes.object
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(FilterView))
);