import React, { useState, useEffect, useCallback, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

//components
import DashletSiteView from "../../../../views/overview/SiteView";
import DashletAppView from "../../../../views/overview/AppView";
import DashletCircuitView from "../../../overview/CircuitView";
import DashletUserView from "../../../overview/UserView";
import FilterView from "../../../overview/FilterView";
import Spinner from "../../../../common/Spinner";
import PageNotFound from "../../../../common/PageNotFound";
import ErrorComponent from "../../../../common/ErrorComponent";
import AppHoc from "../../../../generics/AppHOC";
import TimeStamp from "../../../../generics/TimeStamp";
import SectionHeading from "../../../../common/SectionHeading";

//reducers
import reduxContext from "./reducer";
import * as actions from "./actions";

//style
import css from "../../reportsMagneticStyle.less";

//18n file
import i18n from "amdi18n-loader!../../../nls/i18n";

//utils
import {
  getReportsHeader,
  getReportTrendText,
  getTimeFilter
} from "../../../../../src/utils/common";
import { useMount } from "../../../../utils/genericCommon";
import { getCircuitsData, getCircuitsHighAvailability } from "../../../circuits/common/masterTableUtil";
import { shortDisplayDate24HourWithSec } from "../../../../utils/displayTime";
import { timeFilterTypesCustomV4 } from "../../../../utils/enums";
import { formatDate } from "../../../../utils/displayTime";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => {
  return {
    executiveSummary: state.vanalytics.executive_summary
  };
};

const ExecutiveSummary = props => {
  const [circuitsData, setCircuitsData] = useState({
    loader: true,
    error: null,
    data: [],
    availability: null,
    query: { timeFilterType: "" }
  });
  const mount = useMount();
  const envVarForReports = process.env.REACT_APP_SKIP_RBAC_DISABLE_RBAC;
  const reportId = props.match.params.id;
  const previewRef = useRef(null);
  const { reportLoader, reportData, errorMessage } = props.executiveSummary;
  /*   if (reportData && reportData.length > 0) {
      const overlayName = reportData[0].overlay_name;
      const location = props.location.pathname;
    } */

  const getReport = () => {
    const { getReportData } = props.actions;
    getReportData(reportId);
  };

  const loadCircuitsData = useCallback(async filters => {
    const query = {
      time_frame: timeFilterTypesCustomV4[filters.time_range],
      entry_ts: {
        start: shortDisplayDate24HourWithSec(formatDate(filters.current_period_start).utc()),
        end: shortDisplayDate24HourWithSec(formatDate(filters.current_period_end).utc())
      }
    }
    if (!filters.time_range) {
      const hours = (filters.current_period_end - filters.current_period_start) / (60 * 60 * 1000);
      if (hours >= 720) query.timeFilterType = "720";
      else if (hours >= 168) query.timeFilterType = "168";
    }
    const res = await getCircuitsData(query);
    if (mount.mounted) {
      if (!res.errorObject) {
        // create circuits data for summary, donut and table
        setCircuitsData({
          loader: false,
          error: null,
          data: res.data.data,
          // set circuits summary data
          availability: getCircuitsHighAvailability(res.data),
          query
        });
      }
      else
        setCircuitsData({
          loader: false,
          error: res.errorObject,
          data: [],
          availability: null,
          query
        });
    }
  }, []);

  useEffect(() => {
    getReport();
  }, []);

  useEffect(() => {
    if (
      !props.executiveSummary.reportLoader &&
      props.executiveSummary.reportData &&
      props.executiveSummary.reportData.length > 0
    )
      // get circuits data for summary, donut and table
      loadCircuitsData(props.executiveSummary.reportData[0]);
  }, [props.executiveSummary.reportLoader]);

  useEffect(() => {
    if (!circuitsData.loader && previewRef.current) {
      previewRef.current.id = "isLoaded_chart";
    }
  }, [props.executiveSummary, circuitsData.loader]);

  //by default is_debug is false and report browser link is disable/block to show reports
  if (envVarForReports === "FALSE") {
    if (!reportLoader && reportData && !reportData?.[0]?.is_debug) {
      return <PageNotFound />;
    }
  }

  let timeRange = "";
  let legendText = "";
  if (reportData && reportData.length > 0) {
    timeRange = getReportTrendText(reportData[0]?.time_range);
    legendText = reportData[0]?.time_range
      ? getTimeFilter(reportData[0]?.time_range)
      : "24";
  }

  return (
    <>
      {reportLoader ? (
        <Spinner />
      ) : reportData && reportData.length > 0 ? (
        <div className={css["executive-summary-report"]} ref={previewRef}>
          {getReportsHeader(reportData[0].overlay_name, props.location)}
          <div className="exec-sum-scope-section">
            <span className="details">
              <span className="title hbr-type-body3">
                {i18n.scopeHeading}
              </span>
              <span className="value hbr-type-body3"> {i18n.scopeDetails}</span>
            </span>
            <TimeStamp {...props} isReport={true} reportData={reportData[0]}/>
          </div>
          <SectionHeading title={i18n.overallSummary} />
          <div className="widget-wrapper"><FilterView {...props} isReporting={true} circuitsSummary={circuitsData}/></div>
          <SectionHeading title={i18n.applicationSummary} />
          <div className="widget-wrapper"><DashletAppView {...props} isReporting={true} timeRange={timeRange} /></div>
          <div className="widget-wrapper client-widget"><DashletUserView isReporting={true} reportLegendText={legendText} reportData={reportData[0]}/></div>
          <div className="widget-wrapper info hbr-type-body3">{i18n.qoeInfo}</div>
          <SectionHeading title={i18n.siteSummary} />
          <div className="widget-wrapper">
            <DashletSiteView
              {...props}
              isReporting={true}
              timeRange={timeRange}
              reportLegendText={legendText}
            />
          </div>
          <div className="widget-wrapper info hbr-type-body3">{i18n.siteAvailabilityInfo}</div>
          <SectionHeading title={i18n.cicuitSummary} />
          <div className="widget-wrapper">
            <DashletCircuitView timeRange={timeRange} reporting reportData={circuitsData} />
          </div>
          <div className="widget-wrapper info hbr-type-body3">
            {i18n.circuitAvailabilityInfo}
          </div>
        </div>
      ) : reportData ? (
        <PageNotFound />
      ) : (
              <ErrorComponent {...errorMessage} className={"large-dashlet-error"} />
            )}
    </>
  );
};

ExecutiveSummary.propTypes = {
  executiveSummary: PropTypes.object.isRequired,
  actions: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(ExecutiveSummary))
);
