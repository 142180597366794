import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from "react";
import { PropTypes } from "prop-types";
import AppHOC from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import { useMount } from "../../../../utils/genericCommon";

import {
  getSelectedAppChartData
} from "../../../../config/apiParsers/applications";
import { getAppBWData } from "../actions";
import i18n from "amdi18n-loader!../../../nls/i18n";
import { chartPrimaryColors } from "../../../../utils/colors";
import StackedMultiLineChart from "../../../../common/StackedMultiLineChart";
import { defaultTextValue } from "../../../../utils/enums";
import { getTrendInitialLoad } from "../../../../utils/common";

const Bandwidth = ({ globalFilter, isCustom }) => {
  const [loader, setLoader] = useState(true);
  const [graphData, setGraphData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const dataRef = useRef({
    timestamp: 0,
    error: null,
    data: [],
    // selected applications while calling api
    selections: [],
    // applications from the selected list with valid usage data
    visibleApps: [],
    // series colors
    seriesColors: [],
    // current selections
    currentSelections: [],
    // chart legends based on visible applications
    colorSeries: {}

  });
  const mount = useMount();
  // reset state before calling the api
  const resetBeforeLoad = useCallback(() => {
    const timestamp = Date.now();
    Object.assign(dataRef.current, {
      timestamp,
      selections: [],
      visibleApps: [],
      seriesColors: [],
      error: null,
      data: [],
    });
    setLoader(true);
    setGraphData([]);
    return timestamp;
  }, []);

  // update current selections to be used in a asynchronous function after getting api response
  useMemo(() => {
    const selections = Array.isArray(globalFilter.selected_apps)
      ? globalFilter.selected_apps
      : [];
    dataRef.current.currentSelections = selections;
    let bandwidthColor = {}
    selections.forEach((item, appIndex) => {
      bandwidthColor[item] = chartPrimaryColors[appIndex]
    });
    dataRef.current.currentSelections.colorSeries = bandwidthColor
  }, [globalFilter.selected_apps]);

  // reload chart data on selection of top applications radio button
  useEffect(() => {
    if (mount.initMount === false && isCustom === false) {
      const timestamp = resetBeforeLoad();
      if (getTrendInitialLoad(globalFilter, initialLoad)) {
        getBWData(globalFilter, timestamp);
      }
      setInitialLoad(false);
    }
  }, [isCustom]);

  // reload chart data on selection change when custom radio button is selected
  useEffect(() => {
    if (
      mount.initMount === false && isCustom === true
      && Array.isArray(globalFilter.selected_apps)
    ) {
      const selections = globalFilter.selected_apps;
      // update chart without calling the api if the data for selected applications is already available
      let timestamp = 0;
      if (selections.length > 0 || dataRef.current.error !== null)
        timestamp = resetBeforeLoad();
      // reload chart data on selection if the data for the selected applications is not available
      if (selections.length > 0) {
        dataRef.current.selections = selections;
        if (getTrendInitialLoad(globalFilter, initialLoad)) {
          getBWData(globalFilter, timestamp, selections);
        }
        setInitialLoad(false);
      }
      // display no data available if there is no selected application
      else {
        setGraphData([]);
        setLoader(false);
      }
    }
    mount.initMount = false;
  }, [globalFilter.selected_apps]);

  // reload chart data on time filter change if top applications radio button is selected
  useEffect(() => {
    const timestamp = resetBeforeLoad();
    if (isCustom === false) {
      if (getTrendInitialLoad(globalFilter, initialLoad)) {
        getBWData(globalFilter, timestamp);
      }
      setInitialLoad(false);
    }
  }, [
    globalFilter.sideBarTimeFilter.current_period[0],
    globalFilter.sideBarTimeFilter.current_period[1]
  ]);

  // load chart data
  const getBWData = useCallback(async (gFilter, timestamp, selections) => {
    const res = await getAppBWData(gFilter.globalV4PayloadSidebar, selections);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        const isCustomView = Array.isArray(selections);
        const resData = res.data.data;
        // get data of selected applications, graph data for DNX chart, and visible applications
        const { data, applications } = getSelectedAppChartData(
          resData,
          globalFilter.sideBarTimeFilter,
          isCustomView,
          false,
          gFilter.globalV4PayloadSidebar
        );
        // check if the slections changed after calling the api
        const apps = isCustomView === true
          ? dataRef.current.currentSelections
            .filter(item => applications.includes(item))
          : applications;
        const seriesColors = [];
        applications.forEach((item, index) => {
          if (apps.includes(item))
            seriesColors.push(chartPrimaryColors[index]);
        });
        if (isCustomView === false) dataRef.current.selections = applications;
        // update state with new data
        Object.assign(dataRef.current, {
          data,
          visibleApps: applications,
          seriesColors,
        });
        setGraphData(data);
      }
      setLoader(false);
    }

  }, []);
  const customizeChart = useCallback(chart => {
    chart.series.values.forEach(series => {
      series.adapter.add("tooltipHTML", (html, target) => {
        const data = target.tooltipDataItem.dataContext;
        if (data && data.tooltipData) {
          const series = [...chart.series.values].reverse();
          html = `
            <div class="ttip-header">

            <span class="date">${data.tooltipData.date}</span>
            </div>
            <table class="ttip-content">
            ${series
              .map(item =>
                !item.isHidden
                  ? `
                  <tr>
                    <td>
                      <div class="flex-items">
                        <span
                          class="square-legend"
                          style="background-color:${item.fill.hex};"
                        ></span>
                        ${item.name}
                      </div>
                    </td>
                    <td>${(data.tooltipData[item.dataFields.valueY] !== undefined && data.tooltipData[item.dataFields.valueY] !== null)
                    ? data.tooltipData[item.dataFields.valueY]
                    : defaultTextValue
                  }</td>
                  </tr>
                  `
                  : ""
              )
              .join("")}
            </table>`;
        }
        return html;
      });
    });
    chart.yAxes.values[0].numberFormatter.numberFormat = "#.b";
    chart.yAxes.values[0].maxPrecision = 0;
    chart.yAxes.values[0].renderer.minGridDistance = 90;
  }, []);
  return (
    <div data-cy="selectedAppViewAppBandWidth">
      <div className="hbr-type-h3 trend-applicaiton-title">
        {i18n.selectedAppViewBandwidth}
      </div>
      <div className="trend-chart-container flex-column">
        {loader === true ? (
          <Spinner />
        ) : dataRef.current.error ? (
          <div className="error-container flex-column flex-main-content">
            <ErrorComponent
              {...dataRef.current.error}
              width="250px"
              className="super-small-dashlet-error"
            />
          </div>
        ) : graphData.length === 0 ? (
          <div className="no-data-flex flex-column flex-main-content">
            <NoDataAvailable text={i18n.applicationDashboardNoData} />
          </div>
        ) : (<>
          <StackedMultiLineChart
            id="circuit-bw"
            data={graphData}
            callbackFromParent={customizeChart}
            areaChart={false}
            colorset={dataRef.current.currentSelections.colorSeries}
            stacked={false}
            min={globalFilter.sideBarTimeFilter.current_period[0]}
            max={globalFilter.sideBarTimeFilter.current_period[1]}
            sidebar
          />
        </>
        )}
      </div>
    </div>
  );
};

Bandwidth.propTypes = {
  globalFilter: PropTypes.object,
  isCustom: PropTypes.bool
};

export default AppHOC(Bandwidth);
