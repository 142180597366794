import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

export const siteSummary = {
  data: [
    {
      property: "current",
      format: "percent",
      color: "#00A0D1"
    },
    {
      property: "change",
      format: "changePercent",
      theme: "changeQuality"
    }
  ],
  stats: [
    {
      key: "site_availability",
      title: i18nMessageBundle.summaryTitleSites,
      subTitle: i18nMessageBundle.summarysubTitleAvailability,
      tooltip: i18nMessageBundle.summaryTooltipAvail,
      tooltipKey: "overviewSummaryTooltip",
      subTitleToolTip: i18nMessageBundle.summarySubTitleTooltipAvail
    },
    {
      key: "application_vqoe_health",
      title: i18nMessageBundle.summaryTitleApplications,
      subTitle: i18nMessageBundle.summarysubTitleQoe,
      tooltip: i18nMessageBundle.summaryTooltipQoe,
      tooltipKey: "overviewSummaryTooltip",
      subTitleToolTip: i18nMessageBundle.summarysubTitleToolTipQoe
    },
    {
      key: "circuit_availability",
      title: i18nMessageBundle.summaryTitleCircuits,
      subTitle: i18nMessageBundle.summarysubTitleAvailability,
      tooltip: i18nMessageBundle.summaryTooltipAvail,
      tooltipKey: "overviewSummaryTooltip",
      subTitleToolTip: i18nMessageBundle.summarysubTitleToolTipAvailability
    }
  ]
};

export const singleSiteSummary = {
  data: [
    {
      property: "current",
      format: "percent",
      color: "#00A0D1"
    },
    {
      property: "change",
      format: "changePercent",
      theme: "changeQuality"
    }
  ],
  siteSummary: {
    key: "summary",
    title: i18nMessageBundle.applicationOverviewLabelSummaryCap,
    stats: [
      {
        key: "avg_availability",
        title: i18nMessageBundle.dashletSiteViewAvailabilityWith,
        subTitle: i18nMessageBundle.singleSiteSummarySubtitle,
        tooltip: i18nMessageBundle.singleSiteSummaryTooltip,
        tooltipKey: "avgAvailabilitySummary",
        subTitleToolTip:
          i18nMessageBundle.singleSiteAvailabilitySummarySubtitleTooltip
      },
      {
        key: "usage",
        title: i18nMessageBundle.dashletSiteViewSitesWith,
        subTitle: i18nMessageBundle.deviceUsage,
        tooltip: i18nMessageBundle.deviceUsageTooltip,
        tooltipKey: "usageSummary",
        subTitleToolTip: i18nMessageBundle.singleSiteUsageSummarySubtitleTooltip
      }
    ]
  },
  stats: [
    {
      key: "devices",
      title: i18nMessageBundle.applicationOverviewLabelDevices,
      subTitle: i18nMessageBundle.summarysubTitleAvailability,
      tooltip: i18nMessageBundle.summaryTooltipAvail,
      tooltipKey: "deviceSummary",
      subTitleToolTip: i18nMessageBundle.summaryDeviceSubTitleTooltipAvail
    },
    {
      key: "application_vqoe_health",
      title: i18nMessageBundle.summaryTitleApplications,
      subTitle: i18nMessageBundle.summarysubTitleQoe,
      tooltip: i18nMessageBundle.summaryTooltipQoe,
      tooltipKey: "overviewSummaryTooltip",
      subTitleToolTip: i18nMessageBundle.summarysubTitleToolTipQoe
    },
    {
      key: "circuit_availability",
      title: i18nMessageBundle.circuitViewCircuits,
      subTitle: i18nMessageBundle.summarysubTitleAvailability,
      tooltip: i18nMessageBundle.summaryTooltipAvail,
      tooltipKey: "overviewSummaryTooltip",
      subTitleToolTip: i18nMessageBundle.summarysubTitleToolTipAvailability
    }
  ]
};
