import React, { useState, useEffect, useMemo, useRef } from "react";
import { PropTypes } from "prop-types";
import AppHOC from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import Bandwidth from "./Bandwidth";
import { useMount } from "../../../../utils/genericCommon";
import {
  getApplicationsTableList
} from "../../../../utils/views/applications";
import apiService from "../../../../config/api-config";
import {
  getSelectedAppChartData,
  parseSelections
} from "../../../../config/apiParsers/applications";
import { getAppQoEData } from "../actions";
import i18n from "amdi18n-loader!../../../nls/i18n";
import css from "../trendAnalysisMagneticStyle.less";
import { chartPrimaryColors } from "../../../../utils/colors";
import OverlayedLineChart from "../../../../common/OverlayedLineChart";
import { getTrendInitialLoad, qoeRanges } from "../../../../utils/common";

const SelectedAppView = props => {
  const [qoeData, setQoEData] = useState({ loader: true, data: [] });
  const [initialLoad, setInitialLoad] = useState(true);
  const dataRef = useRef({
    timestamp: 0,
    selections: [],
    visibleApps: [],
    currentSelections: [],
    seriesColors: [],
    qoe: { error: null, data: [] }
  })
  const mount = useMount();
  useMemo(() => {
    const selections = Array.isArray(props.globalFilter.selected_apps)
      ? props.globalFilter.selected_apps
      : [];
    dataRef.current.currentSelections = selections;
  }, [props.globalFilter.selected_apps]);

  const getQoEData = async (gFilter, timestamp, selections) => {
    const res = await getAppQoEData(gFilter.globalV4PayloadSidebar, selections);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      let chartData = [];
      if (res.errorObject instanceof Object)
        dataRef.current.qoe.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        const { data, applications } = getSelectedAppChartData(
          res.data.data,
          gFilter.sideBarTimeFilter,
          true,
          true,
          gFilter.globalV4PayloadSidebar
        );
        const seriesColors = [];
        applications.forEach((item, index) => {
          if (dataRef.current.currentSelections.includes(item))
            seriesColors.push(chartPrimaryColors[index]);
        });
        Object.assign(dataRef.current, {
          visibleApps: applications,
          seriesColors,
        });
        dataRef.current.qoe.data = data;
        chartData = data;
      }
      setQoEData({ loader: false, data: chartData });
    }
  };

  const fetchData = selections => {
    if (getTrendInitialLoad(props.globalFilter, initialLoad)) {
      dataRef.current = {
        timestamp: Date.now(),
        selections: selections,
        visibleApps: [],
        currentSelections: selections,
        seriesColors: [],
        qoe: {
          error: null,
          data: []
        }
      };
      const loader = selections.length === 0 ? false : true;
      setQoEData({ loader, data: [] });
      if (loader === true)
        getQoEData(props.globalFilter, dataRef.current.timestamp, selections);
    }
    setInitialLoad(false);
  };

  const getData = globalFilter => {
    setQoEData({ loader: true, data: [] });
    const promisesQueue = [];
    const defaultRequest = { ...globalFilter.globalV4PayloadSidebar }
    defaultRequest.sort = { usage: "desc" }
    promisesQueue.push(apiService.getTableCustomList(defaultRequest));
    props.setSidebarTableData(undefined);
    Promise.all([...promisesQueue]).then(aggregateApplicationsResults => {
      if (mount.mounted === true) {
        props.setAggregateAppData(aggregateApplicationsResults[0]);
        const tableList = getApplicationsTableList(
          aggregateApplicationsResults[0].data
        );
        props.setSidebarTableData(tableList);
        const selectedAppNames = tableList
          .slice(0, 5).map(app => app.application);
        const topAppsByUsage = parseSelections(selectedAppNames);
        props.setSelectedAppList(topAppsByUsage);
        props.setSelectedAppListDefault(topAppsByUsage);
        fetchData(topAppsByUsage);
      }
    });
  };

  useEffect(() => {
    let selections = Array.isArray(props.globalFilter.selected_apps)
      ? props.globalFilter.selected_apps
      : [];
    if (
      props.isCustom === false &&
      Array.isArray(props.globalFilter.app_data_with_family_sla)
    ) {
      const initAppNames = props.globalFilter.app_data_with_family_sla
        .slice(0, 5).map(app => app.application);
      if (
        selections.length !== initAppNames.length
        || !selections.every(item => initAppNames.includes(item))
      ) {
        props.setSelectedAppList(initAppNames);
        props.setSelectedAppListDefault(initAppNames);
        selections = initAppNames;
      }
    }
    fetchData(selections);
  }, []);

  useEffect(() => {
    if (mount.initMount === false) {
      const selections = Array.isArray(props.globalFilter.selected_apps)
        ? props.globalFilter.selected_apps
        : [];
      if (props.isCustom === true) {
        // get visible applications from selected applications
        fetchData(selections);
      }
    }
  }, [props.globalFilter.selected_apps]);

  useEffect(() => {
    if (mount.initMount === false) {
      if (props.isCustom === false)
        getData(props.globalFilter);
    } else mount.initMount = false;
  }, [
    props.globalFilter.sideBarTimeFilter.current_period[0],
    props.globalFilter.sideBarTimeFilter.current_period[1]
  ]);

  return (
    <div className={css["selected-app-view"]} data-cy="selectedAppView">
      <div data-cy="selectedAppViewAppQoe">
        <div className="hbr-type-h3 trend-applicaiton-title">
          {i18n.selectedAppViewQoE}
        </div>
        {qoeData.loader === true ? (
          <div
            data-cy="spinner-selectedAppViewAppQoe"
            style={{ height: "200px" }}
          >
            <Spinner />
          </div>
        ) : dataRef.current.qoe.error ? (
          <div className="error_components">
            <ErrorComponent
              {...dataRef.current.qoe.error}
              width="110px"
              className="super-small-dashlet-error"
            />
          </div>
        ) : qoeData.data.length === 0 ? (
          <div className="error_components">
            <NoDataAvailable text={i18n.applicationDashboardNoData} />
          </div>
        ) : (
          <div className={css["overall-trend-qoeChart"]} >
            <OverlayedLineChart
              data={
                props.globalFilter.trend_analysis_table_filter === "unknown"
                  ? []
                  : qoeData.data
              }

              qualityRange={qoeRanges}
              chartPadding={{
                top: 15,
                left: 2,
                right: 30
              }}
              range={{
                yAxes: {
                  min: 0,
                  max: 10
                }
              }}
              xAxisConfig={{ dateX: "date" }}
              yAxisConfig={{
                valueY: dataRef.current.selections,
                yAxisminGridDistance: 80
              }}
              qoeRangeFill={true}
              min={props.globalFilter.sideBarTimeFilter.current_period[0]}
              max={props.globalFilter.sideBarTimeFilter.current_period[1]}
              sidebar
            />
          </div>
        )}
      </div>
      <Bandwidth isCustom={props.isCustom} />
    </div>
  );
};

SelectedAppView.propTypes = {
  globalFilter: PropTypes.object,
  setAggregateAppData: PropTypes.func.isRequired,
  setSelectedAppList: PropTypes.func.isRequired,
  setSelectedAppListDefault: PropTypes.func.isRequired,
  setSidebarTableData: PropTypes.func.isRequired,
  isCustom: PropTypes.bool
};

export default AppHOC(SelectedAppView);
