import React from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const [HbrTabGroup, HbrTab, HbrTag] = reactWrapper(["hbr-tab-group", "hbr-tab", "hbr-tag"]);

const TabList = ({ tabs, defaultTabValue, changeReportView }) => {
  const onTabChange = e => { 
    changeReportView(e.currentTarget.name);
  }; 
  return (
  <HbrTabGroup
    value={defaultTabValue}
    name="selected_tab"
    primary
    sentiment="success"
  >
      {tabs &&
        tabs.length > 0 &&
        tabs.map(({ name, label, count }) => (
          <HbrTab
            slot="nav"
            key={name}
            name={name}
            data-cy={name}
            active={name === defaultTabValue}
            onHbr-click={onTabChange}
          >
            {label}
            {count !== undefined ? <HbrTag>{count}</HbrTag> : null}
          </HbrTab>
        ))}
    </HbrTabGroup>
  );
};

TabList.propTypes = {
  tabs: PropTypes.array,
  defaultTabValue: PropTypes.string,
  changeReportView: PropTypes.func 
};

export default TabList;
