import { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import { am4core, am4charts } from "../../../../../loaders/amchartsLoader";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"; 
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n"; 
import { colors } from "../../../../../utils/colors"; 
import {
  customizeTooltip
} from "../../../../../utils/chart";
import { 
  shortDisplayDateForSdwan
} from "../../../../../utils/displayTime"; 
const ThreatStackedBarChart = props => { 
  const { chartsData } = props; 
  const chartsRef = useRef(chartsData);
  const chartPreSelected = useRef(); 
  
  const currentChartRef = useRef();
  am4core.useTheme(am4themes_animated);

  useEffect(() => {  
    chartsRef.current = props.chartsData; 
  }, [props.chartsData]);
 
  useEffect(() => {   
    chartPreSelected.current = props.selectedBar;  
  }, []);

  const createStackedChart = () => { 
          // Apply chart themes
      am4core.useTheme(am4themes_animated);      
      // Create chart instance
      var chart = am4core.create("chartsdwandiv", am4charts.XYChart);
      // Add data
      chart.data = props.data;
      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date"; 
      categoryAxis.renderer.grid.template.location = 0; 
      categoryAxis.renderer.labels.template.fontSize = 8;  
      categoryAxis.renderer.grid.template.strokeWidth = 0;  
      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.cursorTooltipEnabled = false; 
      //valueAxis.renderer.minGridDistance = 5;
      // convert decimal values to integer
      valueAxis.renderer.labels.template.adapter.add("text", function(text) {
        // Convert the text to a number and check if it's a decimal
        var value = parseFloat(text);
        if (Number.isInteger(value)) {
          // If it's an integer, return the original text
          return value;
        } else {
          // If it's a decimal, return an empty string to hide the label
          return "";
        }
      });  

      // function trigger when click on graph bar  
      const highlighColumn = (selectedDate) => {  
        chart.series.each(function(series) {
          if (series instanceof am4charts.ColumnSeries) {
            series.columns.each(function(column) {
              if(selectedDate) { 
                
                if(column._dataItem._dataContext.entry_ts !== selectedDate) { 
                  column.setState("default");
                  column.isActive = false;
                } else {  
                  column.isActive = true;
                  column.fillOpacity = 1;
                }
              }
            })
          }
        })
      }

      // function trigger when mouse over on graph bar 
      const hoverColumn = (selectedDateHover) => { 
        chart.series.each(function(series) {
          if (series instanceof am4charts.ColumnSeries) {
            series.columns.each(function(column) {
              if(selectedDateHover) { 
                if(column._dataItem._dataContext.entry_ts === selectedDateHover) {
                  column.isActive = true;
                  column.fillOpacity = 1;
                }  
              }
            })
          }
        })
      }

      // function trigger when mouse focus out on graph bar 
      const hoverOutColumn = (selectedDateHover) => {
        chart.series.each(function(series) {
          if (series instanceof am4charts.ColumnSeries) {
            series.columns.each(function(column) {
              if(selectedDateHover) {  
                if(column._dataItem._dataContext.entry_ts === selectedDateHover && 
                  column._dataItem._dataContext.entry_ts !== chartPreSelected.current) {
                  column.setState("default");
                  column.isActive = false;
                }  
              }
            })
          }
        })
      }
      
      // Create series
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "client_ip_access_count";
      series.dataFields.categoryX = "date"; 
      series.tooltipText = "{name}: [bold]{valueY}[/]"; 
      //series.stacked = true;  
      series.fill = colors.indigo60;
      series.fillOpacity = 0.8;
      series.strokeWidth = 0;  

      var columnTemplate = series.columns.template;
      columnTemplate.width = am4core.percent(70);
      columnTemplate.height = 8;
      columnTemplate.fillOpacity = .2;

      var hs = columnTemplate.states.create("active");
      hs.properties.fillOpacity = 1;
      setTimeout(() => {
        // customize tooltip
        customizeTooltip(series.tooltip); 
        
      
      },500); 

      columnTemplate.events.on("hit", function hit(ev) { 
        let selectedDate =  ev.target.column._dataItem._dataContext.entry_ts; 
        chartPreSelected.current = selectedDate;  
        props.callThreadDetailApi(selectedDate);
        highlighColumn(selectedDate);
      });

      columnTemplate.events.on("over", function hit(ev) { 
        let moverHoverDataColumn =  ev.target.column._dataItem._dataContext.entry_ts;         
        hoverColumn(moverHoverDataColumn);
        
          series.tooltipHTML= `<div class="thread-chart">
          <div class="thread-tooltip-unique-count">${i18nMessageBundle.securityTabView.uniqueSourceCount}</div> 
          <div class="thread-tooltip-date">${shortDisplayDateForSdwan(ev.target.column._dataItem._dataContext.entry_ts)} ${i18nMessageBundle.securityTabView.UTC}</div>
          <div class="thread-tooltip-thread-count">
          <span class='dot' style='background-color:${
            colors.indigo60
            }'></span>${ev.target.column._dataItem._dataContext.client_ip_access_count}</div>
        </div>`; 
      });
      columnTemplate.events.on("out", function hit(ev) {  
        let moverHoverOutDataColumn =  ev.target.column._dataItem._dataContext.entry_ts;  
        hoverOutColumn(moverHoverOutDataColumn); 
      });
      series.events.on("ready", () => {  
        setTimeout(() => { 
          highlighColumn(chartPreSelected.current);
        },500); 
      }) 
     chart.cursor = new am4charts.XYCursor();
     chart.cursor.lineY.disabled = true;
     chart.cursor.lineX.disabled = true; 
    return chart;
  } 
  useEffect(() => {
    if (currentChartRef.current) {
      currentChartRef.current.dispose();
    }
   // let chart2Obj = createStackedChart(chartId, props.data);
    let chart2Obj = createStackedChart();

    return () => {
      chart2Obj.dispose();
      chart2Obj.events.disable();
    };
  }, [props.data]);

  useEffect(() => {
    return () => {
      if (currentChartRef.current) {
        currentChartRef.current.dispose();
      }
      am4core.disposeAllCharts();
    };
  }, []);

 
  return (
    <div>
      <div
        id={'chartsdwandiv'}
        className="bulletColumnAndLineChart"
        style={{
          height: '24rem',
          paddingTop: props.isTitleShown ? "0px" : "55px",
          ...props.chartProps.backgroundStyle
        }}
      ></div>
    </div>
  );
};

ThreatStackedBarChart.propTypes = {
  chartsData: PropTypes.array.isRequired,
  chartProps: PropTypes.object.isRequired,
  isTitleShown: PropTypes.bool,
  data: PropTypes.array,
  disabledScrollbar: PropTypes.bool,
  saveData: PropTypes.func,
  callThreadDetailApi: PropTypes.func,
  selectedBar: PropTypes.string
};

export default ThreatStackedBarChart;
