import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import DashletTable from "../../../common/DashletTable";
import {
  isCustom,
  getReportTimePeriod,
  getLocalStorageFlag
} from "../../../utils/common";
import { checkCookieValidity } from "../../../utils/waniCommon";
import { getThemeColors } from "../../../utils/colors";
import timeFilterConfig from "../../../generics/config/time-filter-config";
import {
  columnsClientsName,
  getSourceClients
} from "./config/table/harborTableHeaderConfig";
import clientTableConfig from "./config/table/userBandwithTableConfig";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../overviewMagneticStyle.less";

const [HbrCard] = reactWrapper(["hbr-card", "hbr-tooltip"]);

const DashletUserView = ({
  globalFilter,
  overview,
  actions,
  isReporting,
  reportData
}) => {
  const {
    timePeriodSelected,
    timeFilter,
    currentTimeStamp,
    selectedSite,
    selectedCustomTime,
    selectedOverlay
  } = globalFilter;
  const [currentPeriod, setCurrentPeriod] =
    useState('null');
  const [prevPeriod, setPrevPeriod] = useState(timeFilter.current_period[1]);
  const { userSummary, errorUser } = overview;
  const reportRange = reportData
    ? getReportTimePeriod(reportData.time_range)
    : timePeriodSelected;

  const updateDashletFilter = () => {
    const { setDashletFilter, setDashletLoading } = actions;
    setDashletLoading("USER");
    setDashletFilter("USER");
  };

  const [isOverviewPage, setIsOverviewPage] = useState(false);
  const updateView = () => {
    const { getTopUsers } = actions;
    getTopUsers(globalFilter.globalV4Payload, setIsOverviewPage);
  };

  useEffect(() => {
    if (
      !sessionStorage.getItem("isGetInfoApiCalled")
      && !isCustom(timePeriodSelected)
    ) updateDashletFilter();
  }, []);

  useEffect(() => {
    const currentOverlay = getLocalStorageFlag("currentOverlay");
    const isCrossLaunch = checkCookieValidity("cl-rec-apply-url");

    if (currentOverlay && (globalFilter.selectedOverlay === currentOverlay || isReporting || isCrossLaunch)) {
      const { setDashletLoading } = actions;
      if (!(isCustom(timePeriodSelected)) ||
        !(currentPeriod === timeFilter.current_period[0]) ||
        !(prevPeriod === timeFilter.current_period[1])
      ) {
        if (!(currentPeriod === timeFilter.current_period[0]))
          setCurrentPeriod(timeFilter.current_period[0]);
        if (!(prevPeriod === timeFilter.current_period[1]))
          setPrevPeriod(timeFilter.current_period[1])
        setDashletLoading("USER");
        if(!isOverviewPage) updateView();
        if(selectedSite == -1) setIsOverviewPage(true);
      } else if (
        (!(
          selectedCustomTime?.range?.start
          && selectedCustomTime?.range?.end
        ) && selectedSite > 0) || (isCustom(timePeriodSelected) && selectedSite && selectedCustomTime?.range)
      ) {
        setDashletLoading("USER");
        updateView();
      }
    }
  }, [
    selectedOverlay,
    timePeriodSelected,
    timeFilter.current_period[0],
    timeFilter.current_period[1],
    currentTimeStamp,
    selectedSite
  ]);

  const getChartTooltipConfig = () => ({
    labels: [
      { key: "current", label: i18n.applicationOverviewLabelUsage },
      {
        key: "previous",
        label: `${i18n.applicationOverviewLabelUsageIn
          } ${timeFilterConfig[timePeriodSelected].toolTip}`
      }
    ],
    colors: getThemeColors(clientTableConfig.legend.theme),
    type: "bytes",
    format: "GB",
    decimal: 0,
    width: "300"
  });

  return (
    <HbrCard
      className={css[isReporting ? "user-dashlet-reporting" : "user-dashlet"]}
      data-cy="user-dashlet"
      container={true}
    >
      <div className="user-dashlet-view" data-cy="user-view">
        {!isReporting && <div className="title-block">
          <div className="header-content hbr-type-h2">
            {i18n.clientsTitle}
          </div>
        </div>}
        {overview.userSummaryLoader ? (
          <div
            className="spinner-component-clients-view"
            data-cy="spinner-componen-clients-view"
          >
            <Spinner />
          </div>
        ) : errorUser ? (
          <ErrorComponent
            {...errorUser}
            width="210px"
            className="small-dashlet-error"
          />
        ) : (
              <div className="content-block">
                {clientTableConfig && userSummary.usage && (
                  <div className="table-view">
                    <div className="title-clients-dashlet hbr-type-h3">
                      {isReporting ? i18n.topClient : i18n.topClients}
                    </div>
                    <DashletTable
                      config={clientTableConfig}
                      data={userSummary.usage}
                      timeFilterType={
                        reportData ? reportRange : timePeriodSelected
                      }
                      chartTooltip={true}
                      chartConfig={getChartTooltipConfig()}
                      source={getSourceClients([])}
                      columnsName={columnsClientsName}
                      style={{ height: "40px", width: "530px" }}
                    />
                  </div>
                )}
              </div>
            )}
      </div>
    </HbrCard>
  );
};

DashletUserView.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  overview: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isReporting: PropTypes.bool,
  reportData: PropTypes.object
};

const mapStateToProps = state => ({
  overview: state.vanalytics.overview
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(DashletUserView))
);