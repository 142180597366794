import { createContext } from "@cisco-dna/redux-utils";
import {
  parseApplSummary,
  parseUserSummary
} from "../../config/apiParsers/overview";
import { ACTIONS, TYPE } from "./actions";

const reduxContext = createContext();
const keyStore = "vanalytics.overview";

export default reduxContext;

const initialState = {
  deviceSummaryLoader: true,
  filterViewLoaderApp: true,
  filterViewLoaderDevice: true,
  userSummaryLoader: true,
  siteLoaderDonut: true,
  siteLoaderStacked: true,
  appLoaderStacked: true,
  appLoaderDonut: true,
  dashletFilter: {
    app: {
      type: "all",
      view: "qoe"
    },
    site: {
      view: "avail"
    },
    user: {}
  }
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIONS.SET_FILTERVIEW_APP:
      return {
        ...state,
        filterViewApp: payload,
        filterViewLoaderApp: false,
        errorFilterViewApp: null
      };
    case ACTIONS.SET_APPLICATION_DONUT:
      return {
        ...state,
        appDonut: parseApplSummary(payload),
        appLoaderDonut: false,
        errorAppDonut: null
      };
    case ACTIONS.SET_APPLICATION_STACKED:
      return {
        ...state,
        appStacked: parseApplSummary(payload),
        appLoaderStacked: false,
        errorApp: null
      };
    case ACTIONS.SET_USER_SUMMARY:
      return {
        ...state,
        userSummary: parseUserSummary(payload),
        userSummaryLoader: false,
        errorUser: null
      };
    case ACTIONS.SET_DASHLET_FILTER: {
      if (payload.type === TYPE.APP) {
        return {
          ...state,
          dashletFilter: Object.assign({}, state.dashletFilter, {
            app: payload.filter
          })
        };
      } else if (payload.type === TYPE.USER) {
        return {
          ...state,
          dashletFilter: Object.assign({}, state.dashletFilter, {
            user: payload.filter
          })
        };
      } else if (payload.type === TYPE.SITE) {
        return {
          ...state,
          dashletFilter: Object.assign({}, state.dashletFilter, {
            site: payload.filter
          })
        };
      }
      break;
    }
    case ACTIONS.SET_DASHLET_LOADING: {
      if (payload === TYPE.APP) {
        return {
          ...state,
          appLoaderDonut: true,
          appLoaderStacked: true
        };
      } else if (payload === TYPE.USER) {
        return {
          ...state,
          userSummaryLoader: true
        };
      } else if (payload === TYPE.SITE) {
        return {
          ...state,
          siteLoaderStacked: true,
          siteLoaderDonut: true,
          deviceSummaryLoader: true
        };
      } else if (payload === TYPE.FILTER) {
        return {
          ...state,
          filterViewLoaderApp: true,
          filterViewLoaderDevice: true
        };
      }
      break;
    }
    case ACTIONS.SET_ERROR: {
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: keyStore,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_FILTERVIEW_APP,
    ACTIONS.SET_APPLICATION_STACKED,
    ACTIONS.SET_APPLICATION_DONUT,
    ACTIONS.SET_USER_SUMMARY,
    ACTIONS.SET_DASHLET_FILTER,
    ACTIONS.SET_DASHLET_LOADING,
    ACTIONS.SET_ERROR
  ]
});
