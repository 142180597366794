import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import reduxContext from "../reducer";
import * as actions from "../actions";
import css from "../trendAnalysisMagneticStyle.less";

import AppHoc from "../../../../generics/AppHOC";
import TimeFilter from "../../../../generics/TimeFilter";
import TimeStamp from "../../../../generics/TimeStamp";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
  trend: state.vanalytics.trend
});

class FilterView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css.ctrlDashlet} data-cy="trendFilter">
        <div className="custom-actions">
          <div className="time-filter application-trend-timefilter">
            <TimeFilter sideBar={true} />
          </div>
          <div className="hbr-type-body4 application-trend-time-stamp">
            <TimeStamp sideBar={true}  hideCompareText={true} {...this.props} />
          </div>
        </div>
        <div className="hbr-type-h1 application-trend-title">
          {i18nMessageBundle.overallTrendViewOverall}
        </div>
      </div>
    );
  }
}
export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHoc(FilterView))
);
