export const ACTIONS = {
  API_ERROR: "API_ERROR",
  SET_REPORT_OPTIONS: "SET_REPORT_OPTIONS"
};

export const setReportOptions = payload => dispatch => {
  dispatch({
    type: ACTIONS.SET_REPORT_OPTIONS,
    payload: payload
  });
};
export const setReportApiError = payload => dispatch => {
  dispatch({
    type: ACTIONS.API_ERROR,
    payload: payload
  });
};
