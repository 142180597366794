import React, { useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

import css from "../../reportsMagneticStyle.less";
import * as apiConstant from "../../../../apis/apiConstants";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";

import imgCsv from "../../../../assets/file-csv.svg";
import imgPdf from "../../../../assets/file-pdf.svg";

import AppHoc from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import reduxContext from "../reducer";
import * as actions from "../actions";
import { removeLocalStorageFlag } from "../../../../utils/common";

const [HbrCard] = reactWrapper([
  "hbr-card"
]);

const mapStateToProps = state => {
  return {
    reports: state.vanalytics.reports
  };
};
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const ReportTemplates = props => {
  const { actions, reports, globalFilter } = props;
  const { selectedOverlay, currentTimeStamp } = globalFilter;

  const { reportTemplates, reportTemplateLoading } = reports;

  useEffect(() => {
    const { getReportTemplates } = actions;
    removeLocalStorageFlag("workflow");
    getReportTemplates();
  }, [selectedOverlay, currentTimeStamp && currentTimeStamp]);

  if (reportTemplateLoading) {
    return <Spinner />;
  } else {
    if (reportTemplates && reportTemplates.length > 0) {
      return (
        <div className={css["report-template"]}>
            {reportTemplates.map((reportTemplate, index) => {
              const {
                templateId,
                template_name,
                include_reports,
                file_type,
                template_uuid
              } = reportTemplate;

              const cardName = `card${templateId}`;
              const includeReports = include_reports.split(",");

              return (
                <div key={index}>
                  <HbrCard name={cardName} data-cy={template_name}>
                    <div slot="header">
                      <div className="reports-title-icons-container">
                        <div role="heading" aria-level={3}>
                        {template_name}
                        </div>
                        <div className="reports-icons">                        
                          {file_type && file_type === "pdf" && (
                            <img src={imgPdf} alt="PDF File" />
                          )}
                          {file_type &&
                            file_type === "pdf" &&
                            template_name != "Executive Summary" && (
                              <img src={imgCsv} alt="CSV File" />
                            )}
                          {file_type &&
                            file_type === "pdf" &&
                            template_name != "Executive Summary"}
                        </div>      
                      </div>
                      <div role="heading" aria-level={4} className="reports-list">
                      <ul>
                        { includeReports &&
                          includeReports.length > 0 &&
                          includeReports.map((report, idx) => (
                            <li key={idx}>{report}</li>
                        ))} 
                      </ul>
                      </div>
                    </div>
                    <div>
                    <Link className="hbr-type-link"
                            data-cy={`Generate ${template_name} btn`}
                            to={{
                              pathname: `${apiConstant.REPORT_SCOPE_PAGE_URL}`,
                              query: {
                                pageName: template_name,
                                id: { template_uuid }
                              }
                            }}
                          >
                            {i18nMessageBundle.reportTemplatesGenerate}
                          </Link>
                    </div>
                  </HbrCard>
                </div>
              );
            })}
        </div>
      );
    } else {
      if (reportTemplates && reportTemplates.length === 0) {
        return <NoDataAvailable />;
      } else {
        return <Spinner />;
      }
    }
  }
};

ReportTemplates.propTypes = {
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(memo(AppHoc(ReportTemplates)))
);
