import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export default {
  title: i18nMessageBundle.UserTableHeadingTopClients,
  subTitle: i18nMessageBundle.clientsTitle,
  legend: {
    theme: "overlay",
    labels: [
      i18nMessageBundle.applicationOverviewUsed,
      i18nMessageBundle.applicationCircuitViewLabelPrevious
    ]
  },
  columns: [
    {
      title: i18nMessageBundle.clientSitesColHeader,
      colSpan: 1,
      property: "site_name",
      type: "string",
      format: "link",
      maxLength: 15,
      width: "40px"
    },
    {
      title: i18nMessageBundle.clientIpColHeader,
      colSpan: 1,
      property: "source_ip",
      type: "string",
      format: "link",
      maxLength: 15,
      width: "105px"
    },
    {
      title: i18nMessageBundle.clientsTopAppsColHeader,
      colSpan: 1,
      property: "top_used_apps",
      type: "string",
      format: "array",
      delimiter: " | ",
      maxLength: 12,
      width: "120px"
    },
    {
      title: i18nMessageBundle.userDataUsage,
      colSpan: 1,
      property: "usage",
      theme: "changeQuality",
      type: "bytes",
      format: "GB",
      decimal: 0,
      comboUsageChange: true,
      width: "140px",
      padding: "0px",
    },
    {
      title: i18nMessageBundle.clientDistributionColHeader,
      colSpan: 1,
      property: "chart",
      type: "chart",
      format: "overlayBarChart",
      width: "25%",
      padding: "0px"
    }
  ]
};
