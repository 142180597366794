import { createContext } from "@cisco-dna/redux-utils";
import { ACTIONS } from "./actions";
import {
  parseApplSummary,
  parseUserSummary
} from "../../../../config/apiParsers/overview";

const reduxContext = createContext();

export default reduxContext;

const storeKey = "vanalytics.executive_summary";

const initialState = {
  reportData: null,
  reportLoader: true,
  overallSummary: {},
  overallSummaryLoader: true,
  topUsersLoader: true,
  topUsers: {},
  appsAscLoader: true,
  appsAsc: {},
  appsDescLoader: true,
  appsDesc: {},
  appsDonutLoader: true,
  appsDonut: {}
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.SET_REPORT_LOADING:
      return {
        ...state,
        reportLoader: true
      };
    case ACTIONS.SET_REPORT_DATA:
      return {
        ...state,
        reportData: payload,
        reportLoader: false
      };
    case ACTIONS.SET_OVERALL_SUMMARY:
      return {
        ...state,
        overallSummary: payload,
        overallSummaryLoader: false
      };
    case ACTIONS.SET_APPS_DONUT:
      return {
        ...state,
        appsDonut: parseApplSummary(payload),
        appsDonutLoader: false
      };
    case ACTIONS.SET_APPS_STACKED_ASC:
      return {
        ...state,
        appsAsc: parseApplSummary(payload),
        appsAscLoader: false
      };
    case ACTIONS.SET_APPS_STACKED_DESC:
      return {
        ...state,
        appsDesc: parseApplSummary(payload),
        appsDescLoader: false
      };
    case ACTIONS.SET_TOP_USERS:
      return {
        ...state,
        topUsers: parseUserSummary(payload),
        topUsersLoader: false,
        topUsersError: false
      };
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

reduxContext.injectReducer({
  STORE: storeKey,
  REDUCER: reducer,
  ACTION_TYPE: [
    ACTIONS.SET_REPORT_LOADING,
    ACTIONS.SET_REPORT_DATA,
    ACTIONS.SET_OVERALL_SUMMARY,
    ACTIONS.SET_APPS_STACKED_ASC,
    ACTIONS.SET_APPS_STACKED_DESC,
    ACTIONS.SET_APPS_DONUT,
    ACTIONS.SET_TOP_USERS,
    ACTIONS.SET_ERROR
  ]
});
