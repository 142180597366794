import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { PropTypes } from "prop-types";
import _ from "lodash";

import css from "../../../reportsMagneticStyle.less";
import AppHocReport from "../../../../../generics/AppHOCReport";
import { DnxButton } from "../../../../../loaders/DNXLoader";
import {
  PAGE,
  getPageFromStep,
  initGlobalState
} from "../../../../../utils/enums";
import {
  getStepFromPath,
  isGenerateReportWorkflow,
  setLocalStorageFlag,
  removeLocalStorageFlag
} from "../../../../../utils/common";
import * as apiConstant from "../../../../../apis/apiConstants";
import reduxContext from "../../../../../generics/AppHOCReport/reducer";
import { setWorkflow } from "../../../../../generics/AppHOCReport/actions";
import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";
import ApiService from "../../../../../config/api-config";
import reactWrapper from "@harbor/elements/utils/react/wrapper";


const DashletFooter = () => {
  const workflow = localStorage.getItem("workflow");
  const workflowState = !_.isEmpty(workflow)
    ? JSON.parse(workflow)
    : { step: 1 };
  const { step } = workflowState;
  const history = useHistory();
  const dispatch = useDispatch();
  const stepFromPathName = getStepFromPath(location);
  const [alertFlag, setAlertFlag] = useState();
  const [HbrLink, HbrIcon, HbrModal, HbrButton] = reactWrapper(["hbr-link", "hbr-icon", "hbr-modal", "hbr-button"]);
  const refModal = useRef(null);

  const overlayDismiss = event => {
    event.preventDefault();
  };

  const onClickCancel = () => {
    refModal.current.element.hide();
  };

  const _onExit = () => {
    setAlertFlag(
      <HbrModal
          ref={refModal}
          open={true}
          onHbr-overlay-dismiss={overlayDismiss}
          id="report-workflow-exit-modal"
        >
          <div slot="label">
            <HbrIcon
              name="alerts-warning-large"
              sentiment="warning"
            ></HbrIcon>{" "}
            {i18nMessageBundle.workflowExiting}
          </div>
          {i18nMessageBundle.worklfowChanges}
          <div slot="footer">
            <HbrButton
              variant="text"
              style={{ marginRight: "16px" }}
              onClick={onClickCancel}
              name="cancel"
            >
              {"Cancel"}
            </HbrButton>
            <HbrButton
              variant="fill"
              onClick={e => onClose(e)}
              name="exit"
            >
              {"Exit"}
            </HbrButton> 
          </div>
        </HbrModal>
    );
  };

  /**
   * @description update the workflow object with properties such as step, and isNextDisabled
   * and navigate to the next page if conditions such as report name are valid
   */
  const visitNext = async () => {
    const workflowState = JSON.parse(localStorage.getItem("workflow"));
    const { step, reportName, overlayId } = workflowState;
    const updatedWorkflow = {
      ...workflowState,
      ...{ step: step + 1, isNextDisabled: false }
    };

    if (step < 5) {
      if (isGenerateReportWorkflow(location.pathname)) {
        const result = await ApiService.validateReportName(
          reportName,
          overlayId
        );
        //Validate the report name and move to next step
        if (_.isEmpty(result.data)) {
          saveState(updatedWorkflow);
          history.push(getPageFromStep[step + 1]);
        } else {
          saveState({ isNextDisabled: true, isReportNameValid: true });
        }
      } else {
        saveState(updatedWorkflow);
        history.push(getPageFromStep[step + 1]);
      }
    } else if (step === 5) {
      saveState({ isNextDisabled: true });
    }
  };

  const visitPrev = () => {
    saveState({
      step: step - 1,
      isNextDisabled: false,
      isReportNameValid: false
    });
    history.goBack();
  };

  const onClose = event => {
    if (event.target.name === "exit") {
      setLocalStorageFlag("isExitWorkflow", true);
      dispatch(setWorkflow(initGlobalState));
      removeLocalStorageFlag("workflow");
      removeLocalStorageFlag("isExitWorkflow");
      history.push(`${apiConstant.REPORTS_URL_HOME}`);
    } else {
      setAlertFlag(<span style={{ display: "none" }} />);
    }
  };

  const saveState = updatedWorkflow => {
    localStorage.setItem("workflow", JSON.stringify(updatedWorkflow));
    dispatch(setWorkflow(updatedWorkflow));
  };

  return (
    <div className={css["app-footer"]}>
      {alertFlag}
      <div className="exit-btn" onClick={_onExit}>
        <HbrLink target="_parent">
          {i18nMessageBundle.worklfowExit}
        </HbrLink>
      </div>
      <div className="group-btn">
        {getPageFromStep[stepFromPathName] !== PAGE.SCOPE && (
          <div className="each-btn">
            <DnxButton
              name="previous"
              label={i18nMessageBundle.worklfowPrevious}
              flavor="secondary"
              click={visitPrev}
            />
          </div>
        )}
        <div className="each-btn">
          <DnxButton
            name="next"
            data-cy="nextBtn"
            label={
              getPageFromStep[stepFromPathName] !== PAGE.SUMMARY
                ? i18nMessageBundle.worklfowNext
                : i18nMessageBundle.worklfowSubmit
            }
            disabled={
              _.isEmpty(workflowState) ? true : workflowState.isNextDisabled
            }
            flavor="primary"
            click={visitNext}
          />
        </div>
      </div>
    </div>
  );
};

DashletFooter.propTypes = {
  globalReport: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect()(AppHocReport(DashletFooter))
);
