import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

import AppHOC from "../../../generics/AppHOC";
import Spinner from "../../../common/Spinner";
import ErrorComponent from "../../../common/ErrorComponent";
import DashletTable from "../../../common/DashletTable";
import { getThemeColors } from "../../../utils/colors";
import { getDonutText, getDonutData } from "../../../utils/views/overview";
import i18n from "amdi18n-loader!../../nls/i18n";
import css from "../overviewMagneticStyle.less";
import {
  getLocalStorageFlag,
  setLocalStorageFlag
} from "../../../utils/common";
import availTableConfig from "./config/table/circuitAvailTableConfig";
import availDonutConfig from "./config/donut/circuitAvailDonutConfig";
import timeFilterConfig from "../../../generics/config/time-filter-config";
import { health } from "../../circuits/common/masterTableUtil";
import { defaultCellValue } from "../../../utils/enums";
import HarborDonut from "../../../common/HbrDonut";
import QoeListComponent from "../../../common/QoeListComponent";
import { COLOR } from "../../../utils/enums";
import {
  columnsCircuitName,
  getSourceCircuit
} from "./config/table/harborTableHeaderConfig";
import { BASE_URL } from "../../../apis/apiConstants";

const MAX_ROWS = 5;

const [HbrButton, HbrCard] = reactWrapper(["hbr-button", "hbr-card"]);

const CircuitView = ({ history, globalFilter, reporting, reportData }) => {
  const [loader, setLoader] = useState(true);
  const [selectedSiteId, setselectedSiteId] = useState(
    globalFilter.selectedSite
  );
  const [tableData, setTableData] = useState([]);
  const availableFeatures = JSON.parse(
    getLocalStorageFlag("availableFeatures")
  );
  const dataRef = useRef({
    donut: { good: 0, fair: 0, bad: 0, unknown: 0 },
    donutThemecolors: getThemeColors(availDonutConfig.theme),
    donutData: [],
    donutText: [],
    tableConfig: availTableConfig,
    tableTopConfig: { ...availTableConfig, title: i18n.topAvailableCircuits },
    chartTooltipConfig: {
      labels: [
        {
          key: "current",
          label: i18n.circuitViewAvailability
        },
        {
          key: "previous",
          label: ""
        }
      ],
      colors: getThemeColors(availTableConfig.legend.theme),
      type: "number",
      format: "percent"
    },
    topCircuits: []
  });

  useEffect(() => {
    if (!reporting)
      dataRef.current.chartTooltipConfig.labels[1].label =
        `${i18n.applicationOverviewLabelAvailabilityOn} ` +
        timeFilterConfig[globalFilter.timePeriodSelected].toolTip;
  }, [globalFilter.timePeriodSelected]);

  useEffect(() => {
    if (
      (!reporting && globalFilter.circuitsMasterTable.loader) ||
      (reporting && reportData.loader)
    ) {
      setLoader(true);
      return;
    }
    const circuitsData = reporting
      ? reportData
      : globalFilter.circuitsMasterTable;
    if (reporting) dataRef.current.error = reportData.error;
    else dataRef.current.error = circuitsData.error;
    if (dataRef.current.error) {
      setLoader(false);
      return;
    }
    Object.assign(dataRef.current.donut, {
      good: 0,
      fair: 0,
      bad: 0,
      unknown: 0
    });
    let data = circuitsData.data;
    if (data.length > 0) {
      let good = 0;
      let fair = 0;
      let bad = 0;
      let unknown = 0;
      const siteId = globalFilter.selectedSite;
      if (siteId !== -1) data = data.filter(item => item.site_id === siteId);
      data.forEach(item => {
        const availability = parseInt(item.availability);
        if (availability < 80) bad++;
        else if (availability < 95) fair++;
        else if (item.health === health.gray) unknown++;
        else good++;
      });
      Object.assign(dataRef.current.donut, { good, fair, bad, unknown });
    }
    dataRef.current.donutData = getDonutData(
      availDonutConfig.text,
      dataRef.current.donut
    );
    dataRef.current.donutText = getDonutText(
      availDonutConfig.title,
      dataRef.current.donut
    );
    data = data.filter(item => item.health !== health.gray);
    const tableRecords = data.slice(0, MAX_ROWS);
    const circuitTableData = tableRecords.map(item => ({
      ...item,
      availability_previous:
        item.availability_previous === defaultCellValue ? 0 : item.availability_previous,
      availability:
        item.availability === defaultCellValue ? 0 : item.availability,
      tooltip: {
        name: item.circuit_display_name,
        current: item.availability === defaultCellValue ? 0 : item.availability,
        previous: item.availability_previous === defaultCellValue ? 0 : item.availability_previous
      },
      overlay: {
        current: {
          value: item.availability === defaultCellValue ? 0 : item.availability
        },
        previous: { value: item.availability_previous === defaultCellValue ? 0 : item.availability_previous},
        total: { value: 100 }
      }
    }));
    if (reporting)
      dataRef.current.topCircuits = data.slice(-MAX_ROWS).map(item => ({
        ...item,
        availability:
          item.availability === defaultCellValue ? 0 : item.availability,
        overlay: {
          current: {
            value:
              item.availability === defaultCellValue ? 0 : item.availability
          },
          previous: { value: item.availability_previous === defaultCellValue ? 0 : item.availability_previous},
          total: { value: 100 }
        }
      }));
    availTableConfig.columns[1].tooltip = !reporting;
    setTableData(circuitTableData);
    setLoader(false);
  }, [
    globalFilter.selectedSite,
    globalFilter.circuitsMasterTable.loader,
    reportData.loader
  ]);

  useEffect(() => {
    setselectedSiteId(globalFilter.selectedSite);
  }, [globalFilter.selectedSite]);

  useEffect(() => {
    if (!reporting)
      dataRef.current.tableConfig.columns[0].onClick = (
        circuitName,
        circuit
      ) => {
        sessionStorage.setItem(
          "circuit",
          JSON.stringify({
            ...circuit,
            overlay: localStorage.getItem("currentOverlay")
          })
        );
        const joinedCircuitName = circuitName.split(":").join("~");
        history.push(`${BASE_URL}/circuits/${joinedCircuitName}`);
      };
  }, []);

  const setBandwithSelected = () => {
    setLocalStorageFlag("bandForecastingTab", true);
    history.push(`${BASE_URL}/predictivenetworks`);
  };
  return (
    <HbrCard
      className={css[reporting ? "circuit-dashlet-reporting" : "circuit-dashlet"]}
      data-cy="circuit-card-view"
      container={true}
    >
      <div className="circuit-dashlet-view" data-cy="circuit-view">
        {!reporting && (
          <div className="title-block">
            <div className="header-circuit-content hbr-type-h2">
              {i18n.circuitViewCircuits}
            </div>
            <div>
              {availableFeatures?.bwf && (
                <HbrButton variant="text"
                  onClick={setBandwithSelected}
                  style={{ paddingRight: "var(--hbr-spacing-xl)" }}>
                  {i18n.circuitViewBandwidthForecast}
                </HbrButton>
              )}
              <HbrButton variant="text" onClick={() => history.push(`${BASE_URL}/circuits`)}>
                {i18n.circuitViewDetails}
              </HbrButton>
            </div>
          </div>
        )}
        {loader ||
        selectedSiteId !== globalFilter.selectedSite ||
        (reporting && reportData.loader) ||
        (!reporting && globalFilter.circuitsMasterTable.loader) ? (
          <div
            className="spinner-component-circuit-view"
            data-cy="spinner-component-circuit-view"
          >
            <Spinner />
          </div>
        ) : dataRef.current.error ? (
          <ErrorComponent
            {...dataRef.current.error}
            width={"210px"}
            className={
              reporting ? "one-line-dashlet-error" : "small-dashlet-error"
            }
          />
        ) : (
          <div className="content-block">
            <div className={css["custom-legend-list"]}>
              {reporting && (
                <div className="title-block hbr-type-h3">
                  {i18n.circuitAvailabilityBreakdown}
                </div>
              )}
              <div>
                <HarborDonut
                  data={dataRef.current.donutData}
                  donutLabel={i18n.circuitsLabel}
                />
                <div className="legend-circuit-view">
                  <QoeListComponent
                    overviewPage={true}
                    iconType={[COLOR.GOOD, COLOR.FAIR, COLOR.POOR, COLOR.GRAY]}
                    qoeScore={[0, 0, 0, 0]}
                    qoeLabels={[
                      availDonutConfig.text.good.title,
                      availDonutConfig.text.fair.title,
                      availDonutConfig.text.bad.title,
                      availDonutConfig.text.unknown.title
                    ]}
                    tooltipText={i18n.circuit.unknownAvailTtip}
                    position={3}
                  />
                </div>
              </div>
            </div>
            {reporting && (
              <div className="table-chartview">
                <div className="title-circuit-dashlet hbr-type-h3">
                  {i18n.topAvailableCircuits}
                </div>
                <DashletTable
                  config={dataRef.current.tableTopConfig}
                  data={dataRef.current.topCircuits}
                  timeFilterType={reportData.query.timeFilterType}
                />
              </div>
            )}

            <div className="table-chartview">
              <div className="title-circuit-dashlet hbr-type-h3">
                {i18n.bottomAvailableCircuits}
              </div>
              <DashletTable
                config={dataRef.current.tableConfig}
                data={tableData}
                timeFilterType={
                  reporting
                    ? reportData.query.timeFilterType
                    : globalFilter.timePeriodSelected
                }
                chartTooltip={!reporting}
                chartConfig={dataRef.current.chartTooltipConfig}
                source={getSourceCircuit([])}
                columnsName={columnsCircuitName()}
                style={{ height: "40px", width: "380px" }}
              ></DashletTable>
            </div>
          </div>
        )}
      </div>
    </HbrCard>
  );
};

CircuitView.propTypes = {
  history: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  reporting: PropTypes.bool,
  reportData: PropTypes.object
};

CircuitView.defaultProps = {
  reporting: false,
  reportData: {
    loader: true,
    error: null,
    data: [],
    query: { timeFilterType: "" }
  }
};

export default AppHOC(CircuitView);
