import React, { useState, useEffect, useRef, useCallback } from "react";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import AppHOC from "../../../../generics/AppHOC";
import Spinner from "../../../../common/Spinner";
import ErrorComponent from "../../../../common/ErrorComponent";
import NoDataAvailable from "../../../../common/NoDataAvailable";
import { useMount } from "../../../../utils/genericCommon";
import { getAppTotalUsageData } from "../actions";
import {
  parseTotalBandwidth
} from "../../../../config/apiParsers/applications";
import i18n from "amdi18n-loader!../../../nls/i18n";
import css from "../trendAnalysisMagneticStyle.less";
import OverlayedLineChart from "../../../../common/OverlayedLineChart";
import { getTrendInitialLoad } from "../../../../utils/common";

const [HbrCard] = reactWrapper(["hbr-card"]);

const TotalUsage = ({ globalFilter }) => {
  const [loader, setLoader] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const dataRef = useRef({ timestamp: 0, error: null, data: [] });
  const mount = useMount();

  const getTotalUsageData = useCallback(async (gFilter, timestamp) => {
    const res = await getAppTotalUsageData(gFilter.globalV4PayloadSidebar);
    if (mount.mounted === true && timestamp === dataRef.current.timestamp) {
      if (res.errorObject instanceof Object)
        dataRef.current.error = res.errorObject;
      else if (
        res.data instanceof Object && Array.isArray(res.data.data)
        && res.data.data.length > 0
      ) {
        dataRef.current.data =
          parseTotalBandwidth(res.data.data, gFilter.sideBarTimeFilter, gFilter.globalV4PayloadSidebar);
      }
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    const timestamp = Date.now();
    Object.assign(dataRef.current, { timestamp, error: null, data: [] });
    setLoader(true);
    if (getTrendInitialLoad(globalFilter, initialLoad)) {
      getTotalUsageData(globalFilter, timestamp);
    }
    setInitialLoad(false);
  }, [
    globalFilter.sideBarTimeFilter.current_period[0],
    globalFilter.sideBarTimeFilter.current_period[1]
  ]);
  return (
    <div className={css["overall-trend-view"]} data-cy="overallTrendView">
      <HbrCard
        id="non-interactive"
        slot="label"
        className="application-trend-analysis-hbr-card"
      >
        <div data-cy="overallTrendViewAppBandWidth" className="topChart">
          <div className="hbr-type-h2 trend-applicaiton-title-bottom">
            {i18n.overallTrendViewTotal}
          </div>
          {loader === true ? (
            <div
              data-cy="spinner-overAllTrendAppBandWidth"
              style={{ height: "200px" }}
            >
              <Spinner />
            </div>
          ) : dataRef.current.error !== null ? (
            <div className="error_components">
              <ErrorComponent
                {...dataRef.current.error}
                width="110px"
                className="super-small-dashlet-error"
              />
            </div>
          ) : dataRef.current.data.length === 0 ? (
            <div className="error_components">
              <NoDataAvailable text={i18n.applicationDashboardNoData} />
            </div>
          ) : (<>
            <OverlayedLineChart
              data={dataRef.current.data}
              chartPadding={{
                top: 15,
                left: 2,
                right: 0
              }}
              xAxisConfig={{ dateX: "date" }}
              yAxisConfig={{
                valueY: ["usage"],
                yAxisminGridDistance: 80
              }}
              seriesFill={true}
              min={globalFilter.sideBarTimeFilter.current_period[0]}
              max={globalFilter.sideBarTimeFilter.current_period[1]}
              sidebar
            />
          </>)}
        </div>
      </HbrCard>
    </div>
  );
};

TotalUsage.propTypes = {
  globalFilter: PropTypes.object.isRequired
};

export default AppHOC(TotalUsage);
