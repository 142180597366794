import Authorization from "../../generics/authorization";
import TabNavigation from "../../common/TabNavigation";
import TimeStampFilterComponent from "../../common/TimeStampFilterComponent";
import SitesComponent from "./SitesComponent";

const xValue = 0;
const wValue = 12;

const pageProps = {
  filterDashlets: [
    {
      component: TabNavigation,
      name: "tab-nav",
      layout: { x: xValue, y: 0, w: wValue, h: 9 }
    },
    {
      component: TimeStampFilterComponent,
      name: "time-filter",
      layout: { x: xValue, y: 10, w: wValue, h: 2 }
    }
  ],
  dashlets: [{
    component: SitesComponent,
    name: "sitesComponent",
    layout: { x: xValue, y: 0, w: wValue, h: 42 }
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
