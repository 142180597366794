import React, { useState, Suspense } from "react";
import { PropTypes } from "prop-types";

const MapComponent = React.lazy(() => import("../MapComponent"));
const SiteListComponent = React.lazy(() => import("../SiteListComponent"));

import reactWrapper from "@harbor/elements/utils/react/wrapper";
import Spinner from "../../../common/Spinner";
import AppHoc from "../../../generics/AppHOC";
import { TOGGLER } from "../../../utils/enums";
import css from "../sitesMagneticStyle.less";
import { ListBullets, MapPin } from "phosphor-react";

const [HbrViewSwitcher, HbrRadioButton, HbrCard] = reactWrapper([
  "hbr-view-switcher",
  "hbr-radio-button",
  "hbr-card"
]);

const SitesComponent = props => {
  const [toggle, setToggle] = useState(TOGGLER.MAP);
  const sitesView = TOGGLER.AVAILABILITY;
  // When the isEnabled flag is true, the ui will show recommendation and if the overlay is enabled for alto
  // else feature is disabled
  const extraMapSiteComponentStyle = {};

  const handleMapView = evt => {
    // taggler over map or site list table
    setToggle(evt.currentTarget.value);
  };

  return (
    <div
      className={css["map-site-component"]}
      style={extraMapSiteComponentStyle}
    >
      <HbrCard id="non-interactive-most-drop" className="map-sites-card">
        <div className="map-list-toggler-sites">
          <HbrViewSwitcher
            value={toggle}
            name="selected_view"
            onHbr-change={handleMapView}
            className="site-map-switch"
          >
            <HbrRadioButton value={TOGGLER.MAP}>
              <MapPin size={20} color="var(--hbr-color-neutral-text-weak)" weight="bold" />
            </HbrRadioButton>
            <HbrRadioButton value={TOGGLER.LIST}>
              <ListBullets size={20} color="var(--hbr-color-neutral-text-weak)" weight="bold" />
            </HbrRadioButton>
          </HbrViewSwitcher>
        </div>
        <div className="map-component">
          {toggle === TOGGLER.MAP ? (
            <Suspense fallback={<Spinner />}>
              <MapComponent {...props} sitesView={sitesView} />
            </Suspense>
          ) : (
              <Suspense fallback={<Spinner />}>
                <SiteListComponent {...props} sitesView={sitesView} />
              </Suspense>
            )}
        </div>
      </HbrCard>
    </div>
  );
};

SitesComponent.propTypes = {
  sites: PropTypes.object,
  globalFilter: PropTypes.object
};

export default AppHoc(SitesComponent);
