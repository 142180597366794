import React from "react";
import { PropTypes } from "prop-types";
import Spinner from "../../../common/Spinner";
import SiteTable from "./siteTable";
import "../sitesMagneticStyle.less";

const SiteListComponent = props => (
  <>
    {props.globalFilter.siteAPIData.overviewSiteLoader ? (
      <Spinner />
    ) : (
      <div data-cy="pdeTableView">
        <div
          className="dnx--css_table-container commonTableStyle"
          style={{
            maxWidth: "100vw"
          }}
        >
            <SiteTable {...props} />
        </div>
      </div>
    )}
  </>
);
SiteListComponent.propTypes = {
  globalFilter: PropTypes.object,
  sitesView: PropTypes.string
};

export default SiteListComponent;
