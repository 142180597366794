import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";
import { BASE_URL } from "../../../../../apis/apiConstants";

export default {
  title: i18nMessageBundle.tableHeadingTopApp,
  subTitle: i18nMessageBundle.appViewApplication,
  columns: [
    {
      title: i18nMessageBundle.appViewApplication,
      colSpan: 1,
      property: "application",
      type: "string",
      format: "link",
      maxLength: 15,
      path: `${BASE_URL}/Dashboards`
    },
    {
      title: i18nMessageBundle.appViewByQoE,
      colSpan: 1,
      property: "vqoe_score",
      type: "number",
      theme: "change",
      tooltip: true,
      tooltipTitle: i18nMessageBundle.appQoeTooltipTitle,
      tooltipDefinition: i18nMessageBundle.appQoeTooltipDefinition,
      padding: "5px"
    },
    {
      title: i18nMessageBundle.appQoeSiteCount,
      colSpan: 1,
      property: "distributionTotal",
      type: "number",
      tooltip: false,
      tooltipTitle: i18nMessageBundle.appQoeTooltipTitle,
      tooltipDefinition: i18nMessageBundle.appQoeTooltipDefinition,
      padding: "2px"
    },
    {
      title: null,
      colSpan: 1,
      property: "chart",
      type: "chart",
      format: "stackedBarChart",
      width: "50%"
    }
  ]
};
