import Authorization from "../../../../generics/authorization";
import ApplicationSummary from ".";

const pageProps = {
  workflowContainer: true,
  dashlets: [{
    component: ApplicationSummary,
    name: "ApplicationSummary",
    layout: { x: 0, y: 0, w: 100, h: 40 }
  }]
};

const Page = () => (<Authorization pageProps={pageProps} />);

export default Page;
