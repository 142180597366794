import React, { useEffect, useMemo } from "react";
import { PropTypes } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import reduxContext from "../reducer";
import * as actions from "../actions";
import AppHOC from "../../../../../generics/AppHOC";
import Spinner from "../../../../../common/Spinner";
import ErrorComponent from "../../../../../common/ErrorComponent";
import OverlayedLineChart from "../../../../../common/OverlayedLineChart";
import QoEScoreComponent from "../../../../../common/QoEScoreComponent";
import { compareAndMatchELT, getIsHourlyData, qoeRanges } from "../../../../../utils/common";
import { getTimestampFromUTCString } from "../../../../../utils/displayTime";
import i18n from "amdi18n-loader!../../../../nls/i18n";
import { defaultCellValue } from "../../../../../utils/enums";
import { formatNumber } from "../../../../../utils/format";

const QoeChart = ({ actions, app360, reportData, globalV4PayloadReport }) => {
  const appName = globalV4PayloadReport.application;

  useEffect(() => {
    const payload = { ...globalV4PayloadReport, sort: { entry_ts: "asc" } };
    actions.getChartQOE(payload);
  }, []);

  const data = useMemo(() => {
    let qoeData = null;
    if (
      app360.qoe_chart_data instanceof Object
      && Array.isArray(app360.qoe_chart_data.data)
    ) {
      qoeData = app360.qoe_chart_data.data.map(item => ({
        date: getTimestampFromUTCString(item.entry_ts),
        vqoe_score: item.vqoe_score
      }));

      if (getIsHourlyData(globalV4PayloadReport.time_frame)) {
        qoeData = compareAndMatchELT(
          qoeData,
          {
            current_period:
              [reportData.current_period_start, reportData.current_period_end]
          }
        );
      }
    }
    return qoeData;
  }, [app360.qoe_chart_data]);

  const qoeScoreData = useMemo(() => {
    let qoeData = null;
    if (Array.isArray(app360.customList)) {
      const appDataWithFamilySla = app360.customList && app360.customList.find(row => row.application === appName);
      qoeData = {
        appScore:
          appDataWithFamilySla === undefined
            ? 0
            : appDataWithFamilySla.vqoe_score === defaultCellValue
              ? defaultCellValue
              : formatNumber(appDataWithFamilySla.vqoe_score),
        appScoreChange:
          appDataWithFamilySla === undefined
            ? 0
            : appDataWithFamilySla.vqoe_change === defaultCellValue
              ? defaultCellValue
              : formatNumber(appDataWithFamilySla.vqoe_change, "changeValue")
      }
    }
    return qoeData;
  }, [app360.customList]);

  return (
    <div className="app-360-report-qoe-chart">
      {app360.qoe_chart_loader === true ? (
        <Spinner />
      ) : Array.isArray(data) ? (
        <div className="flex-column">
          <div className="flex-items qoe-info">
            <div className="hbr-type-h2">
              {i18n.syncronizedChartQoETitle}
            </div>
            <QoEScoreComponent 
              appScoreChange = {qoeScoreData.appScoreChange}
              appScore = {qoeScoreData.appScore}
              tooltipInfo= {i18n.app360TitleIcon}
              isReport={true}
            />
          </div>
          <OverlayedLineChart
            data={data}
            qualityRange={qoeRanges}
            range={{ yAxes: { min: 0, max: 10 } }}
            lineConfig={{ strokeWidth: 3 }}
            chartPadding={{ top: 15, left: 2, right: 3 }}
            xAxisConfig={{ dateX: "entry_time" }}
            yAxisConfig={{
                valueY: ["vqoe_score"],
                yAxisTitle: i18n.qoeScore,
                yAxisminGridDistance: 80
              }}
            min={reportData.current_period_start}
            max={reportData.current_period_end}
          />
        </div>
      ) : (
        <div className="error">
          <ErrorComponent {...app360.qoe_chart_error} width="210px" />
        </div>
      )}
    </div>
  );
};

QoeChart.propTypes = {
  actions: PropTypes.object.isRequired,
  app360: PropTypes.object.isRequired,
  reportData: PropTypes.object.isRequired,
  globalV4PayloadReport: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({ app360: state.vanalytics.app360_report });

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(QoeChart))
);
