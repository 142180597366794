import i18nMessageBundle from "amdi18n-loader!../../../../nls/i18n";

export const columnsClientsName = [
  {
    prop: "site_name",
    name: i18nMessageBundle.clientSitesColHeader,
    size: 80
  },
  {
    prop: "source_ip",
    name: i18nMessageBundle.clientIpColHeader,
    size: 80
  },
  {
    prop: "top_used_apps",
    name: i18nMessageBundle.clientsTopAppsColHeader,
    size: 150
  },
  {
    prop: "usage",
    name: i18nMessageBundle.userDataUsage,
    size: 100
  }
];

export const getSourceClients = data => {
  return data.map(element => {
    return {
      application: element.site_name,
      source_ip: element.source_ip,
      top_used_apps: element.top_used_apps,
      userDataUsage: element.userDataUsage
    };
  });
};
