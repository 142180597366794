import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";

import css from "../../../reportsMagneticStyle.less";
import reduxContext from "../../../../../generics/AppHOCReport/reducer";
import useReportApis from "../../../commonHooks/useReportApis";
import Spinner from "../../../../../common/Spinner";
import AppHocReport from "../../../../../generics/AppHOCReport";
import { SITE, APP } from "../../../../../utils/enums";
import {
  getReportId,
  getWorkflowState,
  isAll,
  arrayToObject,
  isAllSitesTemplate,
  isAllApplicationsTemplate,
  isAllSitesTemplateCSV,
  isAllApplicationsTemplateCSV,
  isExecutiveTemplate
} from "../../../../../utils/common";
import {
  shortDisplayTimeOnly,
  getRange
} from "../../../../../utils/displayTime";
import { BASE_URL } from "../../../../../apis/apiConstants";
import ErrorComponent from "../../../../../common/ErrorComponent";
import ScopeView from "../../../genericWorkflowComponents/views/ScopeView";
import { setWorkflow } from "../../../../../generics/AppHOCReport/actions";
import { appsFilter } from "../../../../../config/reqConfig/applications"; 
import { prepareQuery } from "../../../../../utils/query-utils";
import ApiService from "../../../../../config/api-config";
 

// Edit Report
const Dashlet = () => {
  const workflowState = getWorkflowState();
  const reportId = getReportId(location);
  const { state, getAppsDetails, getSitesDetails } = useReportApis();
  const dispatch = useDispatch();

  useEffect(async () => {
    if (_.isEmpty(workflowState)) {
      const { getReportByUUID } = ApiService;

      getReportByUUID(reportId)
        .then(res => {
          if (res.errorObject) throw res;
          const reportObj = res.data[0];
          const _scope = JSON.parse(reportObj.scope);
          const freq = reportObj.schedule_freq;
          const timeRange = reportObj.time_range;
          const isScopeEmpty =
            _.isEmpty(_scope.application[0]) && _.isEmpty(_scope.site_id[0]);
          let choice = {
            appChoice: APP.ALL,
            siteChoice: SITE.ALL
          };

          if (_scope.application[0] && !isAll(_scope.application[0])) {
            choice = { appChoice: APP.CUSTOM };
          }

          if (_scope.site_id[0] && !isAll(_scope.site_id[0])) {
            choice = { ...choice, ...{ siteChoice: SITE.CUSTOM } };
          }

          const updatedWorkflow = {
            ...getWorkflowState(),
            ...{
              report_uuid: reportObj.report_uuid,
              scope: {
                application: _scope.application[0],
                site_id: _scope.site_id[0]
              },
              reportName: reportObj.report_name,
              fileType: reportObj.file_type,
              scheduleType: reportObj.schedule_type,
              scheduleFreq: freq,
              timeRange: timeRange,
              repeatTime: shortDisplayTimeOnly(reportObj.repeat_time),
              recipientEmail: reportObj.recipient_email.replace("|", ","),
              scheduleStatus: reportObj.schedule_status,
              baseUrl: `${BASE_URL}/`,
              step: 1,
              isReportLoaded: true,
              isNextDisabled: false,
              showAllSites:
                isScopeEmpty ||
                isAll(_scope.site_id[0]) ||
                !_.isEmpty(_scope.site_id[0]),
              showAllApplications:
                isScopeEmpty ||
                isAll(_scope.application[0]) ||
                !_.isEmpty(_scope.application[0]),
              pageName: reportObj.page_name,
              report_passkey: reportObj.report_passkey,
              ...{ ...choice }
            }
          };
          saveState(updatedWorkflow);
          const pageName = reportObj.page_name;
          if (isAllSitesTemplate(pageName) || isAllSitesTemplateCSV(pageName)) { 
            getSitesDetails();
          }
          // check the generate report is application, then allow to fire application list api

          if (
            isAllApplicationsTemplate(pageName) ||
            isAllApplicationsTemplateCSV(pageName)
          ) {
            getAppsDetails(
              prepareQuery(appsFilter, { timePeriodSelected: "720" })
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (state.isFetchingReportDetailsDone) {
      const _scope = JSON.parse(state.reportDetails[0].scope);
      const freq = state.reportDetails[0].schedule_freq;
      const timeRange = getRange(state.reportDetails[0]);
      const isScopeEmpty =
        _.isEmpty(_scope.application[0]) && _.isEmpty(_scope.site_id[0]);
      let choice = {
        appChoice: APP.ALL,
        siteChoice: SITE.ALL
      };

      if (_scope.application[0] && !isAll(_scope.application[0])) {
        choice = { appChoice: APP.CUSTOM };
      }

      if (_scope.site_id[0] && !isAll(_scope.site_id[0])) {
        choice = { ...choice, ...{ siteChoice: SITE.CUSTOM } };
      }

      const updatedWorkflow = {
        ...getWorkflowState(),
        ...{
          report_uuid: state.reportDetails[0].report_uuid,
          scope: {
            application: _scope.application[0],
            site_id: _scope.site_id[0]
          },
          reportName: state.reportDetails[0].report_name,
          fileType: state.reportDetails[0].file_type,
          scheduleType: state.reportDetails[0].schedule_type,
          scheduleFreq: freq,
          timeRange: timeRange,
          repeatTime: shortDisplayTimeOnly(state.reportDetails[0].repeat_time),
          recipientEmail: state.reportDetails[0].recipient_email.replace(
            "|",
            ","
          ),
          scheduleStatus: state.reportDetails[0].schedule_status,
          baseUrl: `${BASE_URL}/`,
          step: 1,
          isReportLoaded: true,
          isNextDisabled: false,
          showAllSites:
            isScopeEmpty ||
            isAll(_scope.site_id[0]) ||
            !_.isEmpty(_scope.site_id[0]),
          showAllApplications:
            isScopeEmpty ||
            isAll(_scope.application[0]) ||
            !_.isEmpty(_scope.application[0]),
          pageName: state.reportDetails[0].page_name,
          report_passkey: state.reportDetails[0].report_passkey,
          ...{ ...choice }
        }
      };
      saveState(updatedWorkflow);
    } else if (state.isFetchingReportDetailsError) {
      saveState({
        ...getWorkflowState(),
        ...{
          scope: { application: "", site_id: "" },
          reportName: "",
          isNextDisabled: true,
          isReportLoaded: true,
          step: 1
        }
      });
    }
  }, [state.isFetchingReportDetailsDone, state.isFetchingReportDetailsError]);

  useEffect(() => {
    if (state.isFetchingAppsDetailsDone) {
      // Create an object to eliminate the dups elements in dropdown
      const appObject = arrayToObject(state.appsDetails, "application");
      const appDetails = Object.keys(appObject).map(elem => {
        return {
          text: elem,
          value: elem
        };
      });

      const updatedScopeWorkflow = {
        ...getWorkflowState(),
        ...{
          applications: appDetails,
          isFilterAppsLoaded: true,
          isFilterSitesLoaded: true
        }
      };
      saveState(updatedScopeWorkflow);
    } else if (state.isFetchingAppsDetailsError) {
      saveState({
        ...getWorkflowState(),
        ...{
          scope: { application: "", site_id: "" },
          reportName: "",
          isNextDisabled: true,
          isFilterAppsLoaded: true,
          isFilterSitesLoaded: true,
          step: 1
        }
      });
    }
  }, [state.isFetchingAppsDetailsDone, state.isFetchingAppsDetailsError]);

  useEffect(() => {
    if (state.isFetchingSitesDetailsDone) {
      const siteDetails = state.sitesDetails
        .map(item => ({ text: item.site_name, value: item.site_id }));

      const updatedScopeWorkflow = {
        ...getWorkflowState(),
        ...{
          sites: siteDetails,
          isFilterAppsLoaded: true,
          isFilterSitesLoaded: true
        }
      };
      saveState(updatedScopeWorkflow);
    } else if (state.isFetchingSitesDetailsError) {
      saveState({
        ...getWorkflowState(),
        ...{
          scope: { application: "", site_id: "" },
          reportName: "",
          isNextDisabled: true,
          isFilterAppsLoaded: true,
          isFilterSitesLoaded: true,
          step: 1
        }
      });
    }
  }, [state.isFetchingSitesDetailsDone, state.isFetchingSitesDetailsError]);

  const saveState = updatedWorkflow => {
    dispatch(setWorkflow(updatedWorkflow));
  };

  const loader = !(
    isExecutiveTemplate(workflowState.pageName) === true
    || (workflowState.isFilterAppsLoaded && workflowState.isFilterSitesLoaded)
    ) && workflowState.isReportLoaded;

  const error = (
    isExecutiveTemplate(workflowState.pageName) === false
    && (state.isFetchingReportDetailsError || state.isFetchingAppsDetailsError)
    ) || state.isFetchingSitesDetailsError;
    
  const isAllSitesSummary = isAllSitesTemplate(workflowState.pageName);

  return (
    <div className={css["workflow"]}>
      {
        loader === true ? (
          <Spinner />
        ) : error ? (
          <ErrorComponent
            className="large-dashlet-error"
            code="500"
            width="300px"
          />
        ) : (
          <ScopeView
            scopeWorkflowState={workflowState}
            saveState={saveState}
            isAllSitesDropdownShown={isAllSitesSummary}
          />
        )
      }
    </div>
  );
};
export default reduxContext.withProvider(connect()(AppHocReport(Dashlet)));
