import React, { Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import _ from "lodash";

//reducers
import reduxContext from "../reducer";
import * as actions from "../actions";

//style
import css from "../overviewMagneticStyle.less";

//components
import AppHOC from "../../../generics/AppHOC";
import DashletTable from "../../../common/DashletTable";
import HarborDonut from "../../../common/HbrDonut";
import Spinner from "../../../common/Spinner";
import QoeListComponent from "../../../common/QoeListComponent";

//utils
import { getDonutData } from "../../../utils/views/overview";
import { hasFilterChanged, getCustomListApi } from "../../../utils/common";
import qoeTableConfig from "./config/table/appQoeTableConfig";
import qoeDonutConfig from "./config/donut/appQoeDonutConfig";
import { parseApplSummary } from "../../../config/apiParsers/overview";
import { columnsName, getSource } from "./config/table/harborTableHeaderConfig";
import { COLOR } from "../../../utils/enums";
import { BASE_URL } from "../../../apis/apiConstants";

//i18 files
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";
import { timeFilterTypesCustomV4 } from "../../../utils/enums";
import {
  shortDisplayDate24HourWithSec,
  formatDate
} from "../../../utils/displayTime";

const ErrorComponent = React.lazy(() =>
  import("../../../common/ErrorComponent")
);

const mapStateToProps = state => {
  return {
    overview: state.vanalytics.overview
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const [HbrSelect, HbrCard, HbrOption, HbrButton] = reactWrapper([
  "hbr-select",
  "hbr-card",
  "hbr-option",
  "hbr-button",
  "hbr-table"
]);
const allQoEFilter = ["good", "fair", "poor"];

class DashletAppView extends React.Component {
  constructor() {
    super();

    this.state = {
      appDonutData: null,
      appDonutLoader: true,
      appDonutError: false,
      appStackedData: null,
      appStackedLoader: true,
      appStackedError: false,
      appStackedDataDes: null,
      appStackedLoaderDes: true,
      appStackedErrorDes: false,
      appdataProcessed: false
    };
    this.title = (
      <div data-cy="application-link">
        <div className="hbr-type-h2">
          {i18nMessageBundle.appViewApplication}
        </div>
      </div>
    );

    this.qoeFilters = ["good", "fair", "poor", "unknown"];
    this.dropdown = {
      options: [
        { label: i18nMessageBundle.appViewByQoE, value: "qoe" },
        {
          label: i18nMessageBundle.appViewByGoodQoE,
          value: this.qoeFilters[0]
        },
        {
          label: i18nMessageBundle.appViewByFairQoE,
          value: this.qoeFilters[1]
        },
        { label: i18nMessageBundle.appViewByPoorQoE, value: this.qoeFilters[2] }
      ]
    };
    this.filters = {
      viewFilter: {
        label: i18nMessageBundle.appViewBy,
        value: "view",
        action: this.onViewChange.bind(this),
        items: {
          qoe: {
            label: i18nMessageBundle.appViewByQoE,
            value: "qoe",
            config: {
              donut: qoeDonutConfig,
              table: qoeTableConfig
            }
          },
          good: {
            label: i18nMessageBundle.appViewByGoodQoE,
            value: "good",
            config: {
              donut: qoeDonutConfig,
              table: qoeTableConfig
            }
          },
          fair: {
            label: i18nMessageBundle.appViewByFairQoE,
            value: "fair",
            config: {
              donut: qoeDonutConfig,
              table: qoeTableConfig
            }
          },
          poor: {
            label: i18nMessageBundle.appViewByPoorQoE,
            value: "poor",
            config: {
              donut: qoeDonutConfig,
              table: qoeTableConfig
            }
          }
        }
      }
    };
    this.donutConfig = false;
    this.tableConfig = false;
  }

  getData = (props, globalV4PayloadReport, isReporting) => {
    this.props.setAppsWithFamilySLA(undefined);
     getCustomListApi(props, globalV4PayloadReport, isReporting);

  };

  componentDidMount() {
    this.setState({
      appDonutData: null,
      appDonutLoader: true,
      appStackedData: null,
      appStackedLoader: true,
      appdataProcessed: false
    });
    this.updateDashletFilter({ type: "all", view: "qoe" });

    if (
      this.props.isReporting &&
      this.props.executiveSummary.reportData &&
      this.props.executiveSummary.reportData.length > 0
    ) {
      const {
        current_period_start,
        current_period_end,
        time_range
      } = this.props.executiveSummary.reportData[0];

    let globalV4PayloadReport = {
        time_frame:  timeFilterTypesCustomV4[time_range],
        entry_ts: {
          start: shortDisplayDate24HourWithSec(formatDate(current_period_start).utc()),
          end: shortDisplayDate24HourWithSec(formatDate(current_period_end).utc())
        }
      };
      this.getData(this.props, globalV4PayloadReport,this.props.isReporting);
    }
  }

  componentDidUpdate(prevProps) {
    // On overlay or time filter change, fetch data
    if (
      prevProps.globalFilter.currentTimeStamp !==
      this.props.globalFilter.currentTimeStamp
    ) {
      this.getData(this.props);
    }
    if (hasFilterChanged(this.props.globalFilter, prevProps.globalFilter)) {
      const { app } = this.props.overview.dashletFilter;
      const { setDashletFilter, setDashletLoading } = this.props.actions;
      setDashletLoading("APP");
      setDashletFilter(
        "APP",
        Object.assign({}, app, { type: "all", view: "qoe" })
      );
      this.setState({
        appdataProcessed: false
      });
    }
    if (
      !_.isEqual(
        this.props.globalFilter.app_data_with_family_sla,
        prevProps.globalFilter.app_data_with_family_sla
      )
    ) {
      if (
        this.props &&
        this.props.globalFilter.app_data_with_family_sla != undefined &&
        this.props.globalFilter.app_data_with_family_sla &&
        this.props.globalFilter.app_data_with_family_sla.length > 0 &&
        !this.state.appdataProcessed
      ) {
        this.setState({
          appDonutData: null,
          appDonutLoader: true,
          appStackedData: null,
          appStackedLoader: true,
          appdataProcessed: false
        });
        this.updateView();
      } else if (
        this.props &&
        this.props.globalFilter.app_data_with_family_sla != undefined &&
        this.props.globalFilter.app_data_with_family_sla &&
        this.props.globalFilter.app_data_with_family_sla.length == 0 &&
        !this.state.appdataProcessed
      ) {
        let vqoe_donut = { good: 0, poor: 0, fair: 0, unknown: 0 };
        let vqoe_donut_summary = { summary: {} };
        vqoe_donut_summary["summary"] = vqoe_donut;
        let vqoe_stacked_summary = { detail: [] };
        this.setState({
          appDonutData: vqoe_donut_summary,
          appDonutLoader: false,
          appStackedData: parseApplSummary(vqoe_stacked_summary),
          appStackedLoader: false,
          appdataProcessed: true
        });
      } else if (
        this.props.globalFilter.app_data_with_family_sla == undefined
      ) {
        this.setState({
          appDonutData: null,
          appDonutLoader: true,
          appStackedData: null,
          appStackedLoader: true,
          appdataProcessed: false
        });
      }
    } else if (
      !this.state.appdataProcessed &&
      this?.props?.globalFilter?.app_data_with_family_sla?.length == 0
    ) {
      let vqoe_donut = { good: 0, poor: 0, fair: 0, unknown: 0 };
      let vqoe_donut_summary = { summary: {} };
      vqoe_donut_summary["summary"] = vqoe_donut;
      let vqoe_stacked_summary = { detail: [] };
      this.setState({
        appDonutData: vqoe_donut_summary,
        appDonutLoader: false,
        appStackedData: parseApplSummary(vqoe_stacked_summary),
        appStackedLoader: false,
        appdataProcessed: true
      });
    }
  }

  onViewChange(view) {
    this.updateDashletFilter({ view });
  }

  updateDashletFilter(filter) {
    const { app } = this.props.overview.dashletFilter;
    const { setDashletFilter, setDashletLoading } = this.props.actions;
    setDashletLoading("APP");
    setDashletFilter("APP", Object.assign({}, app, filter));
    this.setState({
      appDonutData: null,
      appDonutLoader: true,
      appStackedData: null,
      appStackedLoader: true,
      appdataProcessed: false
    });
    this.updateView();
  }

  updateView() {
    const { app } = this.props.overview.dashletFilter;
    const config = this.filters.viewFilter.items[app.view].config;
    this.donutConfig = config.donut;
    if (this.props.isReporting) {
      config.table.columns[1].tooltip = false;
    }
    this.tableConfig = config.table;
    this.topTableConfig = {
      ...qoeTableConfig,
      title: i18nMessageBundle.tableHeadingBottom
    };
    const updateSite =
      this.props.globalFilter.selectedSite > 0
        ? {
          site_id: this.props.globalFilter.selectedSite
        }
        : {};
    this.tableConfig.columns[3].title =
      Object.entries(updateSite).length > 0
        ? i18nMessageBundle.circuitQoeDistribution
        : i18nMessageBundle.appQoeDistribution;

    this.tableConfig.columns[2].title =
      Object.entries(updateSite).length > 0
        ? i18nMessageBundle.circuitQoeSiteCount
        : i18nMessageBundle.appQoeSiteCount;
    if (
      this.props &&
      this.props.globalFilter.app_data_with_family_sla != undefined &&
      this.props.globalFilter.app_data_with_family_sla &&
      this.props.globalFilter.app_data_with_family_sla.length > 0
    ) {
      const resData = this.props.globalFilter.app_data_with_family_sla;
      // get donut chart data
      const qoeData = { good: 0, poor: 0, fair: 0, unknown: 0 };
      for (const { vqoe_status } of resData)
        qoeData[vqoe_status] += 1;
      qoeData.bad = qoeData.poor;
      delete qoeData.poor;
      // get table data
      const qoeFilter = app.view === "qoe" ? allQoEFilter : [app.view];
      const tableData = resData
        .filter(item => qoeFilter.includes(item.vqoe_status))
        .sort((item1, item2) => item2.usage - item1.usage).slice(0, 50)
        .sort((item1, item2) => item1.vqoe_score - item2.vqoe_score);

      this.setState({
        appDonutData: { summary: qoeData },
        appDonutLoader: false,
        appStackedData: parseApplSummary({ detail: tableData.slice(0, 5) }),
        appStackedLoader: false,
        appdataProcessed: true
      });

      if (this.props.isReporting) {
        this.setState({
          appStackedDataDes: parseApplSummary({ detail: tableData.slice(-5) }),
          appStackedLoaderDes: false
        });
      }
    }
  }

  renderFilters() {
    const options = this.dropdown.options;
    const { app } = this.props.overview.dashletFilter;

    return (
      <HbrSelect
        key={options[0].label}
        style={{ width: "110px" }}
        value={app.view}
        onHbr-change={event => {
          this.onViewChange(event.currentTarget.value);
        }}
      >
        {options.map(option => (
          <HbrOption key={option.label} value={option.value}>
            {option.label}
          </HbrOption>
        ))}
      </HbrSelect>
    );
  }

  render() {
    const errorAppDonut =
      this.props.globalFilter &&
      this.props.globalFilter.applicationAggregateData &&
      this.props.globalFilter.applicationAggregateData.resObj &&
      this.props.globalFilter.applicationAggregateData.resObj.message !=
      "successful";

    const errorApp =
      this.props.globalFilter &&
      this.props.globalFilter.applicationAggregateData &&
      this.props.globalFilter.applicationAggregateData.resObj &&
      this.props.globalFilter.applicationAggregateData.resObj.message !=
      "successful";
    let loader =
      this.props.globalFilter.app_data_with_family_sla == undefined
        ? true
        : false;
    const { timePeriodSelected } = this.props.globalFilter;

    return (
      <HbrCard
        className={
          this.props.isReporting
            ? css["app-dashlet-reporting"]
            : css["app-dashlet-card"]
        }
        data-cy="app-dashlet-card"
        container={true}
      >
        <div className="app-dashlet-view" data-cy="app-dashlet-view">
          {!this.props.isReporting && (
            <div className="longer-title-block">
              <div className="title-block">
                <div className="header-content">{this.title}</div>
                <div className="filters">{this.renderFilters()}</div>
              </div>
              <HbrButton variant="text" onClick={() => this.props.history.push(`${BASE_URL}/applications`)}>
                {i18nMessageBundle.appViewByViewDetails}
              </HbrButton>
            </div>
          )}

          {this.state &&
            (loader ||
              this.state.appStackedLoader ||
              this.state.appDonutLoader) ? (
              <div
                className="spinner-component-app-view"
                data-cy="spinner-component-app-view"
              >
                <Spinner />
              </div>
            ) : errorAppDonut || errorApp ? (
              <Suspense fallback={<Spinner />}>
                <ErrorComponent
                  {...errorApp}
                  {...errorAppDonut}
                  width={"210px"}
                  errorCode={
                    this.props.globalFilter.applicationAggregateData.resObj
                      .errorCode
                  }
                  message={
                    this.props.globalFilter.applicationAggregateData.resObj
                      .message
                  }
                  className={"small-dashlet-error"}
                />
              </Suspense>
            ) : (
                <div
                  className={
                    this.props.isReporting
                      ? "content-block-reporting"
                      : "content-block-app"
                  }
                >
                  {this.state &&
                    ((this.state.appDonutLoader && this.state.appDonutData == null) ||
                      loader) ? (
                      <Spinner />
                    ) : (
                      this.state &&
                      this.state.appDonutData &&
                      !this.state.appDonutLoader &&
                      !loader &&
                      this.donutConfig &&
                      this.state.appDonutData.summary && (
                        <div
                          className={
                            this.props.isReporting ? "donut-container-reporting" : "donut-container"
                          }
                        >
                          {this.props.isReporting && (
                            <div className="title-block hbr-type-h3">
                              {i18nMessageBundle.applicationQoeBreakdown}
                            </div>
                          )}
                          <div className="donut-section">
                            <HarborDonut
                              data={getDonutData(
                                this.donutConfig.text,
                                this.state.appDonutData.summary
                              )}
                              donutLabel={i18nMessageBundle.appsLabel}
                            />
                            <div className="legend-app-view">
                              <QoeListComponent
                                overviewPage={true}
                                iconType={[
                                  COLOR.GOOD,
                                  COLOR.FAIR,
                                  COLOR.POOR,
                                  COLOR.GRAY
                                ]}
                                qoeScore={[0, 0, 0, 0]}
                                qoeLabels={[
                                  qoeDonutConfig.text.good.title,
                                  qoeDonutConfig.text.fair.title,
                                  qoeDonutConfig.text.bad.title,
                                  qoeDonutConfig.text.unknown.title
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  {(this.state &&
                    this.state.appStackedLoader &&
                    this.state.appStackedData == null) ||
                    loader ? (
                      <Spinner />
                    ) : (
                      this.state &&
                      this.state.appStackedData &&
                      this.tableConfig &&
                      !this.state.appStackedLoader &&
                      !loader &&
                      this.state.appStackedData.detail && (
                        <div
                          className={
                            this.props.isReporting
                              ? "table-chartview-container"
                              : "table-view-app"
                          }
                        >
                          <div
                            className={
                              this.props.isReporting
                                ? "table-chartview-reporting"
                                : "table-chartview"
                            }
                          >
                            {this.props.isReporting &&
                              this.state &&
                              this.state.appStackedDataDes &&
                              this.state.appStackedDataDes.detail && (<>
                                <div className="title-app-dashlet hbr-type-h3">
                                  {i18nMessageBundle.tableHeadingBottom}
                                </div>
                                <DashletTable
                                  config={this.topTableConfig}
                                  data={this.state.appStackedDataDes.detail}
                                  timeFilterType={timePeriodSelected}
                                  chartTooltip={this.props.isReporting ? false : true}
                                  widget={"app"}
                                ></DashletTable>
                              </>
                              )}
                          </div>
                          <div
                            className={
                              this.props.isReporting
                                ? "table-chartview-reporting"
                                : "table-chartview"
                            }
                          >
                            <div className="title-app-dashlet-left hbr-type-h3">
                              {i18nMessageBundle.tableHeadingTopApp}
                            </div>
                            <DashletTable
                              config={this.tableConfig}
                              data={this.state.appStackedData.detail}
                              timeFilterType={timePeriodSelected}
                              chartTooltip={this.props.isReporting ? false : true}
                              widget={"app"}
                              source={getSource([])}
                              columnsName={columnsName()}
                              style={{ height: "40px", width: "410px" }}
                              history={this.props.history}
                            ></DashletTable>
                          </div>
                        </div>
                      )
                    )}
                </div>
              )}
        </div>
      </HbrCard>
    );
  }
}

DashletAppView.propTypes = {
  overview: PropTypes.object.isRequired,
  globalFilter: PropTypes.object.isRequired,
  actions: PropTypes.object,
  setAggregateAppData: PropTypes.func.isRequired,
  setAppFamilySLA: PropTypes.func.isRequired,
  setAppsWithFamilySLA: PropTypes.func.isRequired,
  isReporting: PropTypes.bool,
  timeRange: PropTypes.any,
  executiveSummary: PropTypes.object.isRequired,
  history: PropTypes.object
};

DashletAppView.defaultProps = {
  isReporting: false,
  executiveSummary: {}
};

export default reduxContext.withProvider(
  connect(mapStateToProps, mapDispatchToProps)(AppHOC(DashletAppView))
);
