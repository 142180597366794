import React, { Suspense, useState } from "react";
import { PropTypes } from "prop-types"; 
import  SdwanComponent from  "./SdwanComponent";
import  TalosComponent from  "./TalosComponent";
import reactWrapper from "@harbor/elements/utils/react/wrapper";
import Spinner from "../../../common/Spinner";
import AppHoc from "../../../generics/AppHOC"; 
import { TOGGLER } from "../../../utils/enums";
import css from "../securityMagneticStyle.less"; 
import i18nMessageBundle from "amdi18n-loader!../../nls/i18n";

const [HbrCard, HbrTabGroup, HbrTab, HbrTabPanel] = reactWrapper([
  "hbr-card",
  "hbr-tab-group",
  "hbr-tab",
  "hbr-tab-panel"
]);

const SecurityComponent = props => { 
  const [toggle, setToggle] = useState(TOGGLER.SDWAN);

  const handleToggleChange = value => {
    setToggle(value.detail.name); // setting toggle between talos & sdwan
  }

  return (
    <div className={css["network-sdwan-talos-component"]}>
      <HbrCard id="non-interactive-most-drop" className="network-view-card">
        <HbrTabGroup size="medium" onHbr-tab-show={((e) => handleToggleChange(e))}>
          <HbrTab className="hbr-type-body3" key={TOGGLER.SDWAN} slot="nav" panel={TOGGLER.SDWAN}>
            {i18nMessageBundle.securityTabView.sdwan}
          </HbrTab>
          <HbrTab className="hbr-type-body3" key={TOGGLER.TALOS} slot="nav" panel={TOGGLER.TALOS}>
            {i18nMessageBundle.securityTabView.talos}
          </HbrTab>
          <HbrTabPanel name={TOGGLER.SDWAN}>
            {toggle === TOGGLER.SDWAN && (<Suspense fallback={<Spinner />}>
              <SdwanComponent {...props} mapView={toggle} /> 
            </Suspense>)}
          </HbrTabPanel>
          <HbrTabPanel name={TOGGLER.TALOS}>
            {toggle === TOGGLER.TALOS && (<Suspense fallback={<Spinner />}>
              <TalosComponent {...props} mapView={toggle} />
            </Suspense>)}
          </HbrTabPanel>          
        </HbrTabGroup>
      </HbrCard>
    </div>
  );
};

SecurityComponent.propTypes = {
  sites: PropTypes.object,
  globalFilter: PropTypes.object
};

export default AppHoc(SecurityComponent);
