import {
  formatNumberNDecimalPlaces,
  formatSiteTableBytes,
  capitalizeString,
  formatString
} from "../../../utils/format";
import { getColor } from "../../../utils/colors";
import i18n from "amdi18n-loader!../../nls/i18n";
import { BASE_URL, BASE_V_MANAGE_URL } from "../../../apis/apiConstants";
import { getCookie } from "../../../serviceWorker";
import { updateCookie } from "../../../utils/common";
import { defaultCellValue } from "../../../utils/enums";
 
export const getTableColumns = (history, setSelectedSite) => {
  const tableColumns = [
    {
      prop: "site_name",
      name: i18n.sites,
      sortable: true,
      size: 180,
      cellTemplate: (createElement, { model }) => { 
        const displaySiteName = formatString(model.site_name, 20);
        const getHTML = () =>
          `<hbr-link class="application-trending-apps-qoe-widget-link" id="sites-top-site-map-${model.site_name}">${displaySiteName}</hbr-link>
            <hbr-portal>
              <hbr-tooltip anchor="#sites-top-site-map-${model.site_name}">
                <div slot="content">
                    <strong>${model.site_name}</strong>  
                </div>
              </hbr-tooltip>
            </hbr-portal>`;
        const div = createElement("div", {
          content: `<div>${model.site_name}</div>`,
          innerHTML: getHTML(),
          onClick: () => { 
            const isUIconvergence = getCookie("cl-analytics");
            const sitesId = model && model.site_id ? model.site_id : '';
  
            if (isUIconvergence) {
              updateCookie("currentSite", sitesId);
              window.location.href = `${BASE_V_MANAGE_URL}/overview`;
            } else {
              const path = `${BASE_URL}/overview`;
              setSelectedSite(sitesId);
              history.push(path);
            } 
          }
        });
        return [div];
      },
    },
    {
      prop: "availability",
      name: i18n.siteAvailabilityPercentage,
      sortable: true,
      size: 140,
      order: "desc",
      cellProperties: ({ model, prop }) => {
        return { style: { color: getColor(model[prop], "availability") } };
      }
    },
    {
      prop: "city",
      name: i18n.siteLocation,
      sortable: true,
      size: 120,
      cellTemplate: (createElement, { model }) => { 
        const displayCityName = formatString(model.city, 15);
        const getHTML = () =>
          `<div>
            <hbr-button size="medium" variant="context" class="sites-table-city-name-text" id="sites-city-name-${model.site_id}">${displayCityName}</hbr-button>    
            <hbr-portal>
              <hbr-tooltip anchor="#sites-city-name-${model.site_id}"> 
                <div slot="content">
                    <strong>${model.city}</strong>  
                </div>
              </hbr-tooltip>
            </hbr-portal>
            </div>`;
        const div = createElement("div", {
          content: `<div>${model.city}</div>`,
          innerHTML: getHTML()          
        });
        return [div];
      }, 
    },
    {
      prop: "usage",
      name: i18n.applicationTableViewUsage, 
      sortable: true,
      size: 150
    },
    {
      prop: "loss",
      name: i18n.applicationTableViewLoss,
      size: 150,
      sortable: true,
    },
    {
      prop: "latency",
      name: i18n.latencyInMPS,
      size: 170,
      sortable: true,
    },
    {
      prop: "jitter",
      name: i18n.jitterInMS,
      size: 150,
      sortable: true
    }
  ];
  return tableColumns;
};

const validateAndFormatNumber = (value, decimalPlaces = 1) => {
  if (Number.isFinite(value))
    return formatNumberNDecimalPlaces(value, decimalPlaces);
  return defaultCellValue;
};

export const setSitesData = data => {
  for (const item of data) { 
    item.availability = validateAndFormatNumber(item.availability);
    item.city = capitalizeString(item.city);    
    item.packet_loss = validateAndFormatNumber(item.packet_loss);
    item.latency = validateAndFormatNumber(item.latency);
    item.jitter = validateAndFormatNumber(item.jitter);
    item.usage = formatSiteTableBytes(item.usage); 
  }
};
 
