import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import format from "string-template";
import { PropTypes } from "prop-types";
import _ from "lodash";

import AppHocReport from "../../../../generics/AppHOCReport";
import css from "../../reportsMagneticStyle.less";
import {
  DnxAccordion,
  DnxAccordionPanel,
  DnxBanner,
  DnxTextfield,
  DnxToast,
  toast
} from "../../../../loaders/DNXLoader";
import {
  PAGE,
  SITE,
  APP,
  BannerTypes,
  TEMPLATE_NAME,
  initGlobalState
} from "../../../../utils/enums";
import { setWorkflow } from "../../../../generics/AppHOCReport/actions";
import reduxContext from "../../../../generics/AppHOCReport/reducer";
import i18nMessageBundle from "amdi18n-loader!../../../nls/i18n";
import ApiService from "../../../../config/api-config";
import {
  isWidgetGradeOut,
  formatScope,
  isRunNow,
  getFreqMessage,
  isOnce,
  getWorkflowState,
  formatPageName,
  isAll,
  getLocalStorageFlag
} from "../../../../utils/common";
import * as apiConstant from "../../../../apis/apiConstants";
import { getTimePeriodELT, nextDay } from "../../../../utils/displayTime";
import reactWrapper from "@harbor/elements/utils/react/wrapper";

const DashletSummary = ({ globalReport }) => {
  const { isNextDisabled } = globalReport.workflowState;
  const workflowState = getWorkflowState();
  const isDisabledWidget = isWidgetGradeOut(location);
  const [HbrLink] = reactWrapper(["hbr-link"]);
  const {
    overlayId,
    reportName,
    recipientEmail,
    report_uuid,
    templateUuid,
    scope,
    fileType,
    repeatTime,
    repeatOn,
    nextRun,
    scheduleFreq,
    timeRange,
    scheduleType,
    showAllApplications,
    showAllSites,
    pageName
  } = workflowState;
  const history = useHistory();
  const dispatch = useDispatch();
  const loadingConfig = {
    message: i18nMessageBundle.workflowSubmitting,
    type: BannerTypes.LOADING
  };
  const [showLoadingBanner, setShowLoadingBanner] = useState(false);
  const [bannerConfig, setBannerConfig] = useState({});
  const [error, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const SCHEDULE_FREQ = {
    NOW: "now"
  };
  const timeFilterOffset = getTimePeriodELT(timeRange);
  const startDate = new Date(timeFilterOffset.current_period[0]);
  const endDate = new Date();
  const localOffset = `${endDate.getTimezoneOffset()}_${startDate.getTimezoneOffset()}`; //i.e. 420_420 time offset for PST
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const editPayload = {
    update_field: {
      scope: scope && formatScope(scope),
      updated_time: Date.now(),
      updated_by: `ui_${localOffset}`,
      report_passkey: workflowState.report_passkey
    },
    query: {
      rules: {
        report_uuid: report_uuid
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const updatedWorkflow = {
        ...workflowState,
        ...initGlobalState
      };
      saveState(updatedWorkflow);
      localStorage.removeItem("workflow");
      history.push(`${apiConstant.REPORTS_URL}`);
    }
  }, [isSuccess]);

  useEffect(() => {
    const isExiting = getLocalStorageFlag("isExitWorkflow") === "true";
    if (isNextDisabled && !isExiting) {
      onSubmit();
    }
  }, [isNextDisabled]);

  useEffect(() => {
    if (error && _.isEmpty(bannerConfig)) {
      setBannerConfig([
        {
          message: `${i18nMessageBundle.workflowErrorSubmitting} <span type='edit_again'  class='dnx-action'>${i18nMessageBundle.workflowSubmitAgain}</span>`,
          type: BannerTypes.ERROR,
          action: e => {
            if (e.detail === "edit_again") {
              onSubmit();
            }
          }
        }
      ]);
    }
  }, [error]);

  const goToPage = currStep => {
    const updatedWorkflow = {
      ...workflowState,
      ...{ step: currStep }
    };
    saveState(updatedWorkflow);

    if (isDisabledWidget && currStep == 1) {
      history.push(`${apiConstant.REPORT_URL}${report_uuid}/${PAGE.SCOPE}`);
    } else if (isDisabledWidget && currStep == 2) {
      history.push(`${apiConstant.REPORT_URL}${report_uuid}/${PAGE.SCOPE}`);
      history.push(`${apiConstant.REPORT_URL}${report_uuid}/${PAGE.FILETYPE}`);
    } else {
      if (currStep == 1) {
        history.push(`${apiConstant.REPORT_SCOPE_PAGE_URL}`);
      } else {
        history.push(`${apiConstant.REPORT_SCOPE_PAGE_URL}`);
        history.push(`${apiConstant.REPORT_URL}setup-filetype`);
      }
    }
  };

  const onSubmit = async () => {
    setShowLoadingBanner(true);
    setBannerConfig({});
    setError(false);

    let currTime = new Date().setSeconds(0, 0);
    const isRunNow = isOnce(scheduleType);
    const updatedTime = Number.isInteger(repeatTime) ? repeatTime : currTime;
    const next = isRunNow ? currTime : nextRun ? nextRun : nextDay(updatedTime);
    const timeFilter = getTimePeriodELT(timeRange, next);
    const rOn = isRunNow ? -1 : repeatOn ? repeatOn : 1;
    const overlayName = localStorage.getItem("currentOverlayName");

    if (
      !_.isEmpty(reportName) &&
      !_.isEmpty(fileType) &&
      !_.isEmpty(scheduleType)
    ) {
      const generatePayload = {
        update_field: {
          report_name: reportName,
          file_type: fileType,
          schedule_type: scheduleType, //recurring or "once"
          schedule_freq: isRunNow ? SCHEDULE_FREQ.NOW : scheduleFreq, // or "daily", "monthly", "weekly","now"
          repeat_on: rOn, // should be a number
          repeat_time: isRunNow ? currTime : updatedTime,
          template_name: TEMPLATE_NAME[pageName],
          recipient_email: recipientEmail.replaceAll(",", "|"), //"123a@cisco.com|abc@cisco.com"
          delivery_type: "attachment",
          template_uuid: templateUuid,
          created_by: `ui_${localOffset}`,
          schedule_status: "pending", // should always be 'pending' for report creation
          overlay_id: overlayId,
          overlay_name: overlayName,
          time_range_type: timeZone,
          current_period_start: timeFilter.current_period[0], //1649466000000
          current_period_end: timeFilter.current_period[1], //must match next_run for widget optimization
          previous_period_start: timeFilter.previous_period[0],
          previous_period_end: timeFilter.previous_period[1],
          scope: formatScope(scope, pageName),
          status: "active",
          next_run: next,
          page_name: formatPageName(pageName, fileType, scope), //ex: "executive_summary",
          time_range: timeRange,
          report_passkey: workflowState.report_passkey
        }
      };
      let result = "";

      try {
        if (isDisabledWidget) {
          result = await ApiService.editReport(editPayload);
        } else {
          result = await ApiService.generateReport(generatePayload, overlayId);
        }

        if (result && result.data) {
          setSuccess(true);
          setShowLoadingBanner(false);
          setBannerConfig({});
          localStorage.removeItem("workflow");
        } else {
          setError(true);
          setShowLoadingBanner(false);
        }
      } catch (e) {
        setError(true);
        setShowLoadingBanner(false);
      }
    } else {
      setError(true);
      setShowLoadingBanner(false);
    }
  };

  const saveState = updatedWorkflow => {
    localStorage.setItem("workflow", JSON.stringify(updatedWorkflow));
    dispatch(setWorkflow(updatedWorkflow));
  };

  const _copyClipboard = () => {
    navigator.clipboard.writeText(workflowState.report_passkey);
    toast({
      message: i18nMessageBundle.workflowSuccessMessage,
      flavor: "success"
    });
  };

  return (
    <div className={css["workflow"]}>
      <React.Fragment>
        <div className="left-side-container">
          <div className="header">{i18nMessageBundle.workflowSummary}</div>
          <div className="subHeadline">{i18nMessageBundle.worklfowAlmost}</div>
          {showLoadingBanner && (
            <DnxBanner name="banner-loading" config={loadingConfig} />
          )}
          {!_.isEmpty(bannerConfig) && (
            <div>
              <DnxBanner name="banner-warning" config={bannerConfig} />
            </div>
          )}
          <div className="workflow-expand-collapse" >
            <DnxAccordion multi={true} openpanels={[0, 1, 2, 3, 4]}>
              <DnxAccordionPanel>
                <div className="dnx-panel-head">
                  <span className="panel-title">
                    {i18nMessageBundle.workflowReportNameLabel}
                  </span>
                </div>
                <div className="title-summary" data-cy="titleSummary">{reportName}</div>
              </DnxAccordionPanel>
              <DnxAccordionPanel style={{ paddingBottom: "6px" }}>
                <div className="dnx-panel-head ">
                  <span className="panel-title">
                    {i18nMessageBundle.worklfowScope}
                  </span>
                  <div
                    className="dnx-panel-head-aside"
                    style={{ height: "22px", marginLeft: "24px" }}
                  >
                    <HbrLink href="#" target="_parent" onClick={() => goToPage(1)}>
                      {i18nMessageBundle.worklfowEdit}
                    </HbrLink>
                  </div>
                </div>
                <div className={"layout-key-value"}>
                  <div className="key-column">
                    {showAllSites && (
                      <div className="kv-row">
                        {i18nMessageBundle.worklfowSite}
                      </div>
                    )}
                    {showAllApplications && (
                      <div className="kv-row">
                        {i18nMessageBundle.worklfowAppllication}
                      </div>
                    )}
                  </div>
                  <div className="value-column">
                    {showAllSites && (
                      <div className="kv-row" data-cy="allSites">
                        {scope.site_id
                          ? isAll(scope.site_id)
                            ? SITE.ALL_SITES
                            : scope.site_id
                          : SITE.ALL_SITES}
                      </div>
                    )}
                    {showAllApplications && (
                      <div className="kv-row" data-cy="allApps">
                        {scope.application
                          ? isAll(scope.application)
                            ? APP.ALL_APPS
                            : scope.application
                          : APP.ALL_APPS}
                      </div>
                    )}
                  </div>
                </div>
              </DnxAccordionPanel>

              <DnxAccordionPanel style={{ paddingBottom: "6px" }}>
                <div className="dnx-panel-head">
                  <span className="panel-title">
                    {i18nMessageBundle.workflowFile}
                  </span>
                  <div
                    className="dnx-panel-head-aside"
                    style={{ height: "22px", marginLeft: "24px" }}
                  >
                    <HbrLink href="#" target="_parent" onClick={() => goToPage(2)}>
                      {i18nMessageBundle.worklfowEdit}
                    </HbrLink>
                  </div>
                </div>
                <div className={"layout-key-value"}>
                  <div className="key-column">
                    {fileType && (
                      <div className="kv-row">
                        {i18nMessageBundle.workflowFileType}
                      </div>
                    )}
                  </div>
                  <div className="value-column">
                    {fileType && (
                      <div className="kv-row" data-cy="fileType">
                        {fileType}
                      </div>
                    )}
                  </div>
                </div>
              </DnxAccordionPanel>

              <DnxAccordionPanel style={{ paddingBottom: "6px" }}>
                <div className="dnx-panel-head">
                  <div className="panel-title">
                    {i18nMessageBundle.worklfowSchedule}
                  </div>
                </div>
                <div className={"layout-key-value"}>
                  <div className="key-column">
                    <div className="kv-row">
                      {i18nMessageBundle.worklfowType}
                    </div>
                    <div className="kv-row">
                      {i18nMessageBundle.worklfowDetails}
                    </div>
                  </div>
                  <div className="value-column">
                    <div className="kv-row" data-cy="typeSummary">
                      {isOnce(scheduleType)
                        ? i18nMessageBundle.workflowOneTime
                        : `${scheduleType
                          .charAt(0)
                          .toUpperCase()}${scheduleType.slice(1)}`}
                    </div>
                    <div className="kv-row" data-cy="detailsSummary">
                      {isRunNow(scheduleType)
                        ? getFreqMessage(timeRange)
                        : format(i18nMessageBundle.workflowGenerated, {
                          object1: scheduleFreq,
                          object2: timeRange
                        })}
                    </div>
                  </div>
                </div>
              </DnxAccordionPanel>
              <DnxAccordionPanel>
                <div className="dnx-panel-head">
                  <div className="panel-title">
                    {i18nMessageBundle.worklfowEmail}
                  </div>
                </div>
                <div className={"layout-key-value"}>
                  <div className="key-column">
                    <div className="kv-row-2">
                      {i18nMessageBundle.workflowDeliveryDocumentKey}:
                    </div>
                  </div>
                  <div className="value-column-2" data-cy="docPasskeySummary">
                    <div>
                      <DnxTextfield
                        password={true}
                        textreadonly={true}
                        value={workflowState.report_passkey}
                        maxWidth={"160px"}
                      />
                      <div className="copy-icon" onClick={_copyClipboard}>
                        <hbr-icon name="copy"></hbr-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"layout-key-value"}>
                  <div className="key-column-2">
                    {recipientEmail &&
                      recipientEmail.split(",").map((elem, val) => (
                        <div className="kv-row" key={elem}>
                          {val === 0 ? (
                            i18nMessageBundle.worklfowEmailTo
                          ) : (
                              <br />
                            )}
                        </div>
                      ))}
                  </div>
                  <div className="value-column" data-cy="emailSummary">
                    {recipientEmail &&
                      recipientEmail.split(",").map(elem => (
                        <div className="kv-row" key={elem}>
                          {elem}
                        </div>
                      ))}
                  </div>
                </div>
              </DnxAccordionPanel>
            </DnxAccordion>
            <DnxToast />
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

DashletSummary.propTypes = {
  globalReport: PropTypes.object.isRequired
};

export default reduxContext.withProvider(
  connect()(AppHocReport(DashletSummary))
);
